import { ConnectButton } from '../components/buttons/ConnectButton'
import * as Styled from './styled'
import { DataContext } from '../context'
import { useContext, useEffect, useState } from 'react'
import { Beta, LoadingPageContainer } from '../components/styled'
import Loader from '../components/Loader'
import Switch from 'react-switch'
import { MoonIcon, SunIcon } from '../icons'

function LoginPage() {
  const { darkTheme, setDarkTheme } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const onSwitchChange = () => {
    if (!darkTheme) {
      localStorage.setItem('dark', 'true')
    } else {
      localStorage.setItem('dark', 'false')
    }
    setDarkTheme(!darkTheme)
  }

  useEffect(() => {
    const init = async () => {
      setDarkTheme(localStorage.getItem('dark') === 'true')
    }
    init()
  }, [])

  if (loading) {
    return (
      <LoadingPageContainer>
        <Loader
          color={darkTheme ? '#fff' : '#000'}
          type="triangle"
          height="300px"
        />
      </LoadingPageContainer>
    )
  }

  return (
    <Styled.LoginBackground $darktheme={darkTheme}>
      <Styled.PageContainer height="100%">
        <Styled.LogoContainer>
          <Beta>Beta</Beta>
          <img
            src={
              darkTheme
                ? '/logo/metacade-white.png'
                : '/logo/metacade-black.png'
            }
            width="202"
            height="30"
            alt="metacade-logo"
          />
          {/* <Call href={bookCallUrl} target="_blank" rel="noreferrer">
            <CallIcon /> Book a Call
          </Call> */}
          <Switch
            checked={!(localStorage.getItem('dark') === 'true')}
            onChange={onSwitchChange}
            className="dark-mode-switch"
            uncheckedIcon={
              <div style={{ margin: '1px 0 0 11px' }}>
                <SunIcon style={{ width: '22px' }} />
              </div>
            }
            checkedIcon={
              <div style={{ padding: '3px 0 0 4px' }}>
                <MoonIcon />
              </div>
            }
            onColor="#ffffff"
            offColor="#000000"
            onHandleColor="#000000"
            offHandleColor="#ffffff"
            handleDiameter={23}
            width={62}
            height={28}
            activeBoxShadow="0"
          />
        </Styled.LogoContainer>
        <Styled.LoginContent>
          <Styled.LoginTitle $darktheme={darkTheme}>
            Metacade Partner Services
          </Styled.LoginTitle>
          <Styled.LoginSubTitle>
            We offer a number of ways to support creators and studios at all
            stages of their development
          </Styled.LoginSubTitle>
          <ConnectButton />
          <Styled.ImgContainer>
            <img
              src={darkTheme ? '/bg-img-dark.png' : '/bg-img.png'}
              style={{ width: '100%' }}
              alt=""
            />
          </Styled.ImgContainer>
        </Styled.LoginContent>
      </Styled.PageContainer>
    </Styled.LoginBackground>
  )
}

export default LoginPage
