import { IQuestData } from '../../types'
import { tagsToArray } from '../utils'
import { appendImage } from './heplers'
import Api from '../../services/api'

export const getQuestData = async (questId: string) => {
  if (!questId) {
    return {}
  }
  const response = await Api.get<IQuestData>(`partners/quest/${questId}`)
  return response.data
}

export const postQuestData = async (values: IQuestData) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    short_description: values.short_description,
    external_url: values.url,
    reward: values.reward,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    type: values.type,
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<IQuestData>(
    `partners/quest`,
    formData,
    {}
  )

  return response.data?.data?.id
}

export const updateQuestData = async (values: IQuestData, questId: string) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    short_description: values.short_description,
    external_url: values.url,
    reward: values.reward,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    type: values.type,
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IQuestData>(
    `partners/quest/${questId}`,
    formData,
    {}
  )

  return response.data
}
