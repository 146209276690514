export interface Partner {
  id: string
  strapiId: string
  email: string
  bio: string
  walletAddress: string
  passStatus: PassStatus
  createdAt: string
  avatar: string
  name: string
  userName: string
  location: string
  passImageURL: string
  subscriptionEndsAt: string
  isPartner: boolean
}

export enum PassStatus {
  PENDING = 'pending',
  BUILD = 'build',
  UPRISING = 'uprising',
  SURGE = 'surge',
}

export interface Moderator {
  id: string
  name: string
  email: string
  walletAddress: string
  referrerId: string
  code?: string
}
