import { DataContext } from '../../context'
import { useContext } from 'react'
import * as Styled from './styled'
import { useNavigate } from 'react-router-dom'

function NewEntryMenu({ open }: { open: boolean }) {
  const navigate = useNavigate()
  const { filter, darkTheme } = useContext(DataContext)

  return (
    <Styled.NewEntryMenuContainer
      darkTheme={darkTheme}
      open={open}
      id="new-entry-menu-feed"
    >
      {filter === 'feed' && (
        <Styled.NewEntryMenuItem
          darkTheme={darkTheme}
          onClick={() => navigate('/announcement')}
        >
          Announcement
        </Styled.NewEntryMenuItem>
      )}
      {(filter === 'feed' || filter === 'earn') && (
        <>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/quest')}
          >
            Quest
          </Styled.NewEntryMenuItem>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/content')}
          >
            Content
          </Styled.NewEntryMenuItem>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/job')}
          >
            Job
          </Styled.NewEntryMenuItem>
        </>
      )}
      {(filter === 'feed' || filter === 'gaming') && (
        <>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/merch')}
          >
            Merch
          </Styled.NewEntryMenuItem>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/tournament')}
          >
            Tournament
          </Styled.NewEntryMenuItem>
        </>
      )}
      {(filter === 'feed' || filter === 'rewards') && (
        <>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/digital_asset')}
          >
            Digital asset
          </Styled.NewEntryMenuItem>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/offer')}
          >
            Offer
          </Styled.NewEntryMenuItem>
          <Styled.NewEntryMenuItem
            darkTheme={darkTheme}
            onClick={() => navigate('/token')}
          >
            Token
          </Styled.NewEntryMenuItem>
        </>
      )}
    </Styled.NewEntryMenuContainer>
  )
}

export default NewEntryMenu
