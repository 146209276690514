import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { PropsWithChildren, useContext } from 'react'
import { DataContext } from '../../context'

export default function ToastifyRegistry(props: PropsWithChildren<{}>) {
  const { darkTheme } = useContext(DataContext)
  return (
    <>
      {props.children}
      <ToastContainer
        pauseOnHover
        hideProgressBar
        toastStyle={
          darkTheme
            ? { backgroundColor: '#111', color: '#8D8D8D' }
            : { backgroundColor: 'black', color: 'white' }
        }
        // theme={darkTheme ? 'light' : 'dark'}
        position="top-center"
        closeButton={false}
        closeOnClick
      />
    </>
  )
}
