// export const CMS_URL = 'https://admin-dev.metacade.co/api'

export const isDev = () => {
  if (
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'beta'
  ) {
    return
  }
  return true
}
