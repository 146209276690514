import { BACKEND_DEV_URL, BACKEND_PROD_URL } from '../utils/urls'
import { isDev } from '../config'
import axios, { AxiosRequestConfig } from 'axios'

class Api {
  instance: ReturnType<typeof axios.create>

  constructor() {
    this.instance = axios.create({
      baseURL: isDev() ? BACKEND_DEV_URL : BACKEND_PROD_URL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        // 'ngrok-skip-browser-warning': '69420'
      },
    })
  }

  async get<T>(url: string, options?: AxiosRequestConfig): Promise<any> {
    const response = await this.instance.get<T>(url, options)
    return response.data
  }

  async post<T>(
    url: string,
    body: any,
    options?: AxiosRequestConfig
  ): Promise<any> {
    const response = await this.instance.post<T>(url, body, options)
    return response.data
  }

  async putFormData<T>(
    url: string,
    formData: FormData,
    headers: any
  ): Promise<any> {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    }
    return await this.instance.put<T>(url, formData, options)
  }

  async postFormData<T>(
    url: string,
    formData: FormData,
    headers: any
  ): Promise<any> {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    }
    return await this.instance.post<T>(url, formData, options)
  }

  async delete(url: string, options?: AxiosRequestConfig): Promise<any> {
    const response = await this.instance.delete(url, options)
    return response.status
  }
}

const api = new Api()

export default api
