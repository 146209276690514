import { IRewardData } from '@/types'
import { tagsToArray } from '../utils'
import { appendImage } from './heplers'
import Api from '../../services/api'

export const postOfferData = async (values: IRewardData) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<IRewardData>(
    `partners/offer`,
    formData
  )

  return response.data?.data?.id
}

export const updateOfferData = async (values: IRewardData, postId: string) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IRewardData>(
    `partners/offer/${postId}`,
    formData
  )

  return response.data
}
