'use client'
import { MouseEventHandler, ReactNode } from 'react'
import * as Styled from './styled'

export type ButtonColors =
  | 'pink'
  | 'black'
  | 'white'
  | 'transparent'
  | 'black-transparent'
  | 'white-transparent'

interface ButtonProps {
  onClick?: any
  children?: ReactNode
  color?: ButtonColors
  href?: string
  blank?: boolean
  disabled?: boolean
  className?: string
  width?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  id?: string
  borderRadius?: string
}

function Button({
  color = 'white',
  onClick,
  href,
  blank,
  children = 'Click',
  disabled,
  className,
  width,
  onMouseEnter,
  onMouseLeave,
  id,
  borderRadius = '16px',
}: ButtonProps) {
  if (href) {
    return (
      <Styled.ButtonLink
        color={color}
        href={href}
        target={blank ? '_blank' : '_self'}
        className={className}
        width={width}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        id={id}
      >
        {children}
      </Styled.ButtonLink>
    )
  }

  return (
    <Styled.Button
      disabled={disabled}
      color={color}
      onClick={onClick}
      width={width}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      id={id}
      borderRadius={borderRadius}
    >
      {children}
    </Styled.Button>
  )
}

export default Button
