import { DataContext } from '../../context'
import { useState, useContext } from 'react'
import * as Styled from './styled'
import Button from '../buttons/Button'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import CloseButton from '../buttons/CloseButton'
import { deleteGame } from '../../utils/api'
import { useCookies } from 'react-cookie'

interface IGameModal {
  open: boolean
  setOpen: (open: boolean) => void
  gameId: string
  getData: () => void
}

function GameDeleteModal({ open, setOpen, gameId, getData }: IGameModal) {
  const [loading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const { darkTheme } = useContext(DataContext)

  const onDelClick = async () => {
    setLoading(true)
    try {
      const response = await deleteGame(gameId)
      if (response.deleted) {
        getData()
        toast('Game was successfully deleted')
        setOpen(false)
      } else {
        toast('Something went wrong...')
      }
    } catch (error) {
      console.error(error)
      toast('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled.ModalContainer onClick={() => setOpen(false)} open={open}>
      <Styled.Modal $darktheme={darkTheme} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => setOpen(false)} />
        <div style={{ marginBottom: '2rem' }}>
          Are you sure you want to delete this game?
        </div>
        <Styled.ButtonsGroup>
          <Button
            color={darkTheme ? 'white-transparent' : 'white'}
            onClick={onDelClick}
            onMouseEnter={() => setLoaderColor(darkTheme ? 'black' : 'white')}
            onMouseLeave={() => setLoaderColor(darkTheme ? 'white' : 'black')}
          >
            {loading ? <Loader color={loaderColor} /> : 'Delete'}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color={darkTheme ? 'white-transparent' : 'white'}
          >
            Cancel
          </Button>
        </Styled.ButtonsGroup>
      </Styled.Modal>
    </Styled.ModalContainer>
  )
}

export default GameDeleteModal
