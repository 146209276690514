import { ITournamentData } from '../../types'
import { tagsToArray } from '../utils'
import { appendImage } from './heplers'
import Api from '../../services/api'

export const getTournamentData = async (postId: string) => {
  if (!postId) {
    return {}
  }
  const response = await Api.get<ITournamentData>(
    `partners/tournament/${postId}`
  )
  return response.data
}

export const postTournamentData = async (values: ITournamentData) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    reward: values.reward,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<ITournamentData>(
    `partners/tournament`,
    formData
  )

  return response.data?.data?.id
}

export const updateTournamentData = async (
  values: ITournamentData,
  postId: string
) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    reward: values.reward,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<ITournamentData>(
    `partners/tournament/${postId}`,
    formData
  )

  return response.data
}
