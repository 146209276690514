import {
  AttackIcon,
  ClothesIcon,
  CommercialIcon,
  LabyrinthIcon,
  MaleUserIcon,
  TagIcon,
  TasksIcon,
  TrophyIcon,
} from '../icons'
import * as Styled from './styled'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DataContext, PartnerContext } from '../context'
import { useCookies } from 'react-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FeedTableRow from '../components/FeedTableRow'
import Loader from '../components/Loader'
import ReactPaginate from 'react-paginate'
import { getFeedData } from '../utils/api/feed'
import { ITEMS_PER_PAGE } from '../constants'
import { transformFeedData } from '../utils/data'
import { toast } from 'react-toastify'
import { getFeedUrl } from '../utils/urls'

function HomePage() {
  const [loading, setLoading] = useState(false)
  const [firstItem, setFirstItem] = useState(0)
  const [searchParams] = useSearchParams()
  const { partner_cms_id } = useContext(PartnerContext)
  const {
    feedData,
    setFeedData,
    page,
    setPage,
    pageCount,
    setPageCount,
    setTotalItems,
    totalItems,
    setFilter,
    darkTheme,
  } = useContext(DataContext)
  const navigate = useNavigate()

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const url = getFeedUrl(partner_cms_id || null, page, '')
      const response = await getFeedData(url)
      const restructuredData = transformFeedData(response?.data)
      setFeedData(restructuredData)
      setPageCount(response?.meta?.pagination?.pageCount || 1)
      setTotalItems(response?.meta?.pagination?.total || 0)
      setFirstItem((page - 1) * ITEMS_PER_PAGE + 1)
    } catch (error) {
      console.error(error)
      toast('Error occured during feed data fetching...')
    } finally {
      setLoading(false)
    }
  }, [page, partner_cms_id, setFeedData, setPageCount, setTotalItems])

  useEffect(() => {
    searchParams.delete('code')
    getData()
  }, [getData, searchParams])

  return (
    <Styled.PageContainer>
      <Styled.HomeTitle>Welcome to Metacade!</Styled.HomeTitle>
      <Styled.HomeColoredBlocks>
        <Styled.ColoredBlock
          type="colored"
          onClick={() => {
            setFilter('profile')
            navigate('/profile')
          }}
        >
          <Styled.ColoredBlockIconAbsolute>
            <MaleUserIcon />
          </Styled.ColoredBlockIconAbsolute>
          <Styled.ColoredBlockText type="colored">
            Profile
            <div className="text">
              Let’s start with your profile. Fill the details, description and
              add some links to your socials!
            </div>
          </Styled.ColoredBlockText>
        </Styled.ColoredBlock>
        <Styled.ColoredBlock
          type="colored"
          onClick={() => navigate('/announcement')}
        >
          <Styled.ColoredBlockIconAbsolute>
            <CommercialIcon />
          </Styled.ColoredBlockIconAbsolute>
          <Styled.ColoredBlockText type="colored">
            Announcements
            <div className="text">
              Engage with the gamers community, make an update in your first own
              announcement
            </div>
          </Styled.ColoredBlockText>
        </Styled.ColoredBlock>
        <Styled.ColoredBlock type="colored" onClick={() => navigate('/job')}>
          <Styled.ColoredBlockIconAbsolute>
            <TasksIcon />
          </Styled.ColoredBlockIconAbsolute>
          <Styled.ColoredBlockText type="colored">
            Job post
            <div className="text">
              Are you hunting? Create a job post. It can be just a gig, a
              contract or full-time position
            </div>
          </Styled.ColoredBlockText>
        </Styled.ColoredBlock>
        <Styled.ColoredBlock type="colored" onClick={() => navigate('/quest')}>
          <Styled.ColoredBlockIconAbsolute>
            <LabyrinthIcon />
          </Styled.ColoredBlockIconAbsolute>
          <Styled.ColoredBlockText type="colored">
            Questing
            <div className="text">
              You can create Learn2Earn, Alpha testing or Content quest to
              incentivise users to write a review
            </div>
          </Styled.ColoredBlockText>
        </Styled.ColoredBlock>
      </Styled.HomeColoredBlocks>
      <Styled.BottomSection>
        <Styled.Title>Feed</Styled.Title>
        <Styled.FeedTable>
          <FeedTableRow />
          {loading ? (
            <Styled.LoaderContainer>
              <Loader type="grid" color="grey" />
            </Styled.LoaderContainer>
          ) : (
            feedData?.map((row) => (
              <FeedTableRow page={page} key={row.id} row={row} />
            ))
          )}
        </Styled.FeedTable>
        <Styled.ReactPaginateContainer>
          {loading ? (
            <div />
          ) : (
            totalItems > 0 && (
              <div>{`Showing ${firstItem} to ${
                firstItem + feedData.length - 1
              } of ${totalItems} results`}</div>
            )
          )}
          {pageCount !== 1 && !loading && (
            <ReactPaginate
              className="pagination"
              activeClassName="active-page"
              pageClassName="page"
              breakLabel="..."
              nextLabel=">"
              onPageChange={(event) => setPage(event.selected + 1)}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              forcePage={page - 1}
            />
          )}
        </Styled.ReactPaginateContainer>
      </Styled.BottomSection>
    </Styled.PageContainer>
  )
}

export default HomePage
