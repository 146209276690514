import { PassStatus } from '../types/partner'
import {
  BlockContentType,
  ShortContentType,
  SubscriptionContentType,
} from '../types'

export const subscription_content: SubscriptionContentType[] = [
  {
    type: {
      status: PassStatus.BUILD,
      title: 'Build',
      color: 'grey',
    },
    description:
      'Ideal for individuals or start up studios working on their first game',
    price: 99,
    features: [
      'Create your studio profile',
      'Launch your new community',
      'List your game',
      'Launch quests, testing, competitions',
      'Post your content',
    ],
  },
  {
    type: {
      status: PassStatus.SURGE,
      title: 'Surge',
      color: 'violet',
    },
    description:
      'Great for creators / studios who have one or more playable games ready to grow',
    price: 499,
    features: [
      'Promoted studio profile',
      'Unlimited job and gig postings',
      'Premium listing boost for specific game',
    ],
    previous: {
      title: 'Build',
      color: 'grey',
    },
  },
  {
    type: {
      status: PassStatus.UPRISING,
      title: 'Uprising',
      color: 'pink',
    },
    popular: true,
    description:
      'The key to all established studios looking to scale across multiple metrics',
    price: 999,
    features: [
      'Unlimited games listings',
      'Unlimited job and gig postings',
      'Premium listing boost for all games',
    ],
    previous: {
      title: 'Surge',
      color: 'violet',
    },
  },
]

const profile_description_block: ShortContentType[] = [
  {
    tag: 'name',
    title: 'Partner Name',
    content_type: 'input',
    placeholder: 'Enter studio name',
    description: '',
    required: true,
  },
  {
    tag: 'email',
    title: 'Contact e-mail',
    content_type: 'input',
    placeholder: 'Enter email',
    description: '',
    disabled: true,
    required: true,
  },
  {
    tag: 'website_link',
    title: 'Website link',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Partner info',
    content_type: 'textarea',
    placeholder:
      'Drop your elevator pitch or mission statement in here, no more than 290 characters',
    description: 'This Information is a description of your partner mission',
    required: true,
  },
  {
    tag: 'short_description',
    title: 'Short description',
    content_type: 'textarea',
    placeholder: 'Description is no more than 290 characters',
    description:
      'This information gives the community a short description of what you do',
    required: true,
  },
]

const profile_image_block: ShortContentType[] = [
  {
    tag: 'logo',
    title: 'Profile image',
    content_type: 'dropzone_small',
    placeholder: '',
    description:
      'Add logo. Your file should be no more than 2 MB. JPG or PNG are required',
  },
  {
    tag: 'preview_image',
    title: 'Banner image',
    content_type: 'dropzone_big',
    placeholder: '',
    description:
      'Add your banner image. Your file should be no more than 2 MB. JPG or PNG are required',
  },
]

const profile_socials_block: ShortContentType[] = [
  {
    tag: 'x',
    title: 'X',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'instagram',
    title: 'Instagram',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'youtube',
    title: 'Youtube',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'twitch',
    title: 'Twitch',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'telegram',
    title: 'Telegram',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'linkedin',
    title: 'LinkedIn',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'linktree',
    title: 'Link Tree',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'tiktok',
    title: 'TikTok',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
]

// const profile_video_block: ShortContentType[] = [
//   {
//     tag: 'video_url',
//     title: 'Video URL',
//     content_type: 'input',
//     placeholder: 'Enter video URL here',
//     description: '',
//   },
//   {
//     tag: 'video_thumbnail',
//     title: 'Video thumbnail',
//     content_type: 'dropzone_big',
//     placeholder: '',
//     description: '',
//   },
// ]

const profile_subscription_block: ShortContentType[] = [
  {
    tag: '',
    title: '',
    content_type: 'subscription',
    placeholder: '',
    description: '',
  },
]

const profile_about_block: ShortContentType[] = [
  {
    tag: 'about_preview',
    title: 'Preview image',
    content_type: 'dropzone_big',
    placeholder: '',
    description:
      'Add more effective visual representation to your Partner Profile. Your file should be no more than 2 MB. JPG or PNG are required',
  },
  {
    tag: 'about_text',
    title: 'Description',
    content_type: 'textarea',
    placeholder:
      'Drop your elevator pitch or mission statement in here, no more than 290 characters',
    description:
      'This information gives the community a short description of your game',
    required: true,
  },
]

const quest_type_block: ShortContentType[] = [
  {
    tag: 'type',
    options: [
      { id: 1, title: 'learn2earn' },
      { id: 2, title: 'alpha testing' },
      { id: 3, title: 'quest' },
    ],
    title: 'Quest type',
    content_type: 'select',
    placeholder: '',
    description: '',
    required: true,
  },
]

const merch_type_block: ShortContentType[] = [
  {
    tag: 'type',
    options: [
      { id: 1, title: 'digital' },
      { id: 2, title: 'physical' },
    ],
    title: 'Merch type',
    content_type: 'select',
    placeholder: '',
    description: '',
    required: true,
  },
]

const quest_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  // {
  //   tag: 'short_description',
  //   title: 'Short description',
  //   content_type: 'textarea',
  //   placeholder: 'Tell more about your challenge to the world',
  //   description: '',
  //   required: true,
  // },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
    required: true,
  },
  // {
  //   tag: 'type',
  //   title: 'Quest type',
  //   content_type: 'radio_type',
  //   placeholder: '',
  //   description: '',
  //   required: true,
  // },
  {
    tag: 'reward',
    title: 'Reward',
    content_type: 'input',
    placeholder: 'i.g. 20 USDT',
    description: '',
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const offer_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
    required: true,
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const asset_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Item',
    content_type: 'input',
    placeholder: 'Enter your item',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
    required: true,
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const token_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
    required: true,
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const tournament_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Tournament Name',
    content_type: 'input',
    placeholder: 'Enter tournament name',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
    required: true,
  },
  {
    tag: 'reward',
    title: 'Reward',
    content_type: 'input',
    placeholder: 'i.g. 20 USDT',
    description: '',
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const merch_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Product',
    content_type: 'input',
    placeholder: 'Enter your product',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Product Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'Discount URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const quest_image_block: ShortContentType[] = [
  {
    tag: 'preview_image',
    title: 'Preview image',
    content_type: 'dropzone_big',
    placeholder: '',
    description:
      'Add an image to showcase your post. Your file should be no more than 2 MB. JPG or PNG are required',
  },
]

const content_image_block: ShortContentType[] = [
  {
    tag: 'preview_image',
    title: 'Preview image',
    content_type: 'dropzone_big',
    placeholder: '',
    description:
      'Add image of your brand. Your file should be no more than 2 MB. JPG or PNG are required',
  },
]

const announcement_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description:
      'Drop a copy of a tweet/social post in here to amplify your announcement or add something new!',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description: '',
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const announcement_image_block: ShortContentType[] = [
  {
    tag: 'preview_image',
    title: 'Preview image',
    content_type: 'dropzone_big',
    placeholder: '',
    description:
      'Add an image to showcase your announcement. Your file should be no more than 2 MB. JPG or PNG are required',
  },
]

const games_block: ShortContentType[] = [
  {
    tag: 'currentGame',
    options: 'games',
    title: 'Games list',
    content_type: 'select',
    placeholder: '',
    description: 'Choose the game you want to add to the post',
  },
]

const schedule_block: ShortContentType[] = [
  {
    tag: 'publish_date',
    title: 'Publish date',
    content_type: 'date_time',
    placeholder: 'Choose from calendar',
    description: '',
  },
  {
    tag: 'expiry_date',
    title: 'Expiry date',
    content_type: 'date_time',
    placeholder: 'Choose from calendar',
    description: '',
  },
]

const profile_games_block: ShortContentType[] = [
  {
    tag: 'currentGame',
    options: 'games',
    title: 'Games list',
    content_type: 'select',
    placeholder: '',
    description: 'Choose the game you want to add to display',
  },
]

const content_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description: '',
    required: true,
    expandable: true,
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter URL',
    description:
      'Provide a link where you would like user to publish the review',
    required: true,
  },
  {
    tag: 'reward',
    title: 'Reward',
    content_type: 'input',
    placeholder: 'i.g. 20 USDT',
    description: '',
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const job_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  {
    tag: 'company',
    title: 'Company',
    content_type: 'input',
    placeholder: 'Enter company',
    description: '',
    required: true,
  },
  {
    tag: 'location',
    title: 'Location',
    content_type: 'input',
    placeholder: 'e.g. London',
    description: '',
    required: true,
  },
  {
    tag: 'employment_type',
    options: [
      { id: 1, title: 'full-time' },
      { id: 2, title: 'part-time' },
      { id: 3, title: 'contract' },
      { id: 4, title: 'gigs' },
    ],
    title: 'Employment type',
    content_type: 'select',
    placeholder: '',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'markdown',
    placeholder:
      'Description under 290 characters for standard post and more than 290 for large ones',
    description:
      'Write an enticing statement to get the community looking at your full opportunity on your website or chosen destination',
    required: true,
    expandable: true,
  },
  {
    tag: 'salary',
    title: 'Salary or rate',
    content_type: 'input',
    placeholder: 'Drop the amount here',
    description: '',
  },
  {
    tag: 'url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Add external link to your vacancy',
    description: '',
    required: true,
  },
  {
    tag: 'tags',
    title: 'Tags',
    content_type: 'input',
    placeholder: '#tags',
    description: '',
  },
  {
    tag: 'trailer_video',
    title: 'Trailer Video',
    content_type: 'input',
    placeholder: 'Youtube link',
    description: '',
  },
]

const game_filters = [
  {
    name: 'Category',
    cms_field: 'category',
    content_type: 'multiselect',
    options: [
      // 'new',
      // 'featured',
      // 'featured_top',
      'free',
      'download',
      'purchase',
      // 'driving_free',
      // 'puzzle_purchase',
      'coming_soon',
      'all_games',
      'playable_demo',
    ],
  },
  {
    name: 'Genres',
    cms_field: 'genres',
    content_type: 'multiselect',
    options: [
      'Puzzle',
      'FPS',
      'Sports',
      'Fantasy',
      'Survival',
      'Action',
      'Fighting',
      'MMORPG',
      'Strategy',
      'Card',
      'Battle Royale',
      'Driving',
      'Adventure',
      'Arcade',
      'Mobile',
      'Desktop',
      'NFT',
      'PVP',
      'Play2Own',
      'Play2Earn',
    ],
  },
  {
    name: 'Platform',
    cms_field: 'platform',
    content_type: 'multiselect',
    options: [
      'Android',
      'IOS',
      'Windows Phone',
      'Browser',
      'PC',
      'Mac',
      'Linux',
      'Console',
    ],
  },
  {
    name: 'Network',
    cms_field: 'network',
    content_type: 'multiselect',
    options: [
      'Ethereum',
      'Polygon',
      'Polygon X',
      'ImmutableX',
      'Tezos',
      'Wax',
      'Cardano',
      'Enjin',
      'Flow',
      'Solana',
      'Avalanche',
      'Wemix',
      'Optimism',
      'Arbitrum',
      'Base',
      'Aptos',
      'Binance',
      'Cronos',
      'Oasis saphire',
      'Nebula',
      'Fantom',
      'Polkadot',
    ],
  },
  {
    name: 'Age rate',
    cms_field: 'age_rate',
    content_type: 'select',
    options: ['PEGI_3', 'PEGI_7', 'PEGI_12', 'PEGI_16', 'PEGI_18', 'PEGI_!'],
  },
  {
    name: 'Type',
    cms_field: 'game_type',
    content_type: 'select',
    options: ['browser', 'store', 'download'],
  },
]

const games_description_block: ShortContentType[] = [
  {
    tag: 'title',
    title: 'Title',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
    required: true,
  },
  {
    tag: 'short_description',
    title: 'Short description',
    content_type: 'textarea',
    placeholder: 'Short description of your game, no more than 290 characters',
    description: '',
    required: true,
  },
  {
    tag: 'description',
    title: 'Description',
    content_type: 'textarea',
    placeholder:
      'Detailed description of your game, no more than 500 characters',
    description: '',
    required: true,
  },
  {
    tag: 'specifications',
    title: 'Plarform specifications',
    content_type: 'textarea',
    placeholder: 'Description no more than 290 characters',
    description: 'Add platform restrictions for the game',
    required: true,
  },
  {
    tag: 'filters',
    title: 'Filters',
    content_type: 'game-filters',
    placeholder: '',
    description: '',
    content: game_filters,
    required: true,
  },
]

const games_image_block: ShortContentType[] = [
  {
    tag: 'logo',
    title: 'Logo',
    content_type: 'dropzone_small',
    placeholder: '',
    description:
      'Add game logo. Your file should be no more than 2 MB. JPG or PNG are required',
  },
]

const games_video_block: ShortContentType[] = [
  {
    tag: 'trailer_video_thumbnail',
    title: 'Thumbnail image',
    content_type: 'dropzone_big',
    placeholder: '',
    description: '',
  },
  {
    tag: 'trailer_video_url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter trailer video url',
    description: '',
  },
]

const second_games_video_block: ShortContentType[] = [
  {
    tag: 'second_trailer_video_thumbnail',
    title: 'Thumbnail image',
    content_type: 'dropzone_big',
    placeholder: '',
    description: '',
  },
  {
    tag: 'second_trailer_video_url',
    title: 'URL',
    content_type: 'input',
    placeholder: 'Enter second trailer video url',
    description: '',
  },
]

const games_languages_block: ShortContentType[] = [
  {
    tag: 'languages_audio',
    title: 'Audio',
    content_type: 'input',
    placeholder: 'Enter audio language',
    description: '',
  },
  {
    tag: 'languages_text',
    title: 'Text',
    content_type: 'input',
    placeholder: 'Enter text language',
    description: '',
  },
]

const games_upload_block: ShortContentType[] = [
  {
    tag: 'source_url',
    title: '',
    content_type: 'file_upload',
    placeholder: '',
    description:
      'Upload a file from computer/add source link or add your game from the store. Contact us if you have any issues or questions',
  },
  {
    tag: 'google_store_url',
    title: 'Google Play link',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'apple_store_url',
    title: 'App Store link',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
]

const games_development_block: ShortContentType[] = [
  {
    tag: 'release_date',
    title: 'Release date',
    content_type: 'date',
    placeholder: 'Enter release date',
    description: '',
  },
]

const games_alpha_block: ShortContentType[] = [
  {
    tag: 'alpha_finish_date',
    title: 'Alpha finish date',
    content_type: 'date',
    placeholder: 'Enter alpha finish date',
    description: '',
  },
  {
    tag: 'alpha_extra_text',
    title: 'Short description',
    content_type: 'textarea',
    placeholder: 'Description is no more than 290 characters',
    description: '',
  },
  {
    tag: 'alpha_extra_image',
    title: 'Image',
    content_type: 'dropzone_big',
    placeholder: '',
    description: '',
  },
  {
    tag: 'alpha_extra_questions_title',
    title: 'Questions',
    content_type: 'input',
    placeholder: 'Enter title',
    description: '',
  },
  {
    tag: 'alpha_extra_questions_type',
    title: '',
    content_type: 'select',
    placeholder: '',
    description: '',
    options: [
      { id: 1, title: 'rating' },
      { id: 2, title: 'text' },
    ],
  },
]

const games_socials_block: ShortContentType[] = [
  {
    tag: 'x',
    title: 'X',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'discord',
    title: 'Discord',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'reddit',
    title: 'Reddit',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'linktree',
    title: 'Link Tree',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
  {
    tag: 'website',
    title: 'Website',
    content_type: 'input',
    placeholder: 'https://',
    description: '',
  },
]

const admin_generate_code_block: ShortContentType[] = [
  {
    tag: 'email_generate',
    title: 'Email',
    content_type: 'input',
    placeholder: 'Enter email',
    description: '',
    required: true,
  },
  {
    tag: 'key_generate',
    title: 'Key',
    content_type: 'input',
    placeholder: 'Enter key',
    description: '',
    required: true,
  },
  {
    tag: 'code_generated',
    title: 'Link',
    content_type: 'input',
    placeholder: 'Link',
    description: '',
    disabled: true,
  },
]

const admin_get_code_block: ShortContentType[] = [
  {
    tag: 'email_get',
    title: 'Email',
    content_type: 'input',
    placeholder: 'Enter email',
    description: '',
    required: true,
  },
  {
    tag: 'key_get',
    title: 'Key',
    content_type: 'input',
    placeholder: 'Enter key',
    description: '',
    required: true,
  },
  {
    tag: 'code_get',
    title: 'Link',
    content_type: 'input',
    placeholder: 'Link',
    description: '',
    disabled: true,
  },
]

export const profile_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: profile_description_block,
  },
  {
    name: 'Images',
    rows: profile_image_block,
  },
  {
    name: 'Social media links',
    rows: profile_socials_block,
  },
  {
    name: 'Subscription',
    rows: profile_subscription_block,
  },
  {
    name: 'Featured Game',
    rows: profile_games_block,
  },
  {
    name: 'About Section',
    rows: profile_about_block,
  },
]

export const admin_content: BlockContentType[] = [
  {
    tag: 'generate',
    name: 'Create invite link',
    rows: admin_generate_code_block,
  },
  {
    tag: 'get',
    name: 'Get existing invite link',
    rows: admin_get_code_block,
  },
]

export const job_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: job_description_block,
  },
  {
    name: 'Schedule',
    rows: schedule_block,
    switch: 'is_scheduled',
  },
]

export const quests_content: BlockContentType[] = [
  {
    name: '',
    rows: quest_type_block,
  },
  {
    name: 'Description',
    rows: quest_description_block,
  },
  {
    name: 'Images',
    rows: quest_image_block,
    optional: true,
  },
  {
    name: 'Games',
    rows: games_block,
  },
  {
    name: 'Schedule',
    rows: schedule_block,
    switch: 'is_scheduled',
  },
]

export const offers_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: offer_description_block,
  },
  {
    name: 'Images',
    rows: quest_image_block,
    optional: true,
  },
  {
    name: 'Games',
    rows: games_block,
  },
]

export const assets_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: asset_description_block,
  },
  {
    name: 'Images',
    rows: quest_image_block,
    optional: true,
  },
  {
    name: 'Games',
    rows: games_block,
  },
]

export const tokens_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: token_description_block,
  },
  {
    name: 'Images',
    rows: quest_image_block,
    optional: true,
  },
  {
    name: 'Games',
    rows: games_block,
  },
]

export const tournaments_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: tournament_description_block,
  },
  {
    name: 'Images',
    rows: quest_image_block,
    optional: true,
  },
  {
    name: 'Games',
    rows: games_block,
  },
]

export const merch_content: BlockContentType[] = [
  {
    name: '',
    rows: merch_type_block,
  },
  {
    name: 'Description',
    rows: merch_description_block,
  },
  {
    name: 'Images',
    rows: quest_image_block,
    optional: true,
  },
  {
    name: 'Games',
    rows: games_block,
  },
]

export const content_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: content_description_block,
  },
  {
    name: 'Images',
    rows: content_image_block,
  },
  {
    name: 'Games',
    rows: games_block,
  },
  {
    name: 'Schedule',
    rows: schedule_block,
    switch: 'is_scheduled',
  },
]

export const announcements_content: BlockContentType[] = [
  {
    name: 'Description',
    rows: announcement_description_block,
  },
  {
    name: 'Images',
    rows: announcement_image_block,
  },
  {
    name: 'Games',
    rows: games_block,
    optional: true,
  },
]

export const games_content: BlockContentType[] = [
  {
    name: 'Images',
    rows: games_image_block,
  },
  {
    name: 'Trailer Video',
    rows: games_video_block,
  },
  {
    name: 'Second trailer Video',
    rows: second_games_video_block,
  },
  {
    name: 'Description',
    rows: games_description_block,
  },
  {
    name: 'Languages',
    rows: games_languages_block,
  },
  {
    name: 'Game files',
    rows: games_upload_block,
    switch: 'files_upload',
  },
  {
    name: 'Development',
    rows: games_development_block,
    switch: 'is_development',
  },
  {
    name: 'Alpha-testing',
    rows: games_alpha_block,
    switch: 'is_alpha',
  },
  {
    name: 'Social media links',
    rows: games_socials_block,
    switch: 'socials',
  },
]
