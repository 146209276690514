import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout'

export const getDatePickerStyles = (darkTheme: any) => {
  const commonStyles = {
    backgroundColor: darkTheme ? '#111' : '#f5f5f5',
    color: darkTheme ? 'white' : 'black',
  }

  return {
    [`.${pickersLayoutClasses.contentWrapper}`]: commonStyles,
    [`.${pickersLayoutClasses.actionBar}`]: commonStyles,
    '.MuiButtonBase-root.MuiIconButton-root': {
      color: darkTheme ? 'white' : 'black',
    },
    '.Mui-selected': {
      backgroundColor: '#e844c9 !important',
    },
    '.MuiPickersDay-root': {
      color: darkTheme ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#e844c9',
      },
    },
    '.Mui-disabled': {
      color: darkTheme ? '#8d8d8d !important' : '#A9A9A9 !important',
    },
    '.MuiList-root': {
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none !important',
      },
    },
    '.MuiMultiSectionDigitalClockSection-item': {
      '&:hover': {
        color: darkTheme ? '#8d8d8d' : '#A9A9A9',
      },
    },
    '.MuiDayCalendar-weekDayLabel': {
      color: darkTheme ? 'white' : 'black',
    },
    '.MuiPickersDay-today': {
      border: '#e844c9 solid 1px !important',
    },
    '.MuiButton-root': {
      color: darkTheme ? 'white' : 'black',
    },
    '.MuiDivider-root': {
      backgroundColor: darkTheme ? '#2D2D2D' : '#DBDBDB',
    },
  }
}

export const getDatePickerInputStyles = (darkTheme: any) => {
  const commonInputStyles = {
    borderRadius: '16px',
    fontFamily: 'gilroy',
    fontSize: '16px',
    '& .MuiSvgIcon-root': {
      color: darkTheme ? 'white !important' : 'black !important',
    },
  }

  return {
    width: '100%',
    borderRadius: '16px',
    outline: 'none',
    outlineOffset: 0,
    '& label': {
      color: darkTheme ? '#8d8d8d' : '#A9A9A9',
      fontFamily: 'gilroy',
      fontSize: '14px',
    },
    '& fieldset': {
      '&:hover': {
        border: 'none !important',
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#e844c9',
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
      color: darkTheme ? 'white' : 'black',
      backgroundColor: darkTheme ? '#111' : '#f5f5f5',
      border: darkTheme ? '1px solid #2D2D2D' : '1px solid #DBDBDB',
      ...commonInputStyles,
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #e844c9',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '12px',
    },
    '& .MuiPickersLayout-contentWrapper': {
      backgroundColor: darkTheme ? '#111' : '#f5f5f5',
      color: darkTheme ? 'white' : 'black',
    },
  }
}
