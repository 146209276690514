import { Routes, Route } from 'react-router-dom'
import { Navigate } from 'react-router'
import HomePage from '../pages/HomePage'
import { Temporary } from './styled'
import ProfilePage from '../pages/ProfilePage'
import FeedPage from '../pages/FeedPage'
import QuestPage from '../pages/QuestPage'
import ContentPage from '../pages/ContentPage'
import JobPage from '../pages/JobPage'
import AnnouncementPage from '../pages/AnnouncementPage'
import GamePage from '../pages/GamePage'
import GamesPage from '../pages/GamesPage'
import ModsPage from '../pages/ModeratorsPage'
import MerchPage from '../pages/MerchPage'
import AssetPage from '../pages/AssetPage'
import OfferPage from '../pages/OfferPage'
import TokenPage from '../pages/Token'
import TournamentPage from '../pages/TournamentPage'
import AdminPage from '../pages/AdminPage'

export const privateRoutes = [
  {
    id: 1,
    path: '/',
    page: <HomePage />,
  },
  {
    id: 2,
    path: '/profile',
    page: <ProfilePage />,
  },
  {
    id: 3,
    path: '/games',
    page: <GamesPage />,
  },
  {
    id: 4,
    path: '/articles',
    page: <Temporary>Articles</Temporary>,
  },
  {
    id: 5,
    path: '/announcement',
    page: <AnnouncementPage />,
  },
  {
    id: 6,
    path: '/announcement/:id',
    page: <AnnouncementPage />,
  },
  {
    id: 7,
    path: '/quest',
    page: <QuestPage />,
  },
  {
    id: 8,
    path: '/quest/:id',
    page: <QuestPage />,
  },
  {
    id: 9,
    path: '/content/',
    page: <ContentPage />,
  },
  {
    id: 10,
    path: '/content/:id',
    page: <ContentPage />,
  },
  {
    id: 11,
    path: '/job/',
    page: <JobPage />,
  },
  {
    id: 12,
    path: '/job/:id',
    page: <JobPage />,
  },
  {
    id: 15,
    path: '/feed',
    page: <FeedPage />,
  },
  {
    id: 16,
    path: '/game',
    page: <GamePage />,
  },
  {
    id: 17,
    path: '/game/:id',
    page: <GamePage />,
  },
  {
    id: 18,
    path: '/moderators',
    page: <ModsPage />,
  },
  {
    id: 19,
    path: '/merch',
    page: <MerchPage />,
  },
  {
    id: 191,
    path: '/merch/:id',
    page: <MerchPage />,
  },
  {
    id: 20,
    path: '/tournament',
    page: <TournamentPage />,
  },
  {
    id: 201,
    path: '/tournament/:id',
    page: <TournamentPage />,
  },
  {
    id: 22,
    path: '/offer',
    page: <OfferPage />,
  },
  {
    id: 221,
    path: '/offer/:id',
    page: <OfferPage />,
  },
  {
    id: 23,
    path: '/digital_asset',
    page: <AssetPage />,
  },
  {
    id: 231,
    path: '/digital_asset/:id',
    page: <AssetPage />,
  },
  {
    id: 24,
    path: '/token',
    page: <TokenPage />,
  },
  {
    id: 241,
    path: '/token/:id',
    page: <TokenPage />,
  },
  {
    id: 25,
    path: '/mcade-admin',
    page: <AdminPage />,
  },
  {
    id: 26,
    path: '*',
    page: <Navigate to="/" />,
  },
]

function PrivateContent() {
  return (
    <div className="App">
      <Routes>
        {privateRoutes.map((el) => (
          <Route key={el.id} path={el.path} element={el.page} />
        ))}
      </Routes>
    </div>
  )
}

export default PrivateContent
