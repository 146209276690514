import { ModsTableType } from '@/types'
import * as Styled from '../styled'

import { useContext, useState } from 'react'
import { DataContext } from '../../context'
import DeleteModModal from '../modals/DeleteModModal'
import { DeleteIcon, LinkIcon } from '../../icons'

import { truncateString } from '../../utils/utils'

function ModsTableRow({
  row,
  index,
  isHeader,
  getModerators,
  showExistingLink,
}: {
  row?: ModsTableType
  index?: number
  isHeader?: boolean
  getModerators?: Function
  showExistingLink?: Function
}) {
  const { darkTheme } = useContext(DataContext)

  const cellColor = row ? (darkTheme ? 'white' : 'black') : '#8D8D8D'

  const Actions = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleModalOpen = () => {
      setIsModalOpen(true)
    }

    const handleModalClose = () => {
      setIsModalOpen(false)
    }

    const iconStyle = {
      cursor: 'pointer',
      filter: darkTheme
        ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(151deg) brightness(104%) contrast(101%)'
        : undefined,
      marginLeft: 'auto',
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        {!!row?.code && (
          <LinkIcon
            onClick={() => showExistingLink?.(row.url)}
            style={{ ...iconStyle, marginTop: '0.75rem' }}
          />
        )}
        <DeleteIcon
          onClick={handleModalOpen}
          style={{ ...iconStyle, marginTop: '0.4rem' }}
        />
        <DeleteModModal
          open={isModalOpen}
          close={handleModalClose}
          row={row}
          getModerators={getModerators}
        />
      </div>
    )
  }
  return (
    <Styled.FeedTableRowContainer $darktheme={darkTheme} key={row?.id || index}>
      <Styled.TableCell color="#8D8D8D" width="3%">
        {isHeader ? '#' : `0${index + 1}`}
      </Styled.TableCell>
      <Styled.TableCell
        color={cellColor}
        $bold={row ? true : undefined}
        width="25%"
      >
        {isHeader ? 'Name' : row.userName || '-'}
      </Styled.TableCell>
      <Styled.TableCell color={cellColor} width="30%">
        {isHeader ? 'E-mail' : row.email}
      </Styled.TableCell>
      <Styled.TableCell color={cellColor} width="30%">
        {isHeader ? 'Wallet address' : truncateString(row.walletAddress) || '-'}
      </Styled.TableCell>
      <Styled.TableCell color={cellColor} width="7%" $last={true}>
        {isHeader ? 'Actions' : <Actions />}
      </Styled.TableCell>
    </Styled.FeedTableRowContainer>
  )
}

export default ModsTableRow
