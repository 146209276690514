import { AuthContextType, DataContextType, PartnerContextType } from '../types'
import { createContext } from 'react'

export const PartnerContext = createContext<PartnerContextType>({
  partner_id: null,
  partner_email: null,
  partner_cms_id: null,
  setPartnerId: () => {},
  setPartnerCmsId: () => {},
  setPartnerEmail: () => {},
  partner_name: null,
  setPartnerName: () => {},
  logo: null,
  setLogo: () => {},
  passStatus: null,
  setPassStatus: () => {},
  passExpire: null,
  setPassExpire: () => {},
  canceled: false,
  setCanceled: () => {},
  referrerId: null,
  setReferrerId: () => {},
  referralLink: null,
  setReferralLink: () => {},
})

export const AuthContext = createContext<AuthContextType>({
  isAuth: false,
  setAuth: () => {},
})

export const DataContext = createContext<DataContextType>({
  filter: null,
  setFilter: () => {},
  feedData: [],
  setFeedData: () => {},
  page: 1,
  setPage: () => {},
  pageCount: 1,
  setPageCount: () => {},
  totalItems: 0,
  setTotalItems: () => {},
  darkTheme: false,
  setDarkTheme: () => {},
})
