import * as Styled from './styled'

function Popular({ isDark }: { isDark?: boolean }) {
  return (
    <Styled.PopularContainer $darktheme={isDark}>
      Popular
    </Styled.PopularContainer>
  )
}

export default Popular
