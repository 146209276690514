import { useNavigate } from 'react-router-dom'
import { DeleteIcon, EditIcon } from '../icons'
import * as Styled from './styled'
import { FeedRowStatusType } from '../types'
import { useContext, useState } from 'react'
import DeleteModal from './modals/DeleteModal'
import { DataContext } from '../context'
import { getStatus } from '../utils/utils'

function FeedRowStatus({
  status,
  hover,
  type_id,
  type,
  feed_id,
}: FeedRowStatusType) {
  const [isModalOpen, setModalOpen] = useState(false)
  const { darkTheme } = useContext(DataContext)

  const navigate = useNavigate()

  const { color, title } = getStatus(status)

  if (hover) {
    return (
      <Styled.EditContainer darkTheme={darkTheme}>
        <DeleteModal
          open={isModalOpen}
          setOpen={setModalOpen}
          itemData={{ feed_id, type_id, type }}
        />
        <EditIcon
          onClick={() => navigate(`/${type.toLowerCase()}/${type_id}`)}
        />
        <DeleteIcon onClick={() => setModalOpen(true)} />
      </Styled.EditContainer>
    )
  }

  return (
    <Styled.Status color={color} $darktheme={darkTheme}>
      {title}
    </Styled.Status>
  )
}

export default FeedRowStatus
