import { IJobData } from '../../types'
import { tagsToArray } from '../utils'
import Api from '../../services/api'

export const getJobData = async (jobId: string) => {
  if (!jobId) {
    return {}
  }
  const response = await Api.get<IJobData>(`partners/job/${jobId}`)

  return await response.data
}

export const postJobData = async (values: IJobData) => {
  const dataToSend: any = {
    title: values.title,
    company: values.company,
    location: values.location,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    short_description: values.short_description,
    employment_type: values.employment_type,
    salary: values.salary,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
  }

  let formData = new FormData()

  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<IJobData>(
    `partners/job`,
    formData,
    {}
  )
  return response.data?.data?.id
}

export const updateJobData = async (values: IJobData, questId: string) => {
  const dataToSend: any = {
    title: values.title,
    company: values.company,
    location: values.location,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    short_description: values.short_description,
    employment_type: values.employment_type,
    salary: values.salary,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    trailer_video: values.trailer_video,
  }

  let formData = new FormData()

  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IJobData>(
    `partners/job/${questId}`,
    formData,
    {}
  )
  return response.data
}
