import { DEVICES } from '../../responsive'
import styled from 'styled-components'

export const ModalContainer = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  justify-content: center;
  align-items: center;
  font-family: gilroy-semibold;
`

export const Modal = styled.div<{ $darktheme?: boolean }>`
  position: relative;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  width: 30rem;
  min-height: 15rem;
  background: ${(props) => (props.$darktheme ? '#222' : 'white')};
  color: ${(props) => (props.$darktheme ? 'white' : 'black')};
  border-radius: 1rem;
  min-width: 90vw;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.06);
  font-size: 1.1rem;

  & .close-icon {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    cursor: pointer;
  }

  @media ${DEVICES.lg} {
    padding: 2rem 3rem;
    margin: 0;
    min-width: 30rem;
  }
`

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Input = styled.div<{ $darktheme?: boolean }>`
  & input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 48px;
    border-radius: 16px;
    padding: 12px;
    border: 1px solid #dbdbdb;
    outline: none;
    font-size: 16px;
    outline-offset: 0;
    font-family: gilroy;
    border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
    background: #f5f5f5;
    color: #8d8d8d;
    resize: none;
    transition: min-height 0.5s ease-out;
  }

  & .copy-icon {
    position: absolute;
    right: 4rem;
    top: 72%;
    cursor: pointer;
  }
`
