import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import './fonts/gilroy-regular.woff2'
import './fonts/gilroy-medium.woff2'
import './fonts/gilroy-semibold.woff2'
import './fonts/gilroy-bold.woff2'
import './fonts/gilroy-black.woff2'
import ToastifyRegistry from './components/registries/ToastifyRegistry'
import ContextProvider from './context/ContextProvider'
import PartnerPlatform from './PartnerPlatform'
import { CookiesProvider } from 'react-cookie'

function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <ContextProvider>
        <BrowserRouter>
          <ToastifyRegistry>
            <PartnerPlatform />
          </ToastifyRegistry>
        </BrowserRouter>
      </ContextProvider>
    </CookiesProvider>
  )
}

export default App
