import { useCookies } from 'react-cookie'
import Button from './buttons/Button'
import * as Styled from './styled'
import { AuthContext, DataContext, PartnerContext } from '../context'
import { useContext } from 'react'
import Api from '../services/api'
import { Partner } from '@/types/partner'

function Header() {
  const { setAuth } = useContext(AuthContext)
  const {
    setPartnerCmsId,
    setPartnerEmail,
    setPassStatus,
    setPassExpire,
    setCanceled,
    setReferrerId,
    setReferralLink,
  } = useContext(PartnerContext)
  const { darkTheme } = useContext(DataContext)
  const onLogout = async () => {
    await Api.post('users/logout', {})
    setPartnerCmsId(null)
    setPartnerEmail(null)
    setPassStatus(null)
    setPassExpire(null)
    setCanceled(false)
    setReferrerId(null)
    setReferralLink(null)
    setAuth(false)
    window.location.pathname = '/'
  }

  return (
    <Styled.HeaderContainer darkTheme={darkTheme}>
      <div></div>
      <Styled.HeaderButtons>
        <Button color="black" onClick={onLogout}>
          Sign Out
        </Button>
      </Styled.HeaderButtons>
    </Styled.HeaderContainer>
  )
}

export default Header
