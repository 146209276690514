import { ChevronDownIcon } from '../icons'
import { useContext } from 'react'
import * as Styled from './styled'
import { DataContext } from '../context'
import { useClose } from '../hooks/useClose'
import { MultiselectName } from '@/types'

function Multiselect({
  options,
  value,
  setValue,
  type,
  name,
}: {
  options: string[]
  value: any
  setValue: any
  type: MultiselectName
  name: string
}) {
  const { show, setShow } = useClose(type)
  const { darkTheme } = useContext(DataContext)

  const onSelectChange = (e: any, item: string) => {
    e.stopPropagation()
    setValue({
      ...value,
      filters: value.filters?.[type]?.includes(item)
        ? {
            ...value.filters,
            [type]: value.filters?.[type]?.filter((el: string) => el !== item),
          }
        : { ...value.filters, [type]: [...value.filters?.[type], item] },
    })
  }

  return (
    <Styled.MultiselectContainer
      $darktheme={darkTheme}
      onClick={() => setShow(!show)}
      id={`element-${type}`}
    >
      {name}
      <div className="multiselect-icon" id={`button-${type}`}>
        <ChevronDownIcon />
      </div>
      {show && (
        <Styled.MultiselectMenu
          id={`menu-${type}`}
          $darktheme={darkTheme}
          className={darkTheme ? 'custom-scrollbar-dark' : 'custom-scrollbar'}
        >
          {options.map((item) => {
            return (
              <Styled.MultiselectItem
                $darktheme={darkTheme}
                onClick={(e) => onSelectChange(e, item)}
                key={item}
                className="menu-item"
              >
                {item}
                <Styled.MultiselectCheckbox
                  $darktheme={darkTheme}
                  //   value={value.filters?.[type]?.[type]?.includes(item)}
                  checked={value.filters?.[type]?.includes(item) || false}
                  type="checkbox"
                  name={item}
                />
              </Styled.MultiselectItem>
            )
          })}
        </Styled.MultiselectMenu>
      )}
    </Styled.MultiselectContainer>
  )
}

export default Multiselect
