import { FeedTableType } from '@/types'
import * as Styled from './styled'
import FeedRowStatus from './FeedRowStatus'
import { useContext, useState } from 'react'
import { ITEMS_PER_PAGE } from '../constants'
import { DataContext } from '../context'

function FeedTableRow({
  row,
  page = 0,
}: {
  row?: FeedTableType
  page?: number
}) {
  const { darkTheme } = useContext(DataContext)
  const [hover, setHover] = useState(false)
  const indexCoeff = ITEMS_PER_PAGE * (page - 1)
  const index = (row?.index || 0) + indexCoeff
  const indexString = (index.toString()?.length || 1) > 1 ? index : '0' + index
  return (
    <Styled.FeedTableRowContainer
      $darktheme={darkTheme}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <Styled.TableCell color="#8D8D8D" width="10%">
        {row ? indexString : '#'}
      </Styled.TableCell>
      <Styled.TableCell
        color={row ? (darkTheme ? 'white' : 'black') : '#8D8D8D'}
        $bold={row ? true : undefined}
        width="25%"
      >
        {row ? row.title : 'Title'}
      </Styled.TableCell>
      <Styled.TableCell
        color={row ? (darkTheme ? 'white' : 'black') : '#8D8D8D'}
        width="15%"
      >
        {row ? row?.type?.replace('_', ' ') : 'Type'}
      </Styled.TableCell>
      <Styled.TableCell
        color={row ? (darkTheme ? '#8D8D8D' : '#4A4949') : '#8D8D8D'}
        width="20%"
      >
        {row
          ? row.tags.map((tag) => (
              <span key={tag}>{tag.toLowerCase() + ' '}</span>
            ))
          : 'Tag'}
      </Styled.TableCell>
      <Styled.TableCell
        color={row ? (darkTheme ? '#656565' : '#8D8D8D') : '#8D8D8D'}
        width="10%"
      >
        {row ? row.time : 'Time'}
      </Styled.TableCell>
      <Styled.TableCell
        color={row ? (darkTheme ? '#656565' : '#8D8D8D') : '#8D8D8D'}
        width="15%"
      >
        {row ? row.date : 'Date'}
      </Styled.TableCell>
      <Styled.TableCell color={row ? 'black' : '#8D8D8D'} width="15%" $last>
        {row ? (
          <FeedRowStatus
            type={row.type}
            type_id={row.typeId}
            feed_id={row.id}
            status={row.status}
            hover={hover}
          />
        ) : (
          'Status'
        )}
      </Styled.TableCell>
    </Styled.FeedTableRowContainer>
  )
}

export default FeedTableRow
