import { MultiselectName } from '@/types'
import { useEffect, useState } from 'react'

const handleClickOutside = function (
  e: any,
  setShow: (show: boolean) => void,
  name: MultiselectName
) {
  const element = document.getElementById(`element-${name}`)
  const button = document.getElementById(`button-${name}`)
  const menuItems = document.getElementsByClassName(`menu-item`)
  const menu = document.getElementById(`menu-${name}`)
  const inputs = menu?.getElementsByTagName(`input`)
  if (!!inputs) {
    if (
      !e.target.isEqualNode(element) &&
      !e.target.isEqualNode(button) &&
      ![].slice.call(inputs || [])?.some((el) => el === e.target) &&
      ![].slice.call(menuItems || [])?.some((el) => el === e.target)
    ) {
      setShow(false)
    }
  }
}

export const useClose = (name: MultiselectName) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      window.addEventListener('click', (e) =>
        handleClickOutside(e, setShow, name)
      )
    } else {
      window.removeEventListener('click', (e) =>
        handleClickOutside(e, setShow, name)
      )
    }
    return () =>
      window.removeEventListener('click', (e) =>
        handleClickOutside(e, setShow, name)
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return { show, setShow }
}
