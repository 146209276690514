const SCREEN_SIZE = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  _2xl: 1536,
  _3xl: 1800,
}

export const DEVICES = {
  sm: `(min-width: ${SCREEN_SIZE.sm}px)`,
  md: `(min-width: ${SCREEN_SIZE.md}px)`,
  lg: `(min-width: ${SCREEN_SIZE.lg}px)`,
  xl: `(min-width: ${SCREEN_SIZE.xl}px)`,
  _2xl: `(min-width: ${SCREEN_SIZE._2xl}px)`,
  _3xl: `(min-width: ${SCREEN_SIZE._3xl}px)`,
}
