import { useContext, useEffect, useState } from 'react'
import { sequence } from '0xsequence'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Api from '../../services/api'
import { Partner } from '../../types/partner'
import { AuthContext, DataContext, PartnerContext } from '../../context'
import { handleConnectError } from '../../utils/errors'
import { isDev } from '../../config'
import Button from './Button'

export function ConnectButton() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { setAuth } = useContext(AuthContext)
  const {
    setPartnerCmsId,
    setPartnerEmail,
    setPassStatus,
    setPassExpire,
    setCanceled,
  } = useContext(PartnerContext)
  const { darkTheme } = useContext(DataContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    sequence.initWallet(process.env.REACT_APP_SEQUENCE_PROJECT_ACCESS_KEY, {
      defaultNetwork: isDev() ? 'sepolia' : 'polygon',
    })
    setLoading(false)
  }, [])

  const onConnect = async () => {
    const wallet = sequence.getWallet()
    const connect = await wallet.connect({
      askForEmail: true,
      app: 'Partner Platform',
      authorize: true,
      settings: {
        theme: darkTheme ? 'dark' : 'light',
      },
    })

    if (!connect.connected) {
      return
    }

    const token = connect.proof?.proofString
    try {
      const partner = await Api.post<Partner>(
        'partners',
        {
          email: connect.email,
          signupCode: searchParams.get('code') || '',
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )

      if (
        partner.id &&
        partner.passStatus &&
        partner.passStatus !== 'pending'
      ) {
        setPartnerCmsId(partner?.strapiId || null)
        setPartnerEmail(partner?.email || null)
        setPassStatus(partner?.passStatus || null)
        setPassExpire(partner?.subscriptionEndsAt || null)
        setCanceled(partner?.isCanceled || false)
        setAuth(true)
      }
      if (
        partner.id &&
        (!partner.passStatus || partner.passStatus === 'pending')
      ) {
        navigate('/subscription')
      }
    } catch (error: any) {
      handleConnectError(error)
      console.error(error)
    }
  }

  if (loading) {
    return null
  }

  return (
    <div>
      <Button color="black" onClick={onConnect}>
        Sign In
      </Button>
    </div>
  )
}
