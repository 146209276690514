import { DataContext } from '../../context'

import { useState, useContext } from 'react'
import * as Styled from './styled'
import Button from '../buttons/Button'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import CloseButton from '../buttons/CloseButton'
import { useCookies } from 'react-cookie'
import { Moderator } from '../../types/partner'
import Api from '../../services/api'

interface IModal {
  open: boolean
  close: Function
  row: Moderator
  getModerators: Function | undefined
}

function DeleteModal({ open, close, row, getModerators }: IModal) {
  const isPending = !!row?.code
  const [loading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')

  const { darkTheme } = useContext(DataContext)

  const onDelClick = async () => {
    setLoading(true)

    const authOptions = {}
    try {
      if (isPending) {
        await Api.delete(`/codes/${row.email}`, authOptions)
      } else {
        await Api.delete(`partners/moderator/${row.id}`, authOptions)
      }

      getModerators?.()

      toast(
        `${isPending ? 'Invite for ' : 'Moderator '}${
          row.email
        } was successfully deleted`
      )
      close()
    } catch (error) {
      toast('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled.ModalContainer onClick={() => close()} open={open}>
      <Styled.Modal $darktheme={darkTheme} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => close()} />
        <div style={{ marginBottom: '2rem' }}>
          Are you sure you want to delete {row.email}?
        </div>
        <Styled.ButtonsGroup>
          <Button
            color={darkTheme ? 'white-transparent' : 'white'}
            onClick={onDelClick}
            onMouseEnter={() => setLoaderColor(darkTheme ? 'black' : 'white')}
            onMouseLeave={() => setLoaderColor(darkTheme ? 'white' : 'black')}
          >
            {loading ? (
              <Loader color={loaderColor} />
            ) : (
              `Delete ${isPending ? 'invite' : 'moderator'}`
            )}
          </Button>
          <Button
            onClick={() => close()}
            color={darkTheme ? 'white-transparent' : 'white'}
          >
            Cancel
          </Button>
        </Styled.ButtonsGroup>
      </Styled.Modal>
    </Styled.ModalContainer>
  )
}

export default DeleteModal
