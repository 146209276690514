import styled from 'styled-components'

export const NewEntryMenuContainer = styled.div<{
  open: boolean
  darkTheme?: boolean
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 4rem;
  right: 0;
  width: 216px;
  border-radius: 1rem;
  background: ${(props) => (props.darkTheme ? '#111' : 'white')};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  z-index: 999;
  font-family: gilroy-semibold;
  font-size: 16px;
  overflow: hidden;
`

export const NewEntryMenuItem = styled.div<{ darkTheme?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.darkTheme ? '#222' : '#f5f5f5')};
  }
`

export const GameMenuContainer = styled.div<{
  open: boolean
  darkTheme?: boolean
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 2rem;
  right: 1rem;
  width: 7rem;
  border-radius: 1rem;
  background: ${(props) => (props.darkTheme ? '#111' : 'white')};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  z-index: 999;
  font-family: gilroy-semibold;
  font-size: 16px;
  overflow: hidden;
`
