import { toast } from 'react-toastify'

export const handleConnectError = (error: any) => {
  if (error?.response?.data?.code === 'CODE_NOT_FOUND') {
    return toast('Your signup code was not found in database')
  }
  if (error?.response?.data?.code === 'CODE_ALREADY_USED') {
    return toast('Your signup code is expired')
  }
  if (error?.response?.data?.error) {
    return toast(error.response.data.error)
  }
  if (error?.response?.data?.message) {
    return toast(error.response.data.message)
  }
}
