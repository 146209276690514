import {
  IAnnouncementData,
  IContentData,
  IGameData,
  IJobData,
  IMerchData,
  IQuestData,
  ITournamentData,
} from '../types'

export const defaultProfileData = {
  name: '',
  email: '',
  description: '',
  short_description: '',
  logo: '',
  preview_image: '',
  website_link: '',
  video_thumbnail: '',
  about_preview: '',
  about_text: '',
  currentGame: {
    id: 0,
    title: '',
  },
  games: [],
  x: '',
  instagram: '',
  youtube: '',
  twitch: '',
  telegram: '',
  linkedin: '',
  linktree: '',
  tiktok: '',
}

export const defaultQuestData: IQuestData = {
  type: null,
  title: null,
  // status: null,
  is_published: false,
  description: null,
  short_description: '',
  preview_image: '',
  url: null,
  tags: '',
  currentGame: {
    id: 0,
    title: '',
  },
  games: [],
  trailer_video: '',
}

export const defaultRewardData: ITournamentData = {
  title: null,
  is_published: false,
  description: null,
  preview_image: '',
  url: null,
  tags: '',
  currentGame: {
    id: 0,
    title: '',
  },
  games: [],
  trailer_video: '',
}

export const defaultTournamentData: ITournamentData = {
  title: null,
  is_published: false,
  description: null,
  preview_image: '',
  url: null,
  tags: '',
  currentGame: {
    id: 0,
    title: '',
  },
  games: [],
  trailer_video: '',
}

export const defaultMerchData: IMerchData = {
  type: null,
  title: null,
  is_published: false,
  description: null,
  preview_image: '',
  url: null,
  tags: '',
  trailer_video: '',
}

export const defaultJobData: IJobData = {
  title: null,
  company: '',
  location: '',
  is_published: false,
  employment_type: null,
  description: null,
  short_description: '',
  salary: '',
  url: '',
  tags: '',
  trailer_video: '',
}

export const defaultContentData: IContentData = {
  type: null,
  title: null,
  is_published: false,
  description: null,
  short_description: '',
  preview_image: '',
  url: '',
  reward: '',
  tags: '',
  currentGame: {
    id: 0,
    title: '',
  },
  games: [],
  trailer_video: '',
}

export const defaultAnnouncementData: IAnnouncementData = {
  title: null,
  is_published: false,
  description: null,
  short_description: '',
  preview_image: '',
  url: null,
  // tags: '',
  currentGame: {
    id: 0,
    title: '',
  },
  games: [],
  trailer_video: '',
}

export const defaultGameData: IGameData = {
  title: null,
  is_alpha: false,
  is_development: false,
  description: null,
  short_description: null,
  preview_images: [],
  logo: '',
  specifications: null,
  filters: {
    category: [],
    genres: [],
    platform: [],
    age_rate: '',
    game_type: '',
    network: [],
  },
  trailer_video_url: '',
  trailer_video_thumbnail: '',
  second_trailer_video_url: '',
  second_trailer_video_thumbnail: '',
  languages_audio: '',
  languages_text: '',
  release_date: null,
  alpha_finish_date: '',
  alpha_extra_text: '',
  alpha_extra_image: '',
  alpha_extra_questions_title: '',
  alpha_extra_questions_type: '',
  x: '',
  discord: '',
  reddit: '',
  linktree: '',
  website: '',
  google_store_url: '',
  apple_store_url: '',
  source_url: '',
  status: null,
}
