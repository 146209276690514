export const appendImage = (
  type: string,
  image: string | File,
  formData: FormData
) => {
  if (typeof image === 'object') {
    formData.append(`files.${type}`, image, image.name)
  }
}

export const appendImages = (
  type: string,
  images: any[],
  formData: FormData
) => {
  images.forEach((image) => {
    if (image?.name) {
      formData.append(`files.${type}`, image, image.name)
    }
  })
}
