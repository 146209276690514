import styled from 'styled-components'

export const TierCard = styled.div<{ $darktheme?: boolean }>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 8rem;
  top: -10rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-family: gilroy;
  gap: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  border: ${(props) => (props.$darktheme ? 'none' : '1px solid #e0e0e0')};
  background: ${(props) =>
    props.$darktheme
      ? 'linear-gradient(180deg, rgba(78, 78, 78, 0.64) 0%, rgba(17, 17, 17, 0.64) 100%), #000'
      : '#fff'};
  color: ${(props) => (props.$darktheme ? 'white' : 'black')};
  z-index: 999;
  transition: all 0.2s ease-out;

  & ul {
    margin: 0;
    text-align: start;
  }

  & li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid
      ${(props) => (props.$darktheme ? '#4A4949' : '#e0e0e0')};

    & svg {
      flex: 0 0 10%;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`
