import { IContentData } from '../../types'
import { tagsToArray } from '../utils'
import { appendImage } from './heplers'
import Api from '../../services/api'

export const getContentData = async (contentId: string) => {
  if (contentId) {
    const response = await Api.get<IContentData>(
      `partners/content/${contentId}`
    )

    return response.data
  }
  return {}
}

export const postContentData = async (values: IContentData) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    short_description: values.short_description,
    external_url: values.url,
    reward: values.reward,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    type: 'review',
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<IContentData>(
    `partners/content`,
    formData,
    {}
  )

  return response.data?.data?.id
}

export const updateContentData = async (
  values: IContentData,
  contentId: string
) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    short_description: values.short_description,
    external_url: values.url,
    reward: values.reward,
    type: values.type,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IContentData>(
    `partners/content/${contentId}`,
    formData,
    {}
  )

  return response.data
}
