import { OkIcon, OkWhiteIcon } from '../../icons'
import {
  SubscriptionType,
  Price,
  Previous,
  Features,
  Description,
} from '../../pages/styled'
import { Status } from '../styled'
import { SubscriptionContentType } from '@/types'
import * as Styled from './styled'
import Popular from '../Popular'
import { DataContext } from '../../context'
import { useContext } from 'react'

function TierCard({ card }: { card: SubscriptionContentType }) {
  const { darkTheme } = useContext(DataContext)
  return (
    <Styled.TierCard $darktheme={darkTheme} className="tier-card">
      <SubscriptionType color={card.type.color}>
        {card.type.title}
        {card.popular && <Popular />}
      </SubscriptionType>
      <Description>{card.description}</Description>
      <Price $darktheme={darkTheme}>
        {'$' + card.price}
        <span>/month</span>
      </Price>
      {card.previous && (
        <Previous>
          {'All of the '}
          <Status color={card.previous.color}>{card.previous.title}</Status>
          {' bundle +'}
        </Previous>
      )}
      <Features>
        <ul>
          {card.features.map((item) => (
            <li key={item}>
              {darkTheme ? <OkWhiteIcon /> : <OkIcon />}
              {item}
            </li>
          ))}
        </ul>
      </Features>
    </Styled.TierCard>
  )
}

export default TierCard
