import { ButtonColors } from '@/components/buttons/Button'

export const setColor = (
  color: ButtonColors,
  type:
    | 'background'
    | 'text'
    | 'background-hover'
    | 'text-hover'
    | 'border'
    | 'border-hover'
) => {
  if (type === 'background') {
    switch (color) {
      case 'pink': {
        return '#E844C9'
      }
      case 'black': {
        return '#000'
      }
      case 'black-transparent': {
        return '#000'
      }
      case 'white': {
        return '#fff'
      }
      case 'white-transparent': {
        return 'transparent'
      }
      case 'transparent': {
        return '#11ffee00'
      }
    }
  }
  if (type === 'text') {
    switch (color) {
      case 'pink': {
        return '#fff'
      }
      case 'black': {
        return '#fff'
      }
      case 'black-transparent': {
        return '#fff'
      }
      case 'white': {
        return '#000'
      }
      case 'white-transparent': {
        return '#fff'
      }
      case 'transparent': {
        return '#000'
      }
    }
  }
  if (type === 'background-hover') {
    switch (color) {
      case 'pink': {
        return '#fff'
      }
      case 'black': {
        return '#fff'
      }
      case 'black-transparent': {
        return 'transparent'
      }
      case 'white': {
        return '#000'
      }
      case 'white-transparent': {
        return '#fff'
      }
      case 'transparent': {
        return '#000'
      }
    }
  }
  if (type === 'text-hover') {
    switch (color) {
      case 'pink': {
        return '#E844C9'
      }
      case 'black': {
        return '#000'
      }
      case 'black-transparent': {
        return '#000'
      }
      case 'white': {
        return '#fff'
      }
      case 'white-transparent': {
        return '#000'
      }
      case 'transparent': {
        return '#fff'
      }
    }
  }
  if (type === 'border') {
    switch (color) {
      case 'pink': {
        return '1px solid #E844C9'
      }
      case 'black': {
        return '1px solid #fff'
      }
      case 'black-transparent': {
        return '1px solid #fff'
      }
      case 'white': {
        return '1px solid #000'
      }
      case 'white-transparent': {
        return '1px solid #fff'
      }
      case 'transparent': {
        return '1px solid #000'
      }
    }
  }

  if (type === 'border-hover') {
    switch (color) {
      case 'pink': {
        return '1px solid #E844C9'
      }
      case 'black': {
        return '1px solid #000'
      }
      case 'black-transparent': {
        return '1px solid #000'
      }
      case 'white': {
        return '1px solid #fff'
      }
      case 'white-transparent': {
        return '1px solid #fff'
      }
      case 'transparent': {
        return '1px solid #fff'
      }
    }
  }
}
