import { useNavigate, useParams } from 'react-router-dom'
import * as Styled from './styled'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import Button from '../components/buttons/Button'
import Loader from '../components/Loader'
import ContentBlockRow from '../components/ContentBlockRow'
import { tournaments_content } from './content'
import { defaultTournamentData } from './defaultData'
import {
  FeedTableType,
  ITournamentData,
  ShortFeedTableType,
  StatusType,
} from '../types'
import { transformTournamentData } from '../utils/data'
import { getCurrentFeed, onReviewClick, onSaveClick } from '../utils/save'
import { DataContext, PartnerContext } from '../context'
import { useCookies } from 'react-cookie'
import { unpublish } from '../utils/api'
import { getOneFeedData } from '../utils/api/feed'
import { getGamesData } from '../utils/api/game'
import {
  getTournamentData,
  postTournamentData,
  updateTournamentData,
} from '../utils/api/tournament'

function TournamentPage() {
  const params = useParams()
  const navigate = useNavigate()
  const { partner_cms_id } = useContext(PartnerContext)
  const { feedData, setPage, setFilter } = useContext(DataContext)
  const [postData, setPostData] = useState<ITournamentData>(
    defaultTournamentData
  )
  const [values, setValues] = useState<ITournamentData>(defaultTournamentData)
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingReview, setLoadingReview] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const [status, setStatus] = useState<StatusType>(null)
  const [currentFeed, setCurrentFeed] = useState<
    FeedTableType | ShortFeedTableType | null
  >(null)

  const getData = async (postId: string) => {
    try {
      const postData = await getTournamentData(postId)
      const gamesData = await getGamesData(partner_cms_id as string)
      const restructuredData = transformTournamentData(
        postData?.attributes || {},
        gamesData
      )
      setValues(restructuredData)
      setPostData(restructuredData)
      const feed = await getCurrentFeed(postId, 'tournament')
      setCurrentFeed(feed || null)
      setStatus(feed?.status || null)
    } catch (error) {
      console.error(error)
      toast('Error occured during tournament data fetching...')
    }
  }

  const onSaveDraftClick = async () => {
    const response = await onSaveClick<ITournamentData>(
      'tournament',
      values,
      params.id as string,
      currentFeed?.id.toString() as string,
      partner_cms_id as string,
      setLoadingSave,
      setStatus,
      getData,
      updateTournamentData,
      postTournamentData
    )
    if (response?.status === 'ok' && !params.id) {
      setValues(defaultTournamentData)
      navigate('/feed')
      setPage(1)
    }
  }

  const onUpublishClick = async () => {
    try {
      const response = await unpublish(currentFeed?.id.toString() as string)
      const update_feed_response = await getOneFeedData(
        currentFeed?.id.toString() as string
      )
      if (response === 200) {
        toast('Successfully unpublished')
        setStatus(update_feed_response?.attributes?.review_status || null)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onRequestReviewClick = async () => {
    if (JSON.stringify(postData) !== JSON.stringify(values)) {
      toast('Save updates first')
      return
    }
    const feedId = currentFeed?.id.toString()
    const response = await onReviewClick(feedId as string, setLoadingReview)
    if (response.status) {
      setStatus(response.status)
    }
  }

  useEffect(() => {
    getData(params.id as string)
    setFilter(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedData, params.id])

  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>Tournament</Styled.Title>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            onClick={onSaveDraftClick}
            color="black"
            disabled={
              JSON.stringify(postData) === JSON.stringify(values) ||
              status === 'published'
            }
            width="10rem"
            onMouseEnter={() => setLoaderColor('black')}
            onMouseLeave={() => setLoaderColor('white')}
          >
            {loadingSave ? (
              <Loader color={loaderColor} width="2.5rem" />
            ) : (
              'Save as Draft'
            )}
          </Button>
          {status === 'published' ? (
            <Button onClick={onUpublishClick}>Unpublish</Button>
          ) : (
            <Button
              onClick={onRequestReviewClick}
              disabled={
                status === 'in_review' || status === 'reviewed' || !params.id
              }
              onMouseEnter={() => setLoaderColor('white')}
              onMouseLeave={() => setLoaderColor('black')}
            >
              {loadingReview ? (
                <Loader color={loaderColor} width="8rem" />
              ) : status === 'in_review' ? (
                'In Review'
              ) : status === 'reviewed' ? (
                'Reviewed'
              ) : (
                'Request Review'
              )}
            </Button>
          )}
        </div>
      </Styled.TitleBlock>
      {tournaments_content.map((block) => (
        <Styled.ContentBlock key={block.name}>
          <Styled.ContentBlockTitle>
            <div>
              {block.name}
              {block.optional && (
                <span className="optional">{' (optional)'}</span>
              )}
            </div>
          </Styled.ContentBlockTitle>
          {block.rows.map((item) => (
            <ContentBlockRow
              key={item.tag}
              tag={item.tag}
              title={item.title}
              value={values}
              setValue={setValues}
              content_type={item.content_type}
              placeholder={item.placeholder}
              description={item.description}
              options={item.options}
              required={item.required}
              expandable={item.expandable}
            />
          ))}
        </Styled.ContentBlock>
      ))}
    </Styled.PageContainer>
  )
}

export default TournamentPage
