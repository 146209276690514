import { IMerchData } from '../../types'
import { tagsToArray } from '../utils'
import { appendImage } from './heplers'
import Api from '../../services/api'

export const getMerchData = async (postId: string) => {
  if (!postId) {
    return {}
  }
  const response = await Api.get<IMerchData>(`partners/merch/${postId}`)
  return response.data
}

export const postMerchData = async (values: IMerchData) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    type: values.type,
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
  }
  const formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<IMerchData>(
    `partners/merch`,
    formData
  )

  return response.data?.data?.id
}

export const updateMerchData = async (values: IMerchData, postId: string) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    type: values.type,
    trailer_video: values.trailer_video,
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
  }
  const formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IMerchData>(
    `partners/merch/${postId}`,
    formData
  )

  return response.data
}
