import { useEffect, useState } from 'react'

const handleClickOutside = function (
  e: any,
  setShow: (show: boolean) => void,
  name?: string
) {
  const button = document.getElementById(`new-entry-button-${name}`)
  const menu = document.getElementById(`new-entry-menu-${name}`)
  const svg = document.getElementById(`new-entry-svg-${name}`)
  const path = button?.getElementsByTagName('path')[0]

  if (
    !e.target.isEqualNode(menu) &&
    !e.target.isEqualNode(svg) &&
    !e.target.isEqualNode(path) &&
    !e.target.isEqualNode(button) &&
    !e.target.isEqualNode(menu?.firstChild)
  ) {
    setShow(false)
  }
}

export const useCloseOnClickOutside = (name: string) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      window.addEventListener('click', (e) =>
        handleClickOutside(e, setShow, name)
      )
    } else {
      window.removeEventListener('click', (e) =>
        handleClickOutside(e, setShow, name)
      )
    }
    return () =>
      window.removeEventListener('click', (e) =>
        handleClickOutside(e, setShow, name)
      )
  }, [name, show])

  return { show, setShow }
}
