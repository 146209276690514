import { FeedType, StatusObject, StatusType } from '@/types'

export const tagsToArray = (tags: string) =>
  tags
    ?.replace(/ /g, '')
    .slice(tags.startsWith('#') ? 1 : 0)
    .split('#')
    .map((el) => ({ Tag: el }))

export const getTags = (
  type: FeedType,
  subtype: string
): { tag: string | null }[] => {
  const result: [{ tag: string | null }] = [{ tag: null }]

  if (type === 'announcement') {
    return [{ tag: 'Announcements' }]
  }

  if (type === 'job') {
    return [{ tag: 'Rewards' }, { tag: 'Rewards Jobs' }]
  }

  if (type === 'content') {
    return [{ tag: 'Content' }, { tag: 'Content Reviews' }]
  }

  if (type === 'quest') {
    result.push({ tag: 'Earn' })
    switch (subtype) {
      case 'learn2earn':
        result.push({ tag: 'Earn Learn2Earn' })
        break
      case 'quest':
        result.push({ tag: 'Earn Quests' })
        break
      case 'alpha testing':
        result.push({ tag: 'Earn AlphaTesting' })
        break
    }
  }

  return result
}

export const firstCapital = (string: string | null): string | null => {
  if (typeof string === 'string') {
    return (string?.charAt(0).toUpperCase() || '') + (string?.slice(1) || '')
  }
  return null
}

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const formatDate = (date: string): string => {
  return new Date(Date.parse(date)).toLocaleDateString('en', options)
}

export const determineFilter = () => {
  switch (window.location.pathname) {
    case '/profile':
      return 'profile'
    case '/feed':
      return 'feed'
    case '/':
      return 'dashboard'
    default:
      return null
  }
}

export const addPTags = (input: string) => {
  if (input && !input.startsWith('<p>') && !input.endsWith('</p>')) {
    return '<p>' + input + '</p>'
  }
  return input
}

export const getStatus = (status: StatusType): StatusObject => {
  switch (status) {
    case 'published':
      return {
        color: 'green',
        title: 'Published',
      }
    case 'in_review':
      return {
        color: 'orange',
        title: 'In review',
      }
    case 'reviewed':
      return {
        color: 'blue',
        title: 'Reviewed',
      }
    case 'rejected':
      return {
        color: 'red',
        title: 'Rejected',
      }
    default:
      return {
        color: 'grey',
        title: 'Draft',
      }
  }
}

export const formatFileSize = (size: number) => {
  if (size.toString().length <= 6 && size.toString().length > 3) {
    return (size / 1024).toFixed(0) + ' KB'
  }

  if (size.toString().length <= 9 && size.toString().length > 6) {
    return (size / 1024 / 1024).toFixed(0) + ' MB'
  }

  if (size.toString().length > 9) {
    return (size / 1024 / 1024 / 1024).toFixed(0) + ' GB'
  }

  return size + ' B'
}

export const checkIfFilled = (
  images: { id: number; url: string }[] | File[],
  imagesCount: number
) => {
  return images
    .slice(0, imagesCount + 1)
    .every((image) => image && (image instanceof File || image.id !== 0))
}

export const getDefaultImagesCount = (imagesCount: number) => {
  let result = []
  const count = imagesCount < 5 ? 4 : imagesCount - 1
  for (let i = 0; i < count; i++) {
    result.push(i + 1)
  }
  return result
}

export const truncateString = (str: string) => {
  if (!str) return ''
  const start = str.substring(0, 5)
  const end = str.substring(str.length - 5)
  const middle = '...'

  return start + middle + end
}

export const copyLink = (toast: any, text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    toast('Link copied to clipboard')
  })
}

export const cutString = (str: string, length: number) => {
  if (str.length > length) {
    return str.slice(0, length) + '...'
  }
  return str
}
