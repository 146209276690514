import { useContext } from 'react'
import PrivatePages from './components/PrivatePages'
import PublicPages from './components/PublicPages'
import { AppContainer } from './components/styled'
import { AuthContext, DataContext } from './context'
import UserProvider from './UserProvider'

function PartnerPlatform() {
  const { darkTheme } = useContext(DataContext)
  const { isAuth } = useContext(AuthContext)

  return (
    <UserProvider>
      <AppContainer darkTheme={darkTheme}>
        {isAuth ? <PrivatePages /> : <PublicPages />}
      </AppContainer>
    </UserProvider>
  )
}

export default PartnerPlatform
