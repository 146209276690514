import * as Styled from './styled'

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <Styled.CloseButtonContainer onClick={onClick}>
      <Styled.Lines>
        <Styled.CloseButtonLine />
        <Styled.CloseButtonLine />
      </Styled.Lines>
    </Styled.CloseButtonContainer>
  )
}

export default CloseButton
