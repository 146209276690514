import { useNavigate, useParams } from 'react-router-dom'
import * as Styled from './styled'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import Button from '../components/buttons/Button'
import Loader from '../components/Loader'
import ContentBlockRow from '../components/ContentBlockRow'
import { job_content } from './content'
import { defaultJobData } from './defaultData'
import {
  FeedTableType,
  IJobData,
  ShortFeedTableType,
  StatusType,
} from '../types'
import { transformJobData } from '../utils/data'
import { getCurrentFeed, onReviewClick, onSaveClick } from '../utils/save'
import { DataContext, PartnerContext } from '../context'
import { useCookies } from 'react-cookie'
import { unpublish } from '../utils/api'
import { getOneFeedData } from '../utils/api/feed'
import { getJobData, updateJobData, postJobData } from '../utils/api/job'
import Switch from 'react-switch'

function JobPage() {
  const params = useParams()
  const navigate = useNavigate()
  const { partner_cms_id } = useContext(PartnerContext)
  const { feedData, setPage, setFilter, darkTheme } = useContext(DataContext)
  const [jobData, setJobData] = useState<IJobData>(defaultJobData)
  const [values, setValues] = useState<IJobData>(defaultJobData)
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingReview, setLoadingReview] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const [status, setStatus] = useState<StatusType>(null)
  const [isError, setIsError] = useState(false)
  const [currentFeed, setCurrentFeed] = useState<
    FeedTableType | ShortFeedTableType | null
  >(null)

  const [isScheduled, setIsScheduled] = useState(false)

  const getData = async (jobId: string) => {
    try {
      const jobData = await getJobData(jobId)
      const feed = await getCurrentFeed(jobId, 'job')
      const restructuredData = transformJobData(jobData?.attributes, feed)
      if (restructuredData.publish_date || restructuredData.expiry_date) {
        setIsScheduled(true)
      }
      setValues(restructuredData)
      setJobData(restructuredData)
      setCurrentFeed(feed || null)
      setStatus(feed?.status || null)
    } catch (error) {
      console.error(error)
      toast('Error occurred during job data fetching...')
    }
  }

  const handleSwitchChange = (checked: boolean) => {
    setIsScheduled(checked)
    if (!checked) {
      setValues({ ...values, publish_date: ' ', expiry_date: ' ' })
      setIsError(false)
    } else {
      if (!values.publish_date || !values.expiry_date) {
        setIsError(true)
      }
    }
  }

  const onSaveDraftClick = async () => {
    const response = await onSaveClick<IJobData>(
      'job',
      values,
      params.id as string,
      currentFeed?.id.toString() as string,
      partner_cms_id as string,
      setLoadingSave,
      setStatus,
      getData,
      updateJobData,
      postJobData
    )
    if (response?.status === 'ok' && !params.id) {
      setValues(defaultJobData)
      navigate('/feed')
      setPage(1)
      return
    }
  }

  const onUpublishClick = async () => {
    try {
      const response = await unpublish(currentFeed?.id.toString() as string)
      const update_feed_response = await getOneFeedData(
        currentFeed?.id.toString() as string
      )
      if (response === 200) {
        toast('Successfully unpublished')
        setStatus(update_feed_response?.data?.attributes?.review_status || null)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onRequestReviewClick = async () => {
    if (JSON.stringify(jobData) !== JSON.stringify(values)) {
      toast('Save updates first')
      return
    }
    const feedId = currentFeed?.id.toString()
    const response = await onReviewClick(feedId as string, setLoadingReview)
    if (response.status) {
      setStatus(response.status)
    }
  }

  useEffect(() => {
    getData(params.id as string)
    setFilter(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedData, params.id])

  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>Job</Styled.Title>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            onClick={onSaveDraftClick}
            color="black"
            disabled={
              JSON.stringify(jobData) === JSON.stringify(values) ||
              status === 'published' ||
              isError
            }
            width="10rem"
            onMouseEnter={() => setLoaderColor('black')}
            onMouseLeave={() => setLoaderColor('white')}
          >
            {loadingSave ? (
              <Loader color={loaderColor} width="2.5rem" />
            ) : (
              'Save as Draft'
            )}
          </Button>
          {status === 'published' ? (
            <Button onClick={onUpublishClick}>Unpublish</Button>
          ) : (
            <Button
              onClick={onRequestReviewClick}
              disabled={
                status === 'in_review' || status === 'reviewed' || !params.id
              }
              onMouseEnter={() => setLoaderColor('white')}
              onMouseLeave={() => setLoaderColor('black')}
            >
              {loadingReview ? (
                <Loader color={loaderColor} width="8rem" />
              ) : status === 'in_review' ? (
                'In Review'
              ) : status === 'reviewed' ? (
                'Reviewed'
              ) : (
                'Request Review'
              )}
            </Button>
          )}
        </div>
      </Styled.TitleBlock>
      {job_content.map((block) => (
        <Styled.ContentBlock key={block.name}>
          <Styled.ContentBlockTitle $darktheme={darkTheme}>
            {block.name}{' '}
            {block.switch && (
              <Styled.SwitchContainer $darktheme={darkTheme}>
                <Switch
                  checked={isScheduled}
                  onChange={handleSwitchChange}
                  className="dark-mode-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={darkTheme ? '#000000' : '#ffffff'}
                  offColor={darkTheme ? '#000000' : '#ffffff'}
                  onHandleColor={darkTheme ? '#ffffff' : '#000000'}
                  offHandleColor="#656565"
                  handleDiameter={23}
                  width={56}
                  height={28}
                  activeBoxShadow="0"
                />
              </Styled.SwitchContainer>
            )}
          </Styled.ContentBlockTitle>
          {block.rows.map((item) => {
            if (!block.switch || isScheduled)
              return (
                <ContentBlockRow
                  key={item.tag}
                  tag={item.tag}
                  options={item.options}
                  title={item.title}
                  value={values}
                  setValue={setValues}
                  content_type={item.content_type}
                  placeholder={item.placeholder}
                  description={item.description}
                  required={item.required}
                  expandable={item.expandable}
                  setIsError={setIsError}
                />
              )
            return null
          })}
        </Styled.ContentBlock>
      ))}
    </Styled.PageContainer>
  )
}

export default JobPage
