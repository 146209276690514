import { SubscriptionColor } from '../types'
import { DEVICES } from '../responsive'
import styled from 'styled-components'

// export const Page = styled.div`
// height: 88vh;
//   overflow: hidden;
// `

export const PageContainer = styled.div<{ height?: string }>`
  padding: 24px 40px 0 40px;
  height: ${(props) => (props.height ? props.height : 'auto')};

  & .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
    cursor: pointer;
    font-family: gilroy-bold;
  }

  & .page {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .active-page {
    border-radius: 50%;
    background: rgba(220, 220, 220, 0.3);
  }
`

export const TitleBlock = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 32px;
`

export const Title = styled.h1`
  font-family: gilroy-black;
  font-size: 32px;
  text-align: start;
`

export const ContentBlock = styled.div`
  text-align: start;
  margin-bottom: 20px;
`

export const ContentBlockTitle = styled.div<{ $darktheme?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: gilroy-black;
  font-size: 20px;
  text-align: start;
  margin-bottom: 24px;

  & .dark-mode-switch {
    border: 1px solid ${(props) => (props.$darktheme ? 'white' : 'black')};
  }

  & .optional {
    margin-left: 0.5rem;
    color: #8d8d8d;
    font-size: 0.875rem;
    font-family: gilroy;
  }
`

export const FeedTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LogoContainer = styled.div`
  position: relative;
  text-align: start;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
`

export const SubscriptionBackground = styled.div<{ $darktheme?: boolean }>`
  background: ${(props) => (props.$darktheme ? 'black' : 'white')}
    url('bg-subscription.png') repeat;
  background-size: cover;
  height: 100vh;
  overflow: auto;

  @media ${DEVICES.lg} {
    overflow: hidden;
  }
`

export const LoginBackground = styled.div<{ $darktheme?: boolean }>`
  background: url('bg-login.png') repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;

  & .dark-mode-switch {
    border: 1px solid ${(props) => (props.$darktheme ? 'white' : 'black')};
  }
`

export const LoginContent = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '1rem')};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  height: 100%;
`

export const LoginTitle = styled.h1<{ $darktheme?: boolean }>`
  font-family: gilroy-bold;
  font-size: 40px;
  color: ${(props) => (props.$darktheme ? 'white' : 'black')};
`

export const LoginSubTitle = styled.div`
  font-family: gilroy;
  font-size: 20px;
  max-width: 504px;
  color: #656565;
`

export const ImgContainer = styled.div`
  width: 80%;
  margin-top: auto;
`

export const SubscriptionCards = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  @media ${DEVICES.lg} {
    width: 75%;
    margin: 0 auto;
    flex-direction: row;
    gap: 1rem;
  }
`

export const Card = styled.div<{ $active?: boolean; $darktheme?: boolean }>`
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-height: 50vh;
  font-family: gilroy;
  gap: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  border: ${(props) =>
    props.$active
      ? '2px solid #E844C9'
      : props.$darktheme
        ? '2px solid black'
        : '2px solid #E0E0E0'};
  background: ${(props) =>
    props.$darktheme
      ? 'linear-gradient(180deg, rgba(78, 78, 78, 0.64) 0%, rgba(17, 17, 17, 0.64) 100%), #000'
      : '#fff'};
  color: ${(props) => (props.$darktheme ? 'white' : 'black')};

  & ul {
    margin: 0;
    text-align: start;
  }

  & li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid
      ${(props) => (props.$darktheme ? '#4A4949' : '#e0e0e0')};

    & svg {
      flex: 0 0 10%;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`

export const SubscriptionType = styled.div<{
  color: SubscriptionColor
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${(props) =>
    props.color === 'pink'
      ? '#E639D5'
      : props.color === 'blue'
        ? '#00A5EB'
        : props.color === 'grey'
          ? '#8D8D8D'
          : '#7815E2'};
  font-family: gilroy-semibold;
  font-size: 1.5rem;
`

export const Price = styled.div<{ $darktheme?: boolean }>`
  display: flex;
  gap: 0.5rem;
  font-family: ${(props) =>
    props.$darktheme ? 'gilroy-semibold' : 'gilroy-bold'};
  font-size: 50px;

  & span {
    margin-top: 0.5rem;
    font-family: gilroy-bold;
    font-size: 1rem;
    color: #b0aeb4;
  }
`

export const Previous = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: gilroy-bold;
`

export const Features = styled.div`
  width: 100%;
`

export const Description = styled.div`
  text-align: start;
  color: #656565;
  font-size: 1rem;
`

export const HomeTitle = styled.h1`
  text-align: start;
  font-family: gilroy-bold;
  font-size: 2rem;
  margin-bottom: 2rem;
`

export const HomeSubtitle = styled.div`
  text-align: start;
  font-family: gilroy-semibold;
  font-size: 1.25rem;
  margin: 1.25rem 0;
`

export const HomeColoredBlocks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  @media ${DEVICES.lg} {
    flex-direction: row;
  }
`

export const ColoredBlock = styled.div<{
  type: 'white' | 'colored'
  $darktheme?: boolean
}>`
  position: relative;
  flex: 0 1 25%;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.type === 'white' ? 'space-between' : 'start'};
  align-items: start;
  gap: 1rem;
  height: ${(props) => (props.type === 'white' ? '8.3rem' : '22rem')};
  background: ${(props) =>
    props.type === 'white'
      ? props.$darktheme
        ? '#222'
        : 'white'
      : 'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%), #7815E2'};
  color: ${(props) =>
    props.type === 'white' ? (props.$darktheme ? 'white' : 'black') : 'white'};
  border-radius: 1rem;
  box-shadow: ${(props) =>
    props.type === 'white' ? '0px 8px 28px 0px rgba(0, 0, 0, 0.08)' : 'none'};
  // padding: 1rem 1.25rem;
  cursor: ${(props) => (props.type === 'white' ? 'unset' : 'pointer')};

  @media ${DEVICES._2xl} {
    height: ${(props) => (props.type === 'white' ? '8.3rem' : '19.75rem')};
  }
`

export const ColoredBlockIcon = styled.div<{ $darktheme?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: ${(props) => (props.$darktheme ? 'black' : '#f5f5f5')};
  border-radius: 0.5rem;
  margin: 1rem 1.25rem;

  & svg {
    & path {
      fill: ${(props) => (props.$darktheme ? 'white' : 'black')};
    }
  }
`

export const ColoredBlockIconAbsolute = styled.div`
  position: absolute;
  top: 1.5rem;
`

export const ColoredBlockText = styled.div<{ type: 'white' | 'colored' }>`
  font-family: gilroy-semibold;
  font-size: 1.25rem;
  text-align: start;
  margin: ${(props) =>
    props.type === 'white' ? '1rem 1.25rem' : '10rem 1.25rem 1rem'};

  & .text {
    font-family: gilroy;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
  }
`

export const HomeEarn = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  color: #656565;
  text-align: start;
  border-radius: 1rem;
  background: url(earn-bg.png) right no-repeat;
  background-color: #f5f5f5;
  margin-bottom: 2rem;
  position: relative;
  height: 190px;

  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 200px;
    background: url(joystick.png) right no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

export const HomeEarnTitle = styled.div`
  width: fit-content;
  font-family: gilroy-bold;
  font-size: 2rem;
  background: linear-gradient(
    90deg,
    #4b0af4 0.03%,
    #c8369c 49.11%,
    #ea662c 99.97%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
`

export const HomeEarnContent = styled.div`
  width: 393px;
`

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const HomeImg = styled.div`
  margin-top: auto;
  display: flex;
  gap: 1rem;
  width: 75%;

  & img {
    margin-left: -20px;
  }
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: start;
  text-align: start;
  font-size: 1rem;

  & div:nth-child(2) {
    font-family: gilroy-bold;
  }

  & div:last-child {
    font-family: gilroy;
    color: #8d8d8d;
  }
`

export const ReactPaginateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
`

export const LoaderContainer = styled.div`
  margin-top: 2rem;
`

export const GamesContainer = styled.div``

export const GamesList = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`

export const GamesTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-family: gilroy-bold;
  font-size: 2rem;
`

export const SwitchContainer = styled.div<{
  $active?: boolean
  $darktheme?: boolean
  $content?: string
}>`
  position: relative;
  &:hover {
    &:before {
      display: ${(props) =>
        props.$active && props.$content ? 'flex' : 'none'};
      justify-content: center;
      align-items: center;
      content: '${(props) => props.$content || ''}';
      font-family: gilroy;
      font-size: 1rem;
      position: absolute;
      width: 10rem;
      padding: 1rem;
      border-radius: 1rem;
      left: -12vw;
      top: -18vh;
      z-index: 20;
      border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
      background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
      color: ${(props) => (props.$darktheme ? 'white ' : 'black')};
    }
  }
`

export const Warning = styled.div<{ $darktheme?: boolean }>`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: ${(props) => (props.$darktheme ? '#111' : '#F5F5F5')};
  border-radius: 1rem;
  padding: 1rem;
  color: #8620f3;
  text-align: start;
  font-size: 1rem;
  font-family: ${(props) => (props.$darktheme ? 'gilroy' : 'gilroy-medium')};
  margin-bottom: 1rem;
`
