import { PropsWithChildren, useContext, useEffect } from 'react'
import Api from './services/api'
import { Partner } from './types/partner'
import { AuthContext, PartnerContext } from './context'

export default function UserProvider(props: PropsWithChildren): JSX.Element {
  const { setAuth } = useContext(AuthContext)
  const {
    setPartnerCmsId,
    setPartnerEmail,
    setPassStatus,
    setPassExpire,
    setCanceled,
    setReferrerId,
    setReferralLink,
  } = useContext(PartnerContext)

  useEffect(() => {
    const validate = async () => {
      try {
        const partner = await Api.get<Partner>('partners')
        if (
          partner?.id &&
          partner.passStatus &&
          partner.passStatus !== 'pending'
        ) {
          setPartnerCmsId(partner?.strapiId || null)
          setPartnerEmail(partner?.email || null)
          setPassStatus(partner?.passStatus || null)
          setPassExpire(partner?.subscriptionEndsAt || null)
          setCanceled(partner?.isCanceled || false)
          setReferrerId(partner?.referrerId || null)
          setReferralLink(partner?.referralLink || null)
          setAuth(true)
        }
      } catch (e) {
        setAuth(false)
      }
    }
    validate()
  }, [])

  return props.children
}
