import { useEffect, useContext, useCallback, useState } from 'react'
import * as Styled from './styled'
import { transformFeedData } from '../utils/data'
import { toast } from 'react-toastify'
import FeedTableRow from '../components/FeedTableRow'
import { getFeedUrl } from '../utils/urls'
import { DataContext, PartnerContext } from '../context'
import Button from '../components/buttons/Button'
import NewEntryMenu from '../components/menu/NewEntryMenu'
import { useCookies } from 'react-cookie'
import { getFeedData } from '../utils/api/feed'
import { useCloseOnClickOutside } from '../hooks/useCloseOnClickOutside'
import ReactPaginate from 'react-paginate'
import Loader from '../components/Loader'
import { ITEMS_PER_PAGE } from '../constants'
import { useNavigate } from 'react-router-dom'

function FeedPage() {
  const { show, setShow } = useCloseOnClickOutside('feed')
  const { partner_cms_id } = useContext(PartnerContext)
  const {
    filter,
    feedData,
    setFeedData,
    page,
    setPage,
    pageCount,
    setPageCount,
    setTotalItems,
    totalItems,
    darkTheme,
  } = useContext(DataContext)

  const [firstItem, setFirstItem] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const title =
    (filter?.charAt(0).toUpperCase() || '') + (filter?.slice(1) || ' Feed')

  const url = getFeedUrl(partner_cms_id, page, filter)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getFeedData(url)
      const restructuredData = transformFeedData(response?.data)
      setFeedData(restructuredData)
      setPageCount(response?.meta?.pagination?.pageCount || 1)
      setTotalItems(response?.meta?.pagination?.total || 0)
      setFirstItem((page - 1) * ITEMS_PER_PAGE + 1)
    } catch (error) {
      console.error(error)
      toast('Error occured during feed data fetching...')
    } finally {
      setLoading(false)
    }
  }, [page, setFeedData, setPageCount, setTotalItems, url])

  useEffect(() => {
    getData()
  }, [getData])

  const handleNewEntryClick = () => {
    switch (filter) {
      case 'feed':
        setShow(!show)
        break
      case 'earn':
        setShow(!show)
        break
      case 'gaming':
        setShow(!show)
        break
      case 'rewards':
        setShow(!show)
        break
      case 'announcements':
        navigate('/announcement')
        break
      case 'quests':
        navigate('/quest')
        break
      case 'content':
        navigate('/content')
        break
      case 'jobs':
        navigate('/job')
        break
      case 'merch':
        navigate('/merch')
        break
      case 'tournaments':
        navigate('/tournament')
        break
      case 'digital assets':
        navigate('/digital_asset')
        break
      case 'offers':
        navigate('/offer')
        break
      case 'tokens':
        navigate('/token')
        break
      default:
        setShow(!show)
    }
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>{title}</Styled.Title>
        <Button
          color={darkTheme ? 'white' : 'black'}
          onClick={handleNewEntryClick}
          id="new-entry-button-feed"
        >
          New Entry +
        </Button>
        <NewEntryMenu open={show} />
      </Styled.TitleBlock>
      <Styled.FeedTable>
        <FeedTableRow />
        {loading ? (
          <Styled.LoaderContainer>
            <Loader type="grid" color="grey" />
          </Styled.LoaderContainer>
        ) : (
          feedData?.map((row) => (
            <FeedTableRow page={page} key={row.id} row={row} />
          ))
        )}
      </Styled.FeedTable>
      <Styled.ReactPaginateContainer>
        {loading ? (
          <div />
        ) : (
          totalItems > 0 && (
            <div>{`Showing ${firstItem} to ${
              firstItem + feedData.length - 1
            } of ${totalItems} results`}</div>
          )
        )}
        {pageCount !== 1 && !loading && (
          <ReactPaginate
            className="pagination"
            activeClassName="active-page"
            pageClassName="page"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={page - 1}
          />
        )}
      </Styled.ReactPaginateContainer>
    </Styled.PageContainer>
  )
}

export default FeedPage
