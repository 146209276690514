import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { firstCapital } from '../utils/utils'
import { getDatePickerStyles, getDatePickerInputStyles } from './styles'
import {
  ContentType,
  DropzoneNamesType,
  IGame,
  MultiselectName,
} from '../types'
import Dropzone from './dropzone/Dropzone'
import * as Styled from './styled'
import SubscriptionBlock from './SubscriptionBlock'
import { DataContext } from '../context'
import { useCallback, useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ChevronDownIcon, WarningIcon } from '../icons'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'
import Multiselect from './Multiselect'
import GameFileUpload from './dropzone/GameFileUpload'

const modules = {
  toolbar: [
    // [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    ['clean'],
  ],
}

function ContentBlockRow({
  tag,
  title,
  content_type,
  description,
  value,
  setValue,
  placeholder,
  options,
  disabled,
  required,
  expandable,
  content,
  loading,
  percentLoaded,
  onUploadClick,
  onCancelUploadClick,
  setAllFilesSize,
  allFilesSize,
  setIsError,
}: ContentType) {
  const { darkTheme } = useContext(DataContext)
  const [expanded, setExpanded] = useState(false)

  const debouncedToast = useDebouncedCallback(
    () => toast('This field must be no more than 1000 characters long.'),
    1000
  )

  const handleDateChange = (date: any) => {
    setIsError(false)
    setValue((prevValue: any) => ({
      ...prevValue,
      [tag]: date ? new Date(date) : null,
    }))
  }

  const onError = () => {
    setIsError(true)
  }

  const renderDatePicker = useCallback(() => {
    const isPublishDate = tag === 'publish_date'
    const maxDateToPublish = value.expiry_date ? dayjs(value.expiry_date) : null
    const minDateToExpiry = value.publish_date
      ? dayjs(value.publish_date)
      : null

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DateTimePicker
          disablePast
          maxDate={isPublishDate ? maxDateToPublish : null}
          minDate={!isPublishDate ? minDateToExpiry : null}
          maxTime={isPublishDate ? maxDateToPublish : null}
          minTime={!isPublishDate ? minDateToExpiry : null}
          onChange={handleDateChange}
          value={dayjs(value[tag]) || null}
          disableMaskedInput={true}
          InputProps={{
            disableUnderline: true,
          }}
          onError={onError}
          slotProps={{
            field: { clearable: true },
            layout: {
              sx: {
                ...getDatePickerStyles(darkTheme),
              },
            },
          }}
          sx={{ ...getDatePickerInputStyles(darkTheme) }}
          disabled={disabled}
        />
      </LocalizationProvider>
    )
  }, [darkTheme, disabled, handleDateChange, onError, tag, value])

  const onEditorChange = useCallback(
    (editorValue: string, delta: any, source: any, editor: any) => {
      if (editor.getLength() > 290) {
        setExpanded(true)
      } else {
        setExpanded(false)
      }
      if (editor.getLength() > 1000) {
        debouncedToast()
        setValue((prevValue: any) => ({
          ...prevValue,
          [tag]: prevValue[tag],
        }))
        return
      }

      setValue((prevValue: any) => ({
        ...prevValue,
        [tag]: editorValue.replace(/<span[^>]*>|<\/span>/g, ''),
      }))
    },
    [setValue, setExpanded, debouncedToast, tag]
  )

  useEffect(() => {
    if (content_type === 'markdown' && value[tag]) {
      setExpanded(value[tag].replace(/(<([^>]+)>)/gi, '').length > 290)
    }
  }, [content_type, tag, value])

  if (content_type === 'input') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <input
            className="input-element"
            disabled={disabled}
            placeholder={placeholder ? placeholder : ''}
            value={value[tag]}
            onChange={(e) =>
              setValue({
                ...value,
                [tag]: !!e.target.value ? e.target.value : null,
              })
            }
          />
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'textarea') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {(description || expandable) && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <textarea
            className="input-element"
            maxLength={tag === 'description' ? 500 : 290}
            placeholder={placeholder ? placeholder : ''}
            value={value[tag]}
            onChange={(e) =>
              setValue({
                ...value,
                [tag]: !!e.target.value ? e.target.value : null,
              })
            }
          />
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'markdown') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {(description || expandable) && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
              {expanded && (
                <div className="long-form-warning">
                  <Styled.WarningWrapper $darktheme={darkTheme}>
                    <WarningIcon />
                  </Styled.WarningWrapper>
                  Hey, we just noticed your post is larger than 290 characters,
                  it will be shown as collapsed in the users Feed
                </div>
              )}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent
          $darktheme={darkTheme}
          $expanded={expandable ? expanded : false}
        >
          <ReactQuill
            className="editor"
            modules={modules}
            value={value[tag]}
            onChange={onEditorChange}
            placeholder={placeholder ? placeholder : ''}
          />
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'dropzone_small' || content_type === 'dropzone_big') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <Dropzone
            width={content_type === 'dropzone_big' ? '100%' : '106px'}
            height={content_type === 'dropzone_big' ? '274px' : '106px'}
            resolution={
              content_type === 'dropzone_big'
                ? '1920x1080 / 1080х1080'
                : '1080х1080'
            }
            values={value}
            setValues={setValue}
            allFilesSize={allFilesSize}
            setAllFilesSize={setAllFilesSize}
            name={tag as DropzoneNamesType}
          />
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'select') {
    const optionsArray = Array.isArray(options)
      ? options
      : value[options as string]
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <div className="multiselect-icon">
            <ChevronDownIcon />
          </div>
          <select
            className="input-element"
            // value={
            //   value[tag]?.id ? value[tag].id : value[tag]
            // }
            defaultValue={0}
            onChange={(e) =>
              setValue({
                ...value,
                [tag]: value[tag]?.title
                  ? {
                      id: e.target.value,
                      title:
                        e.target.options[
                          e.target.selectedIndex
                        ].text.toLocaleLowerCase(),
                    }
                  : e.target.options[
                      e.target.selectedIndex
                    ].text.toLocaleLowerCase(),
              })
            }
          >
            <option value={0} style={{ color: 'grey' }} disabled>
              Select...
            </option>
            {optionsArray?.map((item: IGame) => (
              <option
                key={item.id}
                value={item.id}
                selected={
                  item.title ===
                  (value[tag]?.title ? value[tag].title : value[tag])
                }
              >
                {firstCapital(item.title)}
              </option>
            ))}
          </select>
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'multiselect') {
    const optionsArray = Array.isArray(options)
      ? options
      : value[options as string]
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Multiselect
          name="Category"
          options={optionsArray}
          value={value}
          setValue={setValue}
          type="category"
        />
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'radio') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <Styled.RadioInputContainer>
            <Styled.RadioInput
              type="radio"
              id="text"
              name="review"
              checked={value[tag] === 'review' || !value[tag]}
              value="review"
              onChange={() => setValue({ ...value, [tag]: 'review' })}
            />
            Text Review
          </Styled.RadioInputContainer>
          <Styled.RadioInputContainer>
            <Styled.RadioInput
              type="radio"
              id="video"
              checked={value[tag] === 'video_live'}
              name="review"
              value="video_live"
              onChange={() => setValue({ ...value, [tag]: 'video_live' })}
              disabled
            />
            Video Review
          </Styled.RadioInputContainer>
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'radio_type') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <Styled.RadioInputContainer>
            <Styled.RadioInput
              type="radio"
              id="quest"
              name="learn2earn"
              checked={value[tag] === 'learn2earn'}
              value="learn2earn"
              onChange={() => setValue({ ...value, [tag]: 'learn2earn' })}
            />
            Learn to Earn
          </Styled.RadioInputContainer>
          <Styled.RadioInputContainer>
            <Styled.RadioInput
              type="radio"
              id="quest"
              checked={value[tag] === 'quest'}
              name="quest"
              value="quest"
              onChange={() => setValue({ ...value, [tag]: 'quest' })}
            />
            Quest
          </Styled.RadioInputContainer>
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'subscription') {
    return <SubscriptionBlock />
  }

  if (content_type === 'game-filters') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme} className="game-filters">
          {content?.map((el: any) => {
            if (el.content_type === 'multiselect') {
              return (
                <Multiselect
                  key={el.cms_field}
                  name={el.name as string}
                  options={el.options || []}
                  value={value}
                  setValue={setValue}
                  type={el.cms_field as MultiselectName}
                />
              )
            }

            return (
              <Styled.SelectContainer $darktheme={darkTheme}>
                <div className="multiselect-icon">
                  <ChevronDownIcon />
                </div>
                <select
                  className="input-element"
                  // value={'name'}
                  defaultValue={'name'}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      filters: {
                        ...value.filters,
                        [el.cms_field]:
                          e.target.options[e.target.selectedIndex].text,
                      },
                    })
                  }
                >
                  <option value={'name'} style={{ color: 'grey' }} disabled>
                    {el.name || '...Select'}
                  </option>
                  {(el.options || []).map((item: any) => (
                    <option
                      key={item}
                      value={item}
                      selected={item === value.filters[el.cms_field]}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </Styled.SelectContainer>
            )
          })}
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'date') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <input
            type="date"
            className="input-element"
            disabled={disabled}
            placeholder={placeholder ? placeholder : ''}
            value={value[tag]}
            onChange={(e) =>
              setValue({
                ...value,
                [tag]: !!e.target.value ? e.target.value : null,
              })
            }
          />
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'date_time') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          {renderDatePicker()}
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  if (content_type === 'file_upload') {
    return (
      <Styled.ContentBlockRow>
        <Styled.RowName>
          <div>
            {title}
            {required && <span>{' *'}</span>}
          </div>
          {description && (
            <Styled.RowDescription darkTheme={darkTheme}>
              {description}
            </Styled.RowDescription>
          )}
        </Styled.RowName>
        <Styled.RowContent $darktheme={darkTheme}>
          <GameFileUpload
            value={value}
            setValue={setValue}
            loading={loading}
            percentLoaded={percentLoaded}
            onUploadClick={onUploadClick}
            onCancelUploadClick={onCancelUploadClick}
          />
        </Styled.RowContent>
      </Styled.ContentBlockRow>
    )
  }

  return <div />
}

export default ContentBlockRow
