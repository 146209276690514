import { IProfileData } from '../../types'
import { appendImage } from './heplers'
import Api from '../../services/api'

export const getProfileData = async () => {
  return await Api.get<IProfileData>(`partners/partner`)
}

export const updateProfileData = async (values: IProfileData) => {
  const dataToSend: any = {
    name: values.name,
    email: values.email,
    description: values.description,
    short_description: values.short_description,
    website_link: values.website_link,
    about_text: values.about_text,
    socials: {
      x: values.x || '',
      instagram: values.instagram || '',
      youtube: values.youtube || '',
      twitch: values.twitch || '',
      telegram: values.telegram || '',
      linkedin: values.linkedin || '',
      linktree: values.linktree || '',
      tiktok: values.tiktok || '',
    },
    featured_game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
  }
  let formData = new FormData()
  values.logo
    ? appendImage('logo', values.logo, formData)
    : (dataToSend.logo = null)
  values.preview_image
    ? appendImage('preview_image', values.preview_image, formData)
    : (dataToSend.preview_image = null)
  values.video_thumbnail
    ? appendImage('video_thumbnail', values.video_thumbnail, formData)
    : (dataToSend.video_thumbnail = null)
  values.about_preview
    ? appendImage('about_preview', values.about_preview, formData)
    : (dataToSend.about_preview = null)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IProfileData>(
    `partners/partner`,
    formData,
    {}
  )

  return response.status
}
