import { FeedType } from '../../types'
import Api from '../../services/api'
import axios from 'axios'

const formatItemType = (item: string) => {
  if (item === 'Announcement') {
    return 'feed-announcement'
  }
  if (item === 'Merch') {
    return 'merche'
  }

  if (item === 'Digital_asset') {
    return 'asset'
  }

  return item.toLocaleLowerCase()
}

export const deleteItem = async (
  feedId: number,
  itemId: number,
  type: FeedType
) => {
  const feedResponse = await Api.delete(`partners/feeds/${feedId}`)

  const typeResponse = await Api.delete(
    `partners/${formatItemType(type)}s/${itemId}`
  )

  return {
    deleted: typeResponse === 200 && feedResponse === 200,
  }
}

export const updateReviewStatus = async (feedId: string) => {
  const dataToSend: any = {
    review_status: 'in_review',
  }
  let formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<FeedType>(
    `partners/feed/${feedId}`,
    formData,
    {}
  )
  return response.status
}

export const unpublish = async (feedId: string) => {
  const dataToSend: any = {
    publishedAt: null,
    review_status: null,
  }
  let formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<FeedType>(
    `partners/feed/${feedId}`,
    formData,
    {}
  )
  return response.status
}

export const deleteReplacedImages = async (
  old_images: any[],
  new_images: any[]
) => {
  const imagesToDelete = old_images.filter(
    (image, index) => image.url !== new_images[index].url
  )
  axios
    .all(
      imagesToDelete.map((image) =>
        Api.delete(`partners/upload/files/${image.id}`)
      )
    )
    .then((res) => {
      return res
    })
    .catch((error) => console.error(error))
  return []
}

export const deleteGame = async (gameId: string) => {
  const response = await Api.delete(`partners/game/${gameId}`)

  return {
    deleted: response === 200,
  }
}
