import useAuthContext from '../hooks/useAuthContext'
import useDataContext from '../hooks/useDataContext'
import usePartnerContext from '../hooks/usePartnerContext'
import { PropsWithChildren } from 'react'
import { AuthContext, PartnerContext, DataContext } from '.'

function ContextProvider(props: PropsWithChildren) {
  const partnerContext = usePartnerContext()
  const authContext = useAuthContext()
  const dataContext = useDataContext()

  return (
    <AuthContext.Provider value={authContext}>
      <PartnerContext.Provider value={partnerContext}>
        <DataContext.Provider value={dataContext}>
          {props.children}
        </DataContext.Provider>
      </PartnerContext.Provider>
    </AuthContext.Provider>
  )
}

export default ContextProvider
