import { IAnnouncementData } from '../../types'
import { tagsToArray } from '../utils'
import Api from '../../services/api'
import { appendImage } from './heplers'

export const getAnnouncementData = async (postId: string) => {
  if (postId) {
    const response = await Api.get<IAnnouncementData>(
      `partners/announcement/${postId}`
    )

    return await response.data
  }
  return {}
}

export const postAnnouncementData = async (values: IAnnouncementData) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    publisher: { connect: ['partner_cms_id'] },
    trailer_video: values.trailer_video,
  }

  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<IAnnouncementData>(
    `partners/announcement`,
    formData,
    {}
  )
  return response.data?.data?.id
}

export const updateAnnouncementData = async (
  values: IAnnouncementData,
  postId: string
) => {
  const dataToSend: any = {
    title: values.title,
    description:
      values.description !== '<p><br></p>' ? values.description : null,
    external_url: values.url,
    tags: !values.tags ? [] : tagsToArray(values.tags),
    game: values.currentGame?.id
      ? { connect: [values.currentGame.id] }
      : { connect: [] },
    trailer_video: values.trailer_video,
  }
  let formData = new FormData()
  appendImage('preview_image', values.preview_image, formData)
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IAnnouncementData>(
    `partners/announcement/${postId}`,
    formData,
    {}
  )
  return response.data
}
