import { useState } from 'react'

const useAuthContext = () => {
  const [isAuth, setAuth] = useState(false)

  return {
    isAuth,
    setAuth,
  }
}

export default useAuthContext
