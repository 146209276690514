import { Routes, Route } from 'react-router-dom'
import LoginPage from '../pages/LoginPage'
import SubscriptionPage from '../pages/SubscriptionPage'

export const publicRoutes = [
  {
    id: 1,
    path: '/',
    page: <LoginPage />,
  },
  {
    id: 2,
    path: '/subscription',
    page: <SubscriptionPage />,
  },
  {
    id: 3,
    path: '*',
    page: <LoginPage />,
  },
]

function PublicContent() {
  return (
    <div className="App">
      <Routes>
        {publicRoutes.map((el) => (
          <Route key={el.id} path={el.path} element={el.page} />
        ))}
      </Routes>
    </div>
  )
}

export default PublicContent
