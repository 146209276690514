import { DataContext } from '../../context'
import { useState, useContext, useEffect } from 'react'
import * as Styled from './styled'
import Button from '../buttons/Button'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import { useCookies } from 'react-cookie'
import { CopyIcon, MultiplyIcon } from '../../icons'
import Api from '../../services/api'
import { Partner } from '@/types/partner'
import { copyLink, cutString } from '../../utils/utils'

interface IModal {
  open: boolean
  close: Function
  getModerators: Function
  setExistingLink: Function
  existingLink?: string
}

function InviteModal({
  open,
  close,
  getModerators,
  setExistingLink,
  existingLink,
}: IModal) {
  const [loading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const { darkTheme } = useContext(DataContext)
  const [inviteLink, setInviteLink] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    setInviteLink('')
    setEmail('')
    setExistingLink('')
  }, [open])

  useEffect(() => {
    if (existingLink) {
      setInviteLink(existingLink)
    }
  }, [existingLink])

  const onGenerateCode = async () => {
    setLoading(true)
    try {
      const response = await Api.post<Partner>('codes/generate-invite', {
        email,
      })

      setInviteLink(response || '')
      getModerators()
    } catch (error: any) {
      setInviteLink('')
      setEmail('')
      toast(error.response?.data?.error || 'Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled.ModalContainer onClick={() => close()} open={open}>
      <Styled.Modal
        $darktheme={darkTheme}
        onClick={(e: any) => e.stopPropagation()}
      >
        <MultiplyIcon onClick={() => close()} className="close-icon" />
        <div style={{ fontSize: '32px', fontFamily: 'gilroy-bold' }}>
          Invite moderator
        </div>
        {inviteLink ? (
          <>
            <div
              style={{ fontSize: '16px', fontFamily: 'gilroy', width: '100%' }}
            >
              Share this link with your team member, so he’d be able to sign up
              for free
            </div>
            <Styled.Input
              $darktheme={darkTheme}
              onClick={() => copyLink(toast, inviteLink)}
            >
              <input disabled value={cutString(inviteLink, 38)} />
              <CopyIcon className="copy-icon" />
            </Styled.Input>
          </>
        ) : (
          <>
            <Styled.Input $darktheme={darkTheme}>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter email..."
              />
            </Styled.Input>
            <Styled.ButtonsGroup>
              <Button
                color={darkTheme ? 'white-transparent' : 'white'}
                onClick={onGenerateCode}
                onMouseEnter={() =>
                  setLoaderColor(darkTheme ? 'black' : 'white')
                }
                onMouseLeave={() =>
                  setLoaderColor(darkTheme ? 'white' : 'black')
                }
              >
                {loading ? <Loader color={loaderColor} /> : 'Get invite link'}
              </Button>
            </Styled.ButtonsGroup>
          </>
        )}
      </Styled.Modal>
    </Styled.ModalContainer>
  )
}

export default InviteModal
