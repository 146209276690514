import { DataContext, PartnerContext } from '../../context'
import { FeedType } from '../../types'
import { transformFeedData } from '../../utils/data'
import { getFeedUrl } from '../../utils/urls'
import { useState, useContext } from 'react'
import * as Styled from './styled'
import Button from '../buttons/Button'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import CloseButton from '../buttons/CloseButton'
import { getFeedData } from '../../utils/api/feed'
import { deleteItem } from '../../utils/api'
import { useCookies } from 'react-cookie'

interface IModal {
  open: boolean
  setOpen: (open: boolean) => void
  itemData: {
    feed_id: number
    type_id: number
    type: FeedType
  }
}

function DeleteModal({ open, setOpen, itemData }: IModal) {
  const { feed_id, type_id, type } = itemData
  const [loading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const { partner_cms_id } = useContext(PartnerContext)
  const { darkTheme } = useContext(DataContext)
  const { filter, setFeedData, page, setPage, setPageCount, setTotalItems } =
    useContext(DataContext)

  const url = getFeedUrl(partner_cms_id, page, filter)

  const onDelClick = async () => {
    setLoading(true)
    try {
      const response = await deleteItem(feed_id, type_id, type)

      if (response.deleted) {
        const response = await getFeedData(url)
        setFeedData(transformFeedData(response?.data))
        const totalPages = response?.meta?.pagination?.pageCount || 1
        setPageCount(totalPages)
        setTotalItems(response?.meta?.pagination?.total || 0)
        if (totalPages <= page) {
          setPage(totalPages)
        }
        toast(`${type.replace('_', ' ')} was successfully deleted`)
        setOpen(false)
      } else {
        toast('Something went wrong...')
      }
    } catch (error) {
      console.error(error)
      toast('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled.ModalContainer onClick={() => setOpen(false)} open={open}>
      <Styled.Modal $darktheme={darkTheme} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => setOpen(false)} />
        <div style={{ marginBottom: '2rem' }}>
          Are you sure you want to delete this item?
        </div>
        <Styled.ButtonsGroup>
          <Button
            color={darkTheme ? 'white-transparent' : 'white'}
            onClick={onDelClick}
            onMouseEnter={() => setLoaderColor(darkTheme ? 'black' : 'white')}
            onMouseLeave={() => setLoaderColor(darkTheme ? 'white' : 'black')}
          >
            {loading ? <Loader color={loaderColor} /> : 'Delete'}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color={darkTheme ? 'white-transparent' : 'white'}
          >
            Cancel
          </Button>
        </Styled.ButtonsGroup>
      </Styled.Modal>
    </Styled.ModalContainer>
  )
}

export default DeleteModal
