import { FilterType } from '../types'
import { useState } from 'react'

const useDataContext = () => {
  const [filter, setFilter] = useState<FilterType>(null)
  const [feedData, setFeedData] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [darkTheme, setDarkTheme] = useState(false)

  return {
    filter,
    setFilter,
    feedData,
    setFeedData,
    page,
    setPage,
    pageCount,
    setPageCount,
    totalItems,
    setTotalItems,
    darkTheme,
    setDarkTheme,
  }
}

export default useDataContext
