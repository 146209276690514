import { DataContext, PartnerContext } from '../context'
import { useContext } from 'react'
import * as Styled from './styled'

function PartnerInfo() {
  const { partner_email, partner_name, logo } = useContext(PartnerContext)
  const { darkTheme } = useContext(DataContext)

  return (
    <Styled.PartnerInfo>
      <Styled.PartnerLogo logo={logo}>
        {!logo ? (
          partner_name?.slice(0, 1)
        ) : (
          <img src={logo} width="75%" alt="" />
        )}
      </Styled.PartnerLogo>
      <Styled.PartnerNameEmail darkTheme={darkTheme}>
        <Styled.PartnerName>{partner_name}</Styled.PartnerName>
        <div>{partner_email}</div>
      </Styled.PartnerNameEmail>
    </Styled.PartnerInfo>
  )
}

export default PartnerInfo
