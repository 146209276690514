import { ICodesData, IGenerateCodeData, IGetCodeData } from '../../types'
import Api from '../../services/api'

export const generateInviteCode = async (values: ICodesData) => {
  return await Api.post<IGenerateCodeData>(`codes/generate-partners`, {
    email: values.email_generate,
    key: values.key_generate,
  })
}

export const getInviteCode = async (values: ICodesData) => {
  return await Api.get<IGetCodeData>(
    `codes/${values.email_get}/${values.key_get}`
  )
}
