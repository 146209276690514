import { DataContext, PartnerContext } from '../context'
import { useContext, useState } from 'react'
import { SandTimerIcon, SparklingIcon } from '../icons'
import Button from './buttons/Button'
import * as Styled from './styled'
import { firstCapital, formatDate } from '../utils/utils'
import Tier from './Tier'
import { subscription_content } from '../pages/content'
import { toast } from 'react-toastify'
import Api from '../services/api'
import { useCookies } from 'react-cookie'
import { resetCancelStatus } from '../utils/reset'

function SubscriptionBlock() {
  const [loading, setLoading] = useState(false)
  const { passStatus, passExpire, canceled, setCanceled } =
    useContext(PartnerContext)
  const { darkTheme } = useContext(DataContext)

  const onCancelSubscription = async () => {
    try {
      setLoading(true)
      await Api.post('payments/cancel', { isPartner: true })
      resetCancelStatus(setCanceled)
      toast(
        'You subscription is cancelled but will stay active until end of paid period'
      )
    } catch (e) {
      console.log('Subscription not canceled, error', e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled.ContentBlockRow>
      <Styled.RowName>
        <Styled.SubscriptionBlock>
          <Styled.SubscriptionTitle $darktheme={darkTheme}>
            <SparklingIcon />
            Your current tier
          </Styled.SubscriptionTitle>
          <Styled.SubscriptionContent>
            {firstCapital(passStatus)}
          </Styled.SubscriptionContent>
          <Styled.SubscriptionTitle $darktheme={darkTheme}>
            <SandTimerIcon />
            Expiring
          </Styled.SubscriptionTitle>
          <Styled.SubscriptionContent>
            {formatDate(passExpire as string)}
          </Styled.SubscriptionContent>
          <Button
            width="fit-content"
            disabled={loading || canceled || passStatus === 'partner'}
            onClick={onCancelSubscription}
          >
            {canceled ? 'Canceled' : 'Cancel subscription'}
          </Button>
        </Styled.SubscriptionBlock>
      </Styled.RowName>
      <Styled.RowContent $darktheme={darkTheme}>
        <Styled.SubscriptionBlock>
          Need to upgrade?
          {subscription_content.map((card) => {
            if (card.type.status !== passStatus) {
              return <Tier key={card.type.status} card={card} />
            }
            return null
          })}
        </Styled.SubscriptionBlock>
      </Styled.RowContent>
    </Styled.ContentBlockRow>
  )
}

export default SubscriptionBlock
