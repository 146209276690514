import Header from './Header'
import PartnerSideBar from './PartnerSideBar'
import PrivateContent from './PrivateContent'

function PrivatePages() {
  return (
    <>
      <PartnerSideBar />
      <div style={{ width: '100%' }}>
        <Header />
        <PrivateContent />
      </div>
    </>
  )
}

export default PrivatePages
