import { IRewardData, RewardType } from '../../types'
import Api from '../../services/api'

export const getRewardData = async (type: RewardType, postId: string) => {
  if (!postId) {
    return {}
  }
  const response = await Api.get<IRewardData>(`partners/${type}/${postId}`)
  return response.data
}
