import { BallTriangle, Grid, Watch } from 'react-loader-spinner'

function Loader({
  width,
  height,
  color,
  type = 'watch',
}: {
  type?: 'watch' | 'triangle' | 'grid'
  width?: string
  height?: string
  color?: string
}) {
  if (type === 'triangle') {
    return (
      <BallTriangle
        height={height || '1.5rem'}
        width={width || '5rem'}
        color={color || '#000000'}
        ariaLabel="watch-loading"
        visible={true}
      />
    )
  }

  if (type === 'grid') {
    return (
      <Grid
        height={height || '1.5rem'}
        width={width || '5rem'}
        color={color || '#000000'}
        ariaLabel="grid-loading"
        visible={true}
      />
    )
  }

  return (
    <Watch
      height={height || '1.3rem'}
      width={width || '5rem'}
      color={color || '#000000'}
      ariaLabel="watch-loading"
      visible={true}
    />
  )
}

export default Loader
