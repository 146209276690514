import { IGameCard } from '@/types'
import * as Styled from './styled'
import { useContext, useState } from 'react'
import { DataContext } from '../context'
import { getStatus } from '../utils/utils'
import GameCardMenu from './menu/GameCardMenu'
import { MenuVertivalIcon } from '../icons'
import GameDeleteModal from './modals/GameDeleteModal'
import { useCloseOnClickOutside } from '../hooks/useCloseOnClickOutside'

function GameCard({ game, getData }: { game: IGameCard; getData: () => void }) {
  const { show, setShow } = useCloseOnClickOutside(game.id.toString())
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { darkTheme } = useContext(DataContext)
  const { color, title } = getStatus(game.status)

  return (
    <Styled.GameCardContainer>
      <Styled.GameCardImage gameId={game.id} $darktheme={darkTheme}>
        {game.logo ? (
          <img
            id={`image-${game.id}`}
            src={game.logo}
            alt={game.title + ' logo'}
            height={242}
          />
        ) : (
          <Styled.MockLogo $darktheme={darkTheme}>Logo</Styled.MockLogo>
        )}
        <Styled.MenuIconContainer
          onClick={() => setShow(true)}
          id={`new-entry-button-${game.id}`}
        >
          <MenuVertivalIcon id={`new-entry-svg-${game.id}`} />
        </Styled.MenuIconContainer>
        <GameCardMenu
          open={show}
          gameId={game.id}
          setDeleteModalOpen={setDeleteModalOpen}
        />
        <GameDeleteModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          gameId={game.id.toString()}
          getData={getData}
        />
      </Styled.GameCardImage>
      <Styled.GameTitle>
        {game.title}
        {game.status !== 'published' && (
          <Styled.Status color={color} $darktheme={darkTheme}>
            {title}
          </Styled.Status>
        )}
      </Styled.GameTitle>
      {game.genres.length > 0 && (
        <Styled.Genres>
          {game.genres.map((el, index) => (
            <Styled.Genre $darktheme={darkTheme} key={index}>
              {el}
            </Styled.Genre>
          ))}
        </Styled.Genres>
      )}
    </Styled.GameCardContainer>
  )
}

export default GameCard
