import Button from '../components/buttons/Button'
import * as Styled from './styled'
import { toast } from 'react-toastify'
import { useState } from 'react'
import Loader from '../components/Loader'
import ContentBlockRow from '../components/ContentBlockRow'
import { admin_content } from './content'
import { generateInviteCode, getInviteCode } from '../utils/api/code'

const INITIAL_VALUES = {
  email_generate: '',
  key_generate: '',
  email_get: '',
  key_get: '',
  code_generated: '',
  code_get: '',
}

function ProfilePage() {
  const linkUrl = 'https://partners.metacade.co/?code='

  const [values, setValues] = useState(INITIAL_VALUES)
  const [isGenerating, setIsGenerating] = useState(false)
  const [isGetting, setIsGetting] = useState(false)

  const [loaderColor, setLoaderColor] = useState('white')

  const generateCode = async () => {
    try {
      setValues({ ...values, code_generated: '' })
      const data = await generateInviteCode(values)
      setValues({ ...values, code_generated: linkUrl + data.code })
    } catch (error) {
      console.error(error)
      toast(
        error.response?.data?.message ||
          error.response?.data ||
          'Error generating code'
      )
    }
  }

  const getCode = async () => {
    try {
      setValues({ ...values, code_get: '' })
      const data = await getInviteCode(values)
      if (data) {
        setValues({ ...values, code_get: linkUrl + data.code })
      } else {
        toast('No code found')
      }
    } catch (error) {
      console.error(error)
      toast(
        error.response?.data?.message ||
          error.response?.data ||
          'Error getting code'
      )
    }
  }

  const onGenerateClick = async () => {
    setIsGenerating(true)
    await generateCode()
    setIsGenerating(false)
  }

  const onGetCodeClick = async () => {
    setIsGetting(true)
    await getCode()
    setIsGetting(false)
  }

  const handlers = {
    generate: {
      handler: onGenerateClick,
      name: 'Generate',
      disabled: !values.email_generate || !values.key_generate,
      isLoading: isGenerating,
    },
    get: {
      handler: onGetCodeClick,
      name: 'Get',
      disabled: !values.email_get || !values.key_get,
      isLoading: isGetting,
    },
  }

  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>SignUp Codes</Styled.Title>
      </Styled.TitleBlock>
      {admin_content.map((block) => (
        <Styled.ContentBlock key={block.name}>
          <Styled.ContentBlockTitle>{block.name}</Styled.ContentBlockTitle>
          {block.rows.map((item) => (
            <ContentBlockRow
              key={item.tag}
              tag={item.tag}
              title={item.title}
              value={values}
              setValue={setValues}
              content_type={item.content_type}
              placeholder={item.placeholder}
              description={item.description}
              options={item.options}
              disabled={item.disabled}
              required={item.required}
            />
          ))}
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button
              onClick={handlers[block.tag].handler}
              color="black"
              width="6rem"
              onMouseEnter={() => setLoaderColor('black')}
              onMouseLeave={() => setLoaderColor('white')}
              disabled={handlers[block.tag].disabled}
            >
              {handlers[block.tag].isLoading ? (
                <Loader color={loaderColor} width="2.5rem" />
              ) : (
                handlers[block.tag].name
              )}
            </Button>
          </div>
        </Styled.ContentBlock>
      ))}
    </Styled.PageContainer>
  )
}

export default ProfilePage
