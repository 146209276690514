import { IGameData } from '@/types'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
  MouseEvent,
  useEffect,
} from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useDropzone } from 'react-dropzone'
import * as Styled from './styled'
import { DataContext } from '../../context'
import { ZipIcon, OkGreenIcon } from '../../icons'
import Button from '../buttons/Button'

function GameFileUpload({
  value,
  setValue,
  loading,
  percentLoaded,
  onUploadClick,
  onCancelUploadClick,
}: {
  loading?: boolean
  percentLoaded?: number
  value: IGameData
  setValue: Dispatch<SetStateAction<IGameData>>
  onUploadClick?: () => void
  onCancelUploadClick?: () => void
}) {
  const { darkTheme } = useContext(DataContext)
  const [file, setFile] = useState<string | File>('')
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setValue({
        ...value,
        source_url: acceptedFiles[0],
        is_file_uploaded: true,
      })
      setFile(file)
    },
    [file, setValue, value]
  )
  const [sourceType, setSourceType] = useState('file')

  const isFileSourceType = sourceType === 'file'
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/zip': [],
      'application/x-7z-compressed': [],
      'application/vnd.rar': [],
    },
    noDrag: true,
    multiple: false,
  })

  const ProgressBar = () => (
    <Styled.ProgressBarWrapper>
      <CircularProgressbar
        value={percentLoaded || 0}
        background
        backgroundPadding={6}
        styles={buildStyles({
          backgroundColor: '#8620F3',
          textColor: '#fff',
          pathColor: '#fff',
          trailColor: 'transparent',
        })}
      />
    </Styled.ProgressBarWrapper>
  )

  const isFileLoaded = typeof file === 'string'

  const onCloseClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setFile('')
    setValue({ ...value, source_url: null, is_file_uploaded: false })
  }

  useEffect(() => {
    setFile(value?.source_url || '')
  }, [value?.source_url])

  useEffect(() => {
    setSourceType(value?.is_file_uploaded ? 'file' : 'url')
  }, [value?.is_file_uploaded])

  const onSourceTypeChange = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement
    setSourceType(target.value)
    setValue({ ...value, source_url: null, is_file_uploaded: false })
  }

  const SourceSelector = () => (
    <Styled.SourceSelector>
      <label>
        <input
          type="radio"
          value="file"
          checked={isFileSourceType}
          onChange={onSourceTypeChange}
          style={{ marginRight: '0.5rem' }}
        />
        Upload file
      </label>
      <label>
        <input
          type="radio"
          value="url"
          checked={!isFileSourceType}
          onChange={onSourceTypeChange}
          style={{ marginRight: '0.5rem' }}
        />
        Source URL
      </label>
    </Styled.SourceSelector>
  )

  return (
    <>
      <SourceSelector />
      {isFileSourceType ? (
        !!file && value.is_file_uploaded ? (
          <Styled.FileLinkBlock $darktheme={darkTheme}>
            {!isFileLoaded && !loading ? (
              <Styled.DropzoneBtnWrapper
                color={darkTheme ? '#656565' : '#B0AEB4'}
                $filled
                onClick={onUploadClick}
                right="120px"
              >
                <Button
                  color={darkTheme ? 'black' : 'white'}
                  borderRadius="8px"
                >
                  Upload
                </Button>
              </Styled.DropzoneBtnWrapper>
            ) : (
              <Styled.StatusIconWrapper>
                {loading ? <ProgressBar /> : <OkGreenIcon />}
              </Styled.StatusIconWrapper>
            )}
            <Styled.DropzoneBtnWrapper
              color={darkTheme ? '#656565' : '#B0AEB4'}
              $filled
              onClick={loading ? onCancelUploadClick : onCloseClick}
            >
              <Button color={darkTheme ? 'black' : 'white'} borderRadius="8px">
                {isFileLoaded ? 'Delete' : 'Cancel'}
              </Button>
            </Styled.DropzoneBtnWrapper>
            <ZipIcon />
            <Styled.NameSizeBlock $darktheme={darkTheme}>
              {isFileLoaded ? (
                <a target="_blank" href={file} rel="noreferrer">
                  {file.slice(file.lastIndexOf('/') + 1)}
                </a>
              ) : (
                <div>{file?.name}</div>
              )}
            </Styled.NameSizeBlock>
          </Styled.FileLinkBlock>
        ) : (
          <Styled.GameFileUploadContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <Button color={darkTheme ? 'white' : 'black'} borderRadius="8px">
              Choose file +
            </Button>
          </Styled.GameFileUploadContainer>
        )
      ) : (
        <Styled.RowContent $darktheme={darkTheme}>
          <input
            className="input-element"
            placeholder="https://"
            value={value.source_url || ''}
            onChange={(e) =>
              setValue({
                ...value,
                source_url: !!e.target.value ? e.target.value : null,
              })
            }
          />
        </Styled.RowContent>
      )}
    </>
  )
}

export default GameFileUpload
