import styled from 'styled-components'

export const DropzoneContainer = styled.div<{
  width: string
  height: string
  darkTheme: boolean
  fixedHeight?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-height: ${(props) => props.height};
  height: ${(props) => (props.fixedHeight ? props.height : 'auto')};
  width: ${(props) => props.width};
  border-radius: 16px;
  background: #f5f5f5;
  color: #8d8d8d;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  border: 1px dashed ${(props) => (props.darkTheme ? '#2D2D2D' : '#DBDBDB')};
  background: ${(props) => (props.darkTheme ? '#111' : '#f5f5f5')};
  position: relative;
  margin-bottom: 1rem;
  margin-top: 1rem;

  &:hover {
    color: ${(props) => (props.darkTheme ? '#fff' : '#000')};
  }
`

export const SourceSelector = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
`

export const FileSizeBlock = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.64);
  font-size: 12px;
  color: black;
  z-index: 10;
`

export const CloseIconWrapper = styled.div<{
  $filled: boolean
  $darktheme?: boolean
  top?: string
  bottom?: string
  right?: string
}>`
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : 'auto')};
  top: ${(props) => (props.top ? props.top : '0.5rem')};
  right: ${(props) => (props.right ? props.right : '0.5rem')};
  display: ${(props) => (props.$filled ? 'block' : 'none')};
  color: #8d8d8d;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: ${(props) => (props.$darktheme ? '#fff' : '#000')};
  }
`

export const DropzoneBtnWrapper = styled.div<{
  $filled: boolean
  color?: string
  right?: string
}>`
  position: absolute;
  top: auto;
  right: ${(props) => (props.right ? props.right : '15px')};
  display: ${(props) => (props.$filled ? 'block' : 'none')};
  color: ${(props) => props.color};
  cursor: pointer;
  z-index: 10;
  font-weight: 600;
  font-family: gilroy-medium;
`

export const ProgressBarWrapper = styled.div`
  width: 28px;
  height: 28px;
`

export const StatusIconWrapper = styled.div<{}>`
  position: absolute;
  top: auto;
  right: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const GameFileUploadContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
`

export const RowContent = styled.div<{ $darktheme: boolean }>`
  flex: 0 0 55%;
  position: relative;

  & input[type='date']::-webkit-calendar-picker-indicator {
    filter: ${(props) => (props.$darktheme ? 'invert(100%)' : 'none')};
  }

  & .input-element {
    width: 100%;
    height: 48px;
    border-radius: 16px;
    padding: 12px;
    border: 1px solid #dbdbdb;
    outline: none;
    outline-offset: 0;
    font-family: gilroy;
    border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
    background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
    color: ${(props) => (props.$darktheme ? 'white ' : 'black')};
    resize: none;
    transition: min-height 0.5s ease-out;

    &::placeholder {
      color: #8d8d8d;
    }

    &:selected {
      color: red !important;
    }

    &:focus {
      border: 1px solid #e844c9;
    }
  }
`

export const FileLinkBlock = styled.div<{ $darktheme: boolean }>`
  width: 100%;
  min-height: 4rem;
  background: transparent;
  color: ${(props) => (props.$darktheme ? '#656565' : '#222')};
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  border-radius: 1rem;
  border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#E0E0E0')};
  padding: 0 1rem;
`

export const NameSizeBlock = styled.div<{ $darktheme: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-family: gilroy-semibold;
  color: ${(props) => (props.$darktheme ? '#656565' : '#222')};
  width: 65%;

  & a,
  div {
    display: block;
    color: inherit;
    text-decoration: none;
  }
`
