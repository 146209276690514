import { SubscriptionContentType } from '@/types'
import { InfoIcon } from '../icons'
import Button from './buttons/Button'
import TierCard from './cards/TierCard'
import * as Styled from './styled'
import { DataContext, PartnerContext } from '../context'
import { useContext, useState } from 'react'
import { subscription_content } from '../pages/content'
import Api from '../services/api'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import { resetTierData } from '../utils/reset'

function Tier({ card }: { card: SubscriptionContentType }) {
  const [loading, setLoading] = useState(false)
  const { passStatus, setPassStatus, setPassExpire } =
    useContext(PartnerContext)
  const { darkTheme } = useContext(DataContext)

  const currentCard = subscription_content.find(
    (card) => card.type.status === passStatus
  )

  const onChangeSubscription = async () => {
    if (passStatus === 'partner') {
      toast('Available after free subscription ends up')
      return
    }
    try {
      setLoading(true)
      await Api.post('payments/upgrade', {
        passStatus: card.type.status,
        isPartner: true,
      })
      resetTierData(setPassStatus, setPassExpire)

      toast('Your subscription is updated')
    } catch (e) {
      console.error(e)
      toast('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled.TierContainer color={card.type.color}>
      <div>
        <Styled.PassStatus>
          {card.type.title}
          <Styled.InfoIconContainer>
            <TierCard card={card} />
            <InfoIcon />
          </Styled.InfoIconContainer>
        </Styled.PassStatus>
        <div className="price">{'$' + card.price + ' monthly'}</div>
      </div>
      {card.price > (currentCard?.price || 0) && (
        <Button
          color={darkTheme ? 'white-transparent' : 'black-transparent'}
          disabled={loading}
          onClick={onChangeSubscription}
        >
          Upgrade
        </Button>
      )}
    </Styled.TierContainer>
  )
}

export default Tier
