import axios, { AxiosProgressEvent } from 'axios'
import { IGame, IGameData } from '@/types'
import Api from '../../services/api'
import { appendImage, appendImages } from './heplers'
import { BACKEND_DEV_URL, BACKEND_PROD_URL } from '../../utils/urls'
import { isDev } from '../../config'

export const getGamesData = async (partner_cms_id: string) => {
  const response = await Api.get<IGame>(
    `partners/games?publicationState=preview&populate[0]=logo&populate[1]=genres&filters[publisher][id][$eq]=${partner_cms_id}`
  )
  return response.data
}

export const getGameData = async (game_id: string) => {
  if (game_id) {
    const response = await Api.get<IGame>(
      `partners/game/${game_id}?populate=trailer_video.thumbnail,is_file_uploaded,second_trailer_video.thumbnail,category,tags,logo,preview_images,platform,publisher,alpha_extra_image,alpha_extra_questions,network,genres`
    )
    return response.data
  }
  return {}
}

export const prepareGameData = (values: IGameData) => {
  return {
    title: values.title,
    description: values.description,
    short_description: values.short_description,
    specifications: values.specifications,
    category: !!values.filters.category.length
      ? values.filters.category?.map((el: any) => ({ Category: el }))
      : null,
    genres: !!values.filters.genres.length
      ? values.filters.genres?.map((el: any) => ({ Genre: el }))
      : null,
    platform: !!values.filters.platform.length
      ? values.filters.platform?.map((el: any) => ({ GamePlatform: el }))
      : null,
    age_rate: values.filters.age_rate,
    GameType: values.filters.game_type,
    network: !!values.filters.network.length
      ? values.filters.network?.map((el: any) => ({ Network: el }))
      : null,
    trailer_video: {
      url: values.trailer_video_url,
    },
    second_trailer_video: {
      url: values.second_trailer_video_url,
    },
    languages_audio: values.languages_audio,
    languages_text: values.languages_text,
    release_date: values.release_date,
    is_alpha: values.is_alpha,
    is_development: values.is_development,
    alpha_finish_date: values.alpha_finish_date,
    alpha_extra_text: values.alpha_extra_text,
    alpha_extra_questions: [
      {
        type: values.alpha_extra_questions_type,
        title: values.alpha_extra_questions_title,
      },
    ],
    twitter_url: values.x,
    discord_url: values.discord,
    reddit_url: values.reddit,
    linktree_url: values.linktree,
    website_url: values.website,
    in_review: false,
    google_store_url: values.google_store_url,
    apple_store_url: values.apple_store_url,
    source_url:
      typeof values.source_url === 'string' ? values.source_url || '' : null,
    is_file_uploaded: values.is_file_uploaded,
  }
}

export const postGameData = async (values: IGameData) => {
  const dataToSend = prepareGameData(values)
  let formData = new FormData()
  appendImages('preview_images', values.preview_images, formData)
  appendImage('logo', values.logo, formData)
  appendImage('alpha_extra_image', values.alpha_extra_image, formData)
  appendImage(
    'trailer_video.thumbnail',
    values.trailer_video_thumbnail,
    formData
  )
  appendImage(
    'second_trailer_video.thumbnail',
    values.second_trailer_video_thumbnail,
    formData
  )
  formData.append(
    'data',
    JSON.stringify({
      ...dataToSend,
      publisher: { connect: ['partner_cms_id'] },
      publishedAt: null,
    })
  )

  const response = await Api.postFormData<IGameData>(`partners/game`, formData)
  return response.status
}

export const updateGameData = async (values: IGameData, gameId: string) => {
  const dataToSend = prepareGameData(values)

  let formData = new FormData()
  appendImages('preview_images', values.preview_images, formData)
  appendImage('logo', values.logo, formData)
  appendImage('alpha_extra_image', values.alpha_extra_image, formData)
  appendImage(
    'trailer_video.thumbnail',
    values.trailer_video_thumbnail,
    formData
  )
  appendImage(
    'second_trailer_video.thumbnail',
    values.second_trailer_video_thumbnail,
    formData
  )
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<IGameData>(
    `partners/game/${gameId}`,
    formData
  )
  return response.status
}

export const unpublishGame = async (gameId: string) => {
  const dataToSend: any = {
    publishedAt: null,
    in_review: false,
  }
  let formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData(
    `partners/game/${gameId}`,
    formData,
    {}
  )
  return response.status
}

export const updateGameReviewStatus = async (gameId: string) => {
  const dataToSend: any = {
    in_review: true,
  }
  let formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData(
    `partners/game/${gameId}`,
    formData,
    {}
  )
  return response.status
}

export const uploadFile = async (
  file: File,
  gameId: string,
  setPercentLoaded: Function,
  uploadCancelToken: Function,
  setUploadCancelToken: Function
) => {
  const backendURL = isDev() ? BACKEND_DEV_URL : BACKEND_PROD_URL

  const cancelToken = axios.CancelToken.source()
  setUploadCancelToken(cancelToken)

  const uploadLink = await axios.get(
    `${backendURL}/partners/game/upload/link/${file.name}/${gameId}`,
    {
      withCredentials: true,
    }
  )

  const responseWithProgress = await axios.put(uploadLink.data, file, {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      setPercentLoaded(
        Math.round((progressEvent.loaded / (progressEvent.total || 100)) * 100)
      ),
    cancelToken: cancelToken.token,
  })

  return responseWithProgress.status
}

export const setFileReviewToFalse = async (id: string) => {
  const dataToSend = {
    is_file_reviewed: false,
  }

  const formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  return await Api.putFormData<IGameData>(`partners/game/${id}`, formData, {})
}
