import { useState } from 'react'

const usePartnerContext = () => {
  const [partner_id, setPartnerId] = useState<string | null>(null)
  const [partner_cms_id, setPartnerCmsId] = useState<string | null>(null)
  const [partner_email, setPartnerEmail] = useState<string | null>(null)
  const [partner_name, setPartnerName] = useState<string | null>(null)
  const [logo, setLogo] = useState<string | null>(null)
  const [passStatus, setPassStatus] = useState<string | null>(null)
  const [passExpire, setPassExpire] = useState<string | null>(null)
  const [referrerId, setReferrerId] = useState<string | null>(null)
  const [referralLink, setReferralLink] = useState<string | null>(null)
  const [canceled, setCanceled] = useState<boolean>(false)

  return {
    partner_id,
    partner_email,
    partner_cms_id,
    setPartnerId,
    setPartnerCmsId,
    setPartnerEmail,
    partner_name,
    setPartnerName,
    logo,
    setLogo,
    passStatus,
    setPassStatus,
    passExpire,
    setPassExpire,
    canceled,
    setCanceled,
    referrerId,
    setReferrerId,
    referralLink,
    setReferralLink,
  }
}

export default usePartnerContext
