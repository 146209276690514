import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import * as Styled from './styled'
import { CloseIcon, UploadIcon } from '../../icons'
import { DropzoneNamesType } from '../../types'
import { DataContext } from '../../context'
import { formatFileSize } from '../../utils/utils'

interface DropzoneTypes {
  width: string
  height: string
  resolution?: string
  values?: any
  setValues?: Dispatch<SetStateAction<any>>
  name?: DropzoneNamesType
  index?: number
  allFilesSize?: number
  setAllFilesSize?: Dispatch<SetStateAction<number>>
  requirements?: string
}

function Dropzone({
  width,
  height,
  resolution,
  values,
  setValues,
  allFilesSize,
  setAllFilesSize,
  name = 'logo',
  index,
  requirements = '',
}: DropzoneTypes) {
  const { darkTheme } = useContext(DataContext)
  const [file, setFile] = useState('')
  const [fileSize, setFileSize] = useState(0)
  const url: string =
    index !== undefined
      ? (values?.[name]?.[index]?.url as string)
      : (values?.[name] as string) || ''
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (values && setValues) {
        if (index !== undefined) {
          const newFiles = new Array(100)
          values[name].forEach((file: string | File, ind: number) => {
            newFiles[ind] = file
          })
          newFiles[index] = acceptedFiles[0]
          setValues({ ...values, [name]: [...newFiles] })
        } else {
          setValues({ ...values, [name]: acceptedFiles[0] })
        }
        setFileSize(acceptedFiles[0].size)
        setAllFilesSize && setAllFilesSize(allFilesSize + acceptedFiles[0].size)
      }
      const imageUrl = URL.createObjectURL(acceptedFiles[0])
      setFile(imageUrl)
    },
    [allFilesSize, index, name, setAllFilesSize, setValues, values]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: false,
  })

  const onCloseClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (values && setValues) {
      if (index !== undefined) {
        const newFiles = [...values[name]]
        if (!!newFiles[index]?.name) {
          setAllFilesSize &&
            allFilesSize &&
            setAllFilesSize(allFilesSize - newFiles[index]?.size)
          setFile('')
        }
        newFiles[index] = { id: 0, url: undefined }
        setValues({ ...values, [name]: [...newFiles] })
      } else {
        setAllFilesSize &&
          values[name]?.size &&
          allFilesSize &&
          setAllFilesSize((prev) => prev - values[name]?.size)
        setFile('')
        setValues({ ...values, [name]: undefined })
      }
      setFileSize(0)
    }
  }

  useEffect(() => {
    if (
      index !== undefined &&
      Array.isArray(values?.[name]) &&
      !values?.[name]?.some((el: any) => el?.name || false)
    ) {
      setFile('')
      setFileSize(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <Styled.DropzoneContainer
      {...getRootProps()}
      width={width}
      height={height}
      darkTheme={darkTheme}
      fixedHeight={!!index}
    >
      <Styled.CloseIconWrapper
        $darktheme={darkTheme}
        $filled={!!url || !!file}
        onClick={onCloseClick}
      >
        <CloseIcon />
      </Styled.CloseIconWrapper>
      <input {...getInputProps()} />
      {!!url || file ? (
        <img
          src={typeof url === 'string' ? url : file}
          alt="file"
          width={!!index ? width : '100%'}
          height={!!index ? 'auto' : '100%'}
        />
      ) : isDragActive ? (
        <div style={{ padding: '1rem' }}>Drop the files here...</div>
      ) : (
        <>
          <UploadIcon />
          <div>{resolution}</div>
          <div>{requirements}</div>
        </>
      )}
      {!!fileSize && (
        <Styled.FileSizeBlock>{formatFileSize(fileSize)}</Styled.FileSizeBlock>
      )}
    </Styled.DropzoneContainer>
  )
}

export default Dropzone
