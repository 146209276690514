import { Partner } from '@/types/partner'
import Api from '../services/api'
import { SetStateAction } from 'react'
import { SetStateString } from '@/types'

export const resetTierData = async (
  setPassStatus: SetStateString,
  setPassExpire: SetStateString
) => {
  try {
    const partner = await Api.get<Partner>('partners')

    if (partner.id && partner.passStatus && partner.passStatus !== 'pending') {
      setPassStatus(partner?.passStatus || null)
      setPassExpire(partner?.subscriptionEndsAt || null)
    }
  } catch (error) {
    console.error(error)
  }
}

export const resetCancelStatus = async (
  setCanceled: React.Dispatch<SetStateAction<boolean>>
) => {
  try {
    const partner = await Api.get<Partner>('partners')

    if (partner.id && partner.passStatus && partner.passStatus !== 'pending') {
      setCanceled(partner?.isCanceled || false)
    }
  } catch (error) {
    console.error(error)
  }
}
