import { FeedType } from '../../types'
import Api from '../../services/api'

export const getFeedData = async (url: string) => {
  return await Api.get<FeedType>(`partners/feeds?${url}`)
}

export const getOneFeedData = async (feedId: string) => {
  const response = await Api.get<FeedType>(`partners/feed/${feedId}`)
  return response.data
}

export const postFeed = async (
  type: FeedType,
  postId: string,
  partner_id: string,
  tags: { tag: string | null }[],
  publish_date: string | null,
  expiry_date: string | null
) => {
  const dataToSend: any = {
    type,
    tags,
    [type]: { connect: [Number(postId)] },
    publisher: { connect: ['partner_cms_id'] },
    publishedAt: null,
    publish_date: publish_date || null,
    expiry_date: expiry_date || null,
  }
  let formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.postFormData<FeedType>(
    `partners/feed`,
    formData,
    {}
  )

  return response.status
}

export const updateFeed = async (
  feedId: string,
  tags: { tag: string | null }[],
  publish_date: string | null,
  expiry_date: string | null
) => {
  const dataToSend: any = {
    tags,
    publishedAt: null,
    review_status: null,
    publish_date: publish_date || null,
    expiry_date: expiry_date || null,
  }
  let formData = new FormData()
  formData.append('data', JSON.stringify(dataToSend))

  const response = await Api.putFormData<FeedType>(
    `partners/feed/${feedId}`,
    formData,
    {}
  )

  return response.status
}
