import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { Link } from 'react-router-dom'
import * as Styled from './styled'
// import * as StyledBtn from '../components/buttons/styled'
import { useState, useContext, useEffect } from 'react'
import {
  DashboardIcon,
  GameControllerIcon,
  MoonIcon,
  RSSIcon,
  SunIcon,
  UserIcon,
  ModIcon,
  ChevronDownIcon,
} from '../icons'
import { DataContext, PartnerContext } from '../context'
import PartnerInfo from './PartnerInfo'
import Button from './buttons/Button'
import { getProfileData } from '../utils/api/profile'
import { useCookies } from 'react-cookie'
import { determineFilter } from '../utils/utils'
import Switch from 'react-switch'
import { isDev } from '../config'
// import AffiliateModal from '../components/modals/AffiliateModal'
// import { toast } from 'react-toastify'
import Api from '../services/api'
import { Partner } from '@/types/partner'

function PartnerSideBar() {
  const [showSidebar, setShowSidebar] = useState(false)
  // const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const {
    setPartnerCmsId,
    setPartnerName,
    setLogo,
    referrerId,
    // referralLink,
    setReferralLink,
    setReferrerId,
  } = useContext(PartnerContext)
  const { filter, setFilter, setPage, setDarkTheme, darkTheme } =
    useContext(DataContext)

  const onClickHandler = (e: any) => {
    const menuItemType = e?.target?.textContent?.toLocaleLowerCase() || null
    setFilter(menuItemType)
    setPage(1)
  }

  // const handleInviteModalClose = () => {
  //   setInviteModalOpen(false)
  // }

  // const handleInviteModalOpen = () => {
  //   setInviteModalOpen(true)
  // }

  const onSwitchChange = () => {
    if (!darkTheme) {
      localStorage.setItem('dark', 'true')
    } else {
      localStorage.setItem('dark', 'false')
    }
    setDarkTheme(!darkTheme)
  }

  useEffect(() => {
    const setProfileData = async () => {
      try {
        const data = await getProfileData()
        setPartnerCmsId(data?.data?.id || null)
        setPartnerName(data?.data?.attributes?.name || null)
        setLogo(data?.data?.attributes?.logo?.data?.attributes?.url || null)
        setReferralLink(data?.data?.attributes?.referralLink || null)
        setFilter(determineFilter())
        const partner = await Api.get<Partner>('partners')
        setReferrerId(partner?.referrerId || null)
      } catch (error) {
        console.error(error)
      }
    }

    setProfileData()
  }, [
    setFilter,
    setLogo,
    setPartnerCmsId,
    setPartnerName,
    setReferralLink,
    setReferrerId,
  ])

  return (
    <Styled.SidebarContainer $darktheme={darkTheme}>
      {!showSidebar && (
        <button className="showSidebar" onClick={() => setShowSidebar(true)}>
          Show Sidebar
        </button>
      )}
      <Sidebar
        width="20rem"
        rootStyles={{
          fontFamily: 'gilroy-bold',
          fontSize: '20px',
          height: '100%',
          minHeight: '100vh',
          borderRight: darkTheme ? '1px solid #2D2D2D' : '1px solid #E0E0E0',
        }}
        breakPoint="md"
        toggled={showSidebar}
        onBackdropClick={() => setShowSidebar(false)}
      >
        <Styled.SideBarTopGroup>
          <Styled.HomeLink onClick={() => setFilter('dashboard')}>
            <Styled.Beta>Beta</Styled.Beta>
            <Link to="/">
              <img
                src={
                  darkTheme
                    ? '/logo/metacade-white.png'
                    : '/logo/metacade-black.png'
                }
                width="202"
                height="30"
                alt="metacade-logo"
              />
            </Link>
          </Styled.HomeLink>
          <PartnerInfo />
          <Button
            blank
            href={isDev() ? 'https://qa.metacade.co' : 'https://metacade.co'}
            color={darkTheme ? 'white-transparent' : 'white'}
          >
            Enter The Metacade
          </Button>
        </Styled.SideBarTopGroup>
        <Menu
          renderExpandIcon={({ open }) => (
            <ChevronDownIcon style={{ transform: !open && 'rotate(270deg)' }} />
          )}
          rootStyles={{
            padding: '0 2rem',
          }}
          menuItemStyles={{
            subMenuContent: {
              background: darkTheme ? 'black' : 'white',
            },
            button: ({ level }) => ({
              [`&.ps-active`]: {
                fontFamily: 'gilroy-bold',
                backgroundColor: darkTheme ? '#222' : 'black',
                color: 'white',
                '& path': {
                  fill: 'white',
                },
              },
              padding: '0.625rem 1rem',
              margin: '0.25rem 0',
              marginLeft: level === 1 ? '2rem' : level === 2 ? '4rem' : '0',
              fontFamily: 'gilroy-medium',
              borderRadius: '16px',
              backgroundColor: 'transparent',
              color: darkTheme ? 'white' : 'black',
              '&:hover': {
                fontFamily: 'gilroy-bold',
                backgroundColor: darkTheme ? '#111' : 'black',
                color: 'white',
                '& path': {
                  fill: 'white',
                },
              },
            }),
          }}
        >
          <MenuItem
            active={filter === 'profile'}
            prefix={
              <UserIcon
                className="prefix-icon"
                style={{ margin: '5px 3px 0 0' }}
              />
            }
            component={<Link to="/profile" />}
            onClick={() => setFilter('profile')}
          >
            Profile
          </MenuItem>
          <MenuItem
            active={filter === 'dashboard'}
            prefix={
              <DashboardIcon
                className="prefix-icon"
                style={{ margin: '5px 3px 0 0' }}
              />
            }
            component={<Link to="/home" />}
            onClick={() => setFilter('dashboard')}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            active={filter === 'games'}
            prefix={
              <GameControllerIcon
                className="prefix-icon"
                style={{ margin: '5px 3px 0 0' }}
              />
            }
            component={<Link to="/games" />}
            onClick={() => setFilter('games')}
          >
            Games
          </MenuItem>
          <SubMenu
            label="Feed"
            active={filter === 'feed'}
            className="feed"
            prefix={
              <RSSIcon
                className="prefix-icon"
                style={{ margin: '5px 3px 0 0' }}
              />
            }
            component={<Link to="/feed" />}
            onClick={onClickHandler}
          >
            <MenuItem
              active={filter === 'announcements'}
              onClick={onClickHandler}
              component={<Link to="/feed" />}
              // className='rightShift1'
            >
              Announcements
            </MenuItem>
            <SubMenu
              label="Earn"
              active={filter === 'earn'}
              onClick={onClickHandler}
              component={<Link to="/feed" />}
            >
              <MenuItem
                active={filter === 'quests'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Quests
              </MenuItem>
              <MenuItem
                active={filter === 'content'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Content
              </MenuItem>
              <MenuItem
                active={filter === 'jobs'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Jobs
              </MenuItem>
            </SubMenu>

            <SubMenu
              label="Gaming"
              onClick={onClickHandler}
              component={<Link to="/feed" />}
            >
              <MenuItem
                active={filter === 'merch'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Merch
              </MenuItem>
              <MenuItem
                active={filter === 'tournaments'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Tournaments
              </MenuItem>
            </SubMenu>
            <SubMenu
              label="Rewards"
              active={filter === 'rewards'}
              onClick={onClickHandler}
              component={<Link to="/feed" />}
            >
              <MenuItem
                active={filter === 'offers'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Offers
              </MenuItem>
              <MenuItem
                active={filter === 'digital assets'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Digital Assets
              </MenuItem>
              <MenuItem
                active={filter === 'tokens'}
                onClick={onClickHandler}
                component={<Link to="/feed" />}
              >
                Tokens
              </MenuItem>
            </SubMenu>
          </SubMenu>

          {!referrerId && (
            <MenuItem
              active={filter === 'moderators'}
              prefix={
                <ModIcon
                  className="prefix-icon"
                  style={{ margin: '5px 3px 0 0' }}
                />
              }
              component={<Link to="/moderators" />}
              onClick={() => setFilter('moderators')}
            >
              Mods
            </MenuItem>
          )}
        </Menu>
        <Styled.SwitchContainer darkTheme={darkTheme}>
          <Styled.SwitchIcon>
            {darkTheme ? <SunIcon /> : <MoonIcon />}
            {darkTheme ? 'Light Mode' : 'Dark Mode'}
          </Styled.SwitchIcon>
          <Switch
            checked={darkTheme}
            onChange={onSwitchChange}
            className="dark-mode-switch"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#000000"
            offColor="#ffffff"
            onHandleColor="#ffffff"
            offHandleColor="#000000"
            handleDiameter={20}
            width={44}
            height={24}
            activeBoxShadow="0"
          />
        </Styled.SwitchContainer>
        {/*@todo: uncomment when requirements are clear*/}
        {/*{referralLink ? <StyledBtn.Button*/}
        {/*    className='referral-link'*/}
        {/*    onClick={() => copyLink(toast, referralLink)}*/}
        {/*  >*/}
        {/*    Copy referral link*/}
        {/*  </StyledBtn.Button>*/}
        {/*  : <StyledBtn.Button className='referral-link'*/}
        {/*                      onClick={() => handleInviteModalOpen()}*/}
        {/*  >*/}
        {/*    Become metafriend*/}
        {/*  </StyledBtn.Button>}*/}

        {/*<AffiliateModal open={inviteModalOpen} close={handleInviteModalClose} setReferralLink={setReferralLink} />*/}
      </Sidebar>
    </Styled.SidebarContainer>
  )
}

export default PartnerSideBar
