import { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import { FeedType, StatusType } from '../types'
import { updateReviewStatus } from './api'
import { postFeed, getOneFeedData, updateFeed, getFeedData } from './api/feed'
import { getTags } from './utils'
import { setFileReviewToFalse, uploadFile } from './api/game'

export async function onSaveClick(
  post_type: FeedType,
  values: any,
  post_id: string,
  feed_id: string,
  partner_id: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setStatus: Dispatch<SetStateAction<StatusType | null>>,
  getData: (post_id: string) => void,
  update: (values: any, post_id: string) => Promise<any>,
  postPost: (values: any) => Promise<{ id: number; validationError: boolean }>
) {
  const updateItem = async () => {
    try {
      setLoading(true)
      const updateData = await update(values, post_id as string)
      const tags = getTags(post_type, updateData?.data?.attributes?.type || '')
      const updateFeedStatus = await updateFeed(
        feed_id,
        tags,
        values.publish_date || null,
        values.expiry_date || null
      )
      if (updateData?.data?.id && updateFeedStatus === 200) {
        toast('You have successfully updated current post!')
        return { status: 'ok' }
      } else {
        toast('Something went wrong...')
        return { status: 'error' }
      }
    } catch (error: any) {
      if (error?.response?.data?.name === 'ValidationError') {
        toast('Fill in all required fields!')
      } else {
        toast('Something went wrong...')
      }
      return { status: 'error' }
    } finally {
      getData(post_id as string)
      setLoading(false)
    }
  }

  const postItem = async () => {
    try {
      setLoading(true)
      const newPostId = await postPost(values)

      const tags = getTags(post_type, values?.type || '')
      const responseStatus = await postFeed(
        post_type,
        newPostId.toString(),
        partner_id,
        tags,
        values.publish_date || '',
        values.expiry_date || ''
      )
      if (responseStatus === 200) {
        toast('You have successfully created new post!')
        return { status: 'ok' }
      } else {
        toast('Something went wrong...')
        return { status: 'error' }
      }
    } catch (error: any) {
      if (error?.response?.data?.name === 'ValidationError') {
        toast('Fill in all required fields!')
      } else {
        toast('Something went wrong...')
      }
      console.error(error)
      return { status: 'error' }
    } finally {
      setLoading(false)
    }
  }

  if (!!post_id) {
    return updateItem()
  } else {
    return postItem()
  }
}

export async function onReviewClick(
  feed_id: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) {
  try {
    setLoading(true)
    const responseStatus = await updateReviewStatus(feed_id as string)
    const update_feed_response = await getOneFeedData(feed_id)
    if (responseStatus === 200) {
      toast('Post is in review now!')
      return {
        status: update_feed_response?.attributes?.review_status || null,
      }
    } else {
      toast('Something went wrong...')
      return { status: null }
    }
  } catch (error) {
    console.error(error)
    toast('Something went wrong...')
    return { status: null }
  } finally {
    setLoading(false)
  }
}

export const getCurrentFeed = async (postId: string, postType: FeedType) => {
  try {
    if (!postId) {
      return null
    }
    const response = await getFeedData(
      `filters[${postType}][id][$eq]=${postId}&publicationState=preview`
    )

    return {
      id: response?.data?.[0]?.id,
      status: response?.data?.[0]?.attributes?.publishedAt
        ? 'published'
        : response?.data?.[0]?.attributes?.review_status,
      publish_date: response?.data?.[0]?.attributes?.publish_date,
      expiry_date: response?.data?.[0]?.attributes?.expiry_date,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export const uploadGameFile = async (
  id: string,
  file: string | File | null,
  setPercentLoaded: Function,
  uploadCancelToken: Function,
  setUploadCancelToken: Function
): Promise<void> => {
  if (file instanceof File) {
    toast('Uploading new file...')
    await uploadFile(
      file,
      id,
      setPercentLoaded,
      uploadCancelToken,
      setUploadCancelToken
    )
    await setFileReviewToFalse(id)
    toast('File uploaded successfully!')
    setPercentLoaded(0)
  } else {
    toast('Something went wrong...')
  }
}
