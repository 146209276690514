import { ITEMS_PER_PAGE } from '../constants'
import { FilterType } from '../types'

export const BACKEND_DEV_URL = 'https://lite-dev-api.metacade.co/api'
// export const BACKEND_DEV_URL = 'http://localhost:3005/api'
export const BACKEND_PROD_URL = 'https://lite-api.metacade.co/api'

export function getHost() {
  if (process.env.REACT_APP_ENV === 'localhost') {
    return 'http://localhost:3000'
  }

  if (process.env.REACT_APP_ENV === 'dev') {
    return 'https://partner-qa.metacade.co/'
  }

  if (process.env.REACT_APP_ENV === 'beta') {
    return 'https://partner-beta.metacade.co/'
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return 'https://partners.metacade.co'
  }
}

export const getFeedUrl = (
  partner_cms_id: string | null,
  page: number,
  filter: FilterType | ''
) => {
  const filterByPartnerId = `filters[publisher][id][$eq]=${partner_cms_id}`
  const populate =
    'populate[content][populate][0]=tags&populate[quest][populate][0]=tags&populate[job][populate][0]=tags&populate[announcement][populate][0]=tags&populate[merch][populate][0]=tags&populate[tournament][populate][0]=tags&populate[offer][populate][0]=tags&populate[digital_asset][populate][0]=tags&populate[token][populate][0]=tags'
  const pagination = `pagination[page]=${page}&pagination[pageSize]=${ITEMS_PER_PAGE}`

  switch (filter) {
    case 'earn':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[$or][0][type][$eq]=job&filters[$or][1][type][$eq]=quest&filters[$or][2][type][$eq]=content&${filterByPartnerId}`
    case 'quests':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=quest&${filterByPartnerId}`
    case 'content':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=content&${filterByPartnerId}`
    case 'jobs':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=job&${filterByPartnerId}`
    case 'announcements':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=announcement&${filterByPartnerId}`
    case 'gaming':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[$or][0][type][$eq]=merch&filters[$or][1][type][$eq]=tournament&${filterByPartnerId}`
    case 'merch':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=merch&${filterByPartnerId}`
    case 'tournaments':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=tournament&${filterByPartnerId}`
    case 'rewards':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[$or][0][type][$eq]=digital_asset&filters[$or][1][type][$eq]=offer&filters[$or][2][type][$eq]=token&${filterByPartnerId}`
    case 'digital assets':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=digital_asset&${filterByPartnerId}`
    case 'offers':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=offer&${filterByPartnerId}`
    case 'tokens':
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&filters[type][$eq]=token&${filterByPartnerId}`
    default:
      return `${populate}&${pagination}&publicationState=preview&sort=updatedAt:desc&${filterByPartnerId}`
  }
}

export const bookCallUrl = 'https://calendly.com/dennis-metacade'
