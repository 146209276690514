import Button from '../components/buttons/Button'
import * as Styled from './styled'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import { getProfileData, updateProfileData } from '../utils/api/profile'
import { IProfileData } from '../types'
import { transformProfileData } from '../utils/data'
import Loader from '../components/Loader'
import ContentBlockRow from '../components/ContentBlockRow'
import { profile_content } from './content'
import { defaultProfileData } from './defaultData'
import { useCookies } from 'react-cookie'
import { PartnerContext } from '../context'
import { getGamesData } from '../utils/api/game'

function ProfilePage() {
  const [profileData, setProfileData] =
    useState<IProfileData>(defaultProfileData)
  const [values, setValues] = useState<IProfileData>(defaultProfileData)
  const [loading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const { setPartnerName, setLogo, partner_cms_id, referrerId } =
    useContext(PartnerContext)

  const getData = async () => {
    try {
      const data = await getProfileData()
      const gamesData = await getGamesData(partner_cms_id as string)
      setPartnerName(data?.data?.attributes?.name || null)
      setLogo(data?.data?.attributes?.logo?.data?.attributes?.url || null)
      const restructuredData = transformProfileData(
        data?.data?.attributes,
        gamesData
      )
      setValues(restructuredData)
      setProfileData(restructuredData)
    } catch (error) {
      console.error(error)
      toast('Error occured during profile data fetching...')
    }
  }

  const onSaveClick = async () => {
    try {
      setLoading(true)
      const responseStatus = await updateProfileData(values)
      if (responseStatus === 200) {
        toast('You have successfully updated your profile!')
      } else {
        toast('Something went wrong...')
      }
    } catch (error: any) {
      console.error(error)
      if (error?.response?.data?.name === 'ValidationError') {
        toast('Fill in all required fields!')
      } else {
        toast('Something went wrong...')
      }
    } finally {
      getData()
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>Profile</Styled.Title>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            onClick={onSaveClick}
            color="black"
            disabled={JSON.stringify(profileData) === JSON.stringify(values)}
            width="5rem"
            onMouseEnter={() => setLoaderColor('black')}
            onMouseLeave={() => setLoaderColor('white')}
          >
            {loading ? <Loader color={loaderColor} width="2.5rem" /> : 'Save'}
          </Button>
          {/* <Button disabled>Preview</Button> */}
        </div>
      </Styled.TitleBlock>
      {profile_content
        .filter((block) => {
          if (referrerId) {
            return block.name !== 'Subscription'
          }
          return true
        })
        .map((block) => (
          <Styled.ContentBlock key={block.name}>
            <Styled.ContentBlockTitle>{block.name}</Styled.ContentBlockTitle>
            {block.rows.map((item) => (
              <ContentBlockRow
                key={item.tag}
                tag={item.tag}
                title={item.title}
                value={values}
                setValue={setValues}
                content_type={item.content_type}
                placeholder={item.placeholder}
                description={item.description}
                options={item.options}
                disabled={item.disabled}
                required={item.required}
              />
            ))}
          </Styled.ContentBlock>
        ))}
    </Styled.PageContainer>
  )
}

export default ProfilePage
