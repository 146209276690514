import { DropzoneNamesType } from '@/types'
import Dropzone from './dropzone/Dropzone'
import * as Styled from './styled'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { checkIfFilled, getDefaultImagesCount } from '../utils/utils'
import { DataContext } from '../context'

function PreviewImages({
  tag,
  values,
  setValues,
  allFilesSize,
  setAllFilesSize,
}: {
  tag: string
  values: any
  setValues: any
  setAllFilesSize?: Dispatch<SetStateAction<number>>
  allFilesSize?: number
}) {
  const { darkTheme } = useContext(DataContext)
  const [imagesCount, setImagesCount] = useState<number[]>([])

  useEffect(() => {
    setImagesCount(
      getDefaultImagesCount(
        values.preview_images.filter((el: any) => el).length
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.preview_images.length])

  const onAddMoreClick = () => {
    const isFilled = checkIfFilled(values.preview_images, imagesCount.length)
    if (isFilled) {
      setImagesCount((prev) => [...prev, prev.length + 1])
      setValues({
        ...values,
        [tag]: [...values[tag].filter((el: any) => el), { id: 0, url: '' }],
      })
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Styled.ContentBlockRow>
        <Styled.RowName>Featured Image</Styled.RowName>
      </Styled.ContentBlockRow>
      <Dropzone
        width="100%"
        height="274px"
        resolution="1920x1080"
        values={values}
        setValues={setValues}
        allFilesSize={allFilesSize}
        setAllFilesSize={setAllFilesSize}
        name={tag as DropzoneNamesType}
        index={0}
        requirements="JPG or PNG are required"
      />
      <Styled.ContentBlockRow>
        <Styled.RowName>Preview Images</Styled.RowName>
      </Styled.ContentBlockRow>
      <Styled.PreviewImagesRow>
        {imagesCount.map((el) => (
          <Dropzone
            width="136px"
            height="76px"
            resolution="1920x1080"
            values={values}
            setValues={setValues}
            allFilesSize={allFilesSize}
            setAllFilesSize={setAllFilesSize}
            name={tag as DropzoneNamesType}
            index={el}
          />
        ))}
        {/*<Styled.AddImageButton $darktheme={darkTheme} onClick={onAddMoreClick}>*/}
        {/*  <PlusIcon />*/}
        {/*</Styled.AddImageButton>*/}
      </Styled.PreviewImagesRow>
    </div>
  )
}

export default PreviewImages
