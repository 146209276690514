import styled from 'styled-components'
import { setColor } from '../../utils/buttons'
import { DEVICES } from '../../responsive'
import { ButtonColors } from './Button'

export const Button = styled.button<{
  color: ButtonColors
  disabled?: boolean
  width?: string
  borderRadius?: string
}>`
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 10px;
  border-radius: ${(props) => props.borderRadius || '16px'};
  border: ${(props) => setColor(props.color, 'border')};
  background-color: ${(props) => setColor(props.color, 'background')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  color: ${(props) => setColor(props.color, 'text')};
  font-family: gilroy;
  font-size: 1rem;
  transition: all 0.5s;
  flex: 1 0 auto;
  width: ${(props) => (props.width ? props.width : 'auto')};

  path {
    transition: fill 0.5s;
    fill: #000;
  }


  @media ${DEVICES.md} {
    padding: 12px 20px;
    flex: 0 0 auto;
  }

  @media ${DEVICES.lg} {
    &:hover {
      border: ${(props) =>
        props.disabled
          ? setColor(props.color, 'border')
          : setColor(props.color, 'border-hover')};
      background-color: ${(props) =>
        props.disabled
          ? setColor(props.color, 'background')
          : setColor(props.color, 'background-hover')};
      color: ${(props) =>
        props.disabled
          ? setColor(props.color, 'text')
          : setColor(props.color, 'text-hover')};
      opacity: ${(props) => (props.disabled ? '0.5' : '1')};
      cursor: pointer;

      path {
        transition: fill 0.5s;
        fill: #fff;
      }
    }
`

export const ButtonLink = styled.a<{
  color: ButtonColors
  width?: string
}>`
  display: flex;
  text-decoration: none;
  padding: 12px 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: ${(props) => setColor(props.color, 'border')};
  background-color: ${(props) => setColor(props.color, 'background')};
  color: ${(props) => setColor(props.color, 'text')};
  font-family: gilroy-semibold;
  font-size: 1.125rem;
  transition: all 0.5s;
  flex: 1 0 auto;
  width: ${(props) => (props.width ? props.width : 'auto')};

  path {
    transition: fill 0.5s;
    fill: #fff;
  }

  @media ${DEVICES.md} {
    padding: 12px 20px;
    flex: 0 0 auto;
  }

  @media ${DEVICES.lg} {
    &:hover {
      border: ${(props) => setColor(props.color, 'border-hover')};
      background-color: ${(props) => setColor(props.color, 'background-hover')};
      color: ${(props) => setColor(props.color, 'text-hover')};
      cursor: pointer;
    }
  }
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 0.3rem;
  background: #dad9d8;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #e1e1e1;
  }
`

export const Lines = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:hover {
    & div {
      background: #565555;
    }
  }

  & div {
    transform-origin: center;

    &:first-child {
      transform: rotate(45deg);
    }

    &:last-child {
      transform: rotate(-45deg);
    }
  }
`

export const CloseButtonLine = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #3f3f3f;
  border-radius: 2px;
`
