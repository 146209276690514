import { StatusColors, SubscriptionColor } from '@/types'
import styled from 'styled-components'

export const AppContainer = styled.div<{ darkTheme?: boolean }>`
  background: ${(props) => (props.darkTheme ? '#000000' : '#ffffff')};
  display: flex;
  min-height: 100vh;
  color: ${(props) => (props.darkTheme ? '#ffffff' : '#000000')};
`

export const LoadingPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const SideBarTopGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem 1.5rem 2rem;
  // font-family: gilroy-bold;
`

export const HomeLink = styled.div`
  position: relative;
  font-size: 1.5rem;
  padding-top: 1rem;
`

export const Temporary = styled.div`
  padding-top: 300px;
  font-size: 2rem;
`

export const MenuItemWrapper = styled.div`
  &:hover {
    & path {
      fill: white;
    }
  }
`

export const MenuItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
`

export const SidebarContainer = styled.div<{ $darktheme: boolean }>`
  margin-top: 1rem;
  position: relative;
  background-color: ${(props) => (props.$darktheme ? '#000000' : '#ffffff')};

  & .prefix-icon {
    & path {
      fill: ${(props) => (props.$darktheme ? '#ffffff' : '#000000')};
    }
  }

  & .css-dip3t8 {
    background: transparent !important;
  }

  & .dark-mode-switch {
    border: 1px solid ${(props) => (props.$darktheme ? 'white' : 'black')};
  }

  & .referral-link {
    margin-left: 1rem;
    margin-top: 40px;
    color: #8620f3;
    border: 1px solid #8620f3;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    width: 285px;
    height: 36px;

    &:hover {
      background-color: buttonface;
    }
  }

  &:before {
    display: ${(props) => (props.$darktheme ? 'block' : 'none')};
    content: '';
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: 0rem;
    right: -2.7rem;
    background-color: transparent;
    background: url(dark-theme-ellipse-2.png) no-repeat center;
  }

  &:after {
    display: ${(props) => (props.$darktheme ? 'block' : 'none')};
    content: '';
    position: absolute;
    width: 20rem;
    height: 30rem;
    top: 5rem;
    right: -2.7rem;
    background-color: transparent;
    background: url(dark-theme-ellipse-1.png) no-repeat center;
  }
`

export const SwitchContainer = styled.div<{ darkTheme: boolean }>`
  border-top: ${(props) =>
    props.darkTheme ? '1px solid #2D2D2D' : '1px solid #E0E0E0'};
  margin: 1rem 2rem;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: gilroy-medium;
`

export const SwitchIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const HeaderContainer = styled.div<{ darkTheme: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  font-family: gilroy-bold;
  font-size: 16px;
  background: ${(props) => (props.darkTheme ? '#000000' : '#ffffff')};
`

export const Call = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: black;
  font-family: gilroy-bold;
  font-size: 16px;
  text-decoration: none;
`

export const FeedTableRowContainer = styled.div<{ $darktheme: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid
    ${(props) => (props.$darktheme ? '#2D2D2D' : '#dbdbdb')};
  width: 100%;
  font-family: gilroy;
  font-size: 16px;
  text-align: start;
  min-height: 3.5rem;

  &:hover {
    &:first-child {
      border-bottom: 1px solid
        ${(props) => (props.$darktheme ? '#2D2D2D' : '#dbdbdb')};
    }

    border-bottom: 1px solid #e844c9;
  }
`

export const TableCell = styled.div<{
  color?: string
  width?: string
  $bold?: boolean
  $last?: boolean
}>`
  padding: 12px 0.5rem;
  color: ${(props) => (props.color ? props.color : 'black')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  font-family: ${(props) => (props.$bold ? 'gilroy-bold' : 'gilroy')};
  text-align: ${(props) => (props.$last ? 'end' : 'start')};
`

const getColor = (color: StatusColors, darkTheme?: boolean) => {
  switch (color) {
    case 'green':
      return {
        background: 'rgba(35, 195, 21, 0.12)',
        text: '#23C315',
      }
    case 'orange':
      return {
        background: 'rgba(255,165,0, 0.12)',
        text: 'orange',
      }
    case 'blue':
      return {
        background: 'rgba(0, 165, 235, 0.12)',
        text: '#00A5EB',
      }
    case 'red':
      return {
        background: 'rgba(255,0,0, 0.12)',
        text: 'red',
      }
    case 'violet':
      return {
        background: 'rgba(120, 21, 226, 0.12)',
        text: '#7815E2',
      }
    default:
      return {
        background: darkTheme ? 'rgba(141, 141, 141, 0.24)' : '#EAEAEA',
        text: '#4A4949',
      }
  }
}

export const Status = styled.div<{ color: StatusColors; $darktheme?: boolean }>`
  align-self: start;
  background-color: ${(props) =>
    getColor(props.color, props.$darktheme).background};
  font-family: gilroy;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  text-align: center;
  width: fit-content;
  color: ${(props) => getColor(props.color).text};
  margin-left: auto;
`

export const EditContainer = styled.div<{ darkTheme?: boolean }>`
  display: flex;
  justify-content: end;
  gap: 2rem;

  & svg {
    cursor: pointer;

    & path {
      fill: ${(props) => (props.darkTheme ? 'white' : 'black')};
    }
  }
`

export const ContentBlockRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-family: gilroy-semibold;
  font-size: 16px;
  margin-bottom: 12px;

  & .game-filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`

export const RowName = styled.div`
  flex: 0 1 40%;

  & span {
    color: red;
  }

  & .optional {
    color: #8d8d8d;
  }
`

export const RowDescription = styled.div<{ darkTheme: boolean }>`
  color: ${(props) => (props.darkTheme ? '#999999 ' : '#656565')};
  margin-top: 12px;
  font-family: gilroy;

  & .long-form-warning {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    background: ${(props) => (props.darkTheme ? '#111' : '#F5F5F5')};
    border-radius: 1rem;
    padding: 1rem;
    color: #8620F3;
    font-size: 1rem;
    font-family: ${(props) => (props.darkTheme ? 'gilroy' : 'gilroy-medium')};
`

export const RowContent = styled.div<{
  $darktheme: boolean
  $expanded?: boolean
}>`
  flex: 0 0 55%;
  position: relative;

  & input[type='date']::-webkit-calendar-picker-indicator {
    filter: ${(props) => (props.$darktheme ? 'invert(100%)' : 'none')};
  }

  & .input-element {
    width: 100%;
    height: 48px;
    border-radius: 16px;
    padding: 12px;
    border: 1px solid #dbdbdb;
    outline: none;
    outline-offset: 0;
    font-family: gilroy;
    border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
    background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
    color: ${(props) => (props.$darktheme ? 'white ' : 'black')};
    resize: none;
    transition: min-height 0.5s ease-out;

    &::placeholder {
      color: #9d9d9d;
    }

    &:selected {
      color: red !important;
    }

    &:focus {
      border: 1px solid #e844c9;
    }
  }

  & textarea {
    height: 100%;
    min-height: 144px;
  }

  & .editor {
    &:focus-within {
      & .ql-container {
        border: 1px solid #e844c9;
        border-top: 1px solid
          ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
      }

      & .ql-toolbar {
        border: 1px solid #e844c9;
        border-bottom: 1px solid
          ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
      }
    }
  }

  & .ql-toolbar {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
    border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
  }

  & .ql-container {
    transition: min-height 0.5s ease-out;
    border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
    min-height: ${(props) => (props.$expanded ? '300px' : '144px')} !important;

    & .ql-editor {
      word-break: break-word;
    }
  }

  & button {
    &:hover {
      & .ql-stroke {
        stroke: #e844c9 !important;
      }

      & .ql-fill {
        fill: #e844c9 !important;
      }
    }
  }

  & .ql-active {
    & .ql-stroke {
      stroke: #e844c9 !important;
    }

    & .ql-fill {
      fill: #e844c9 !important;
    }
  }

  & input {
    &:read-only {
      color: #8d8d8d;
    }
  }

  & .ql-editor.ql-blank::before {
    font-style: normal !important;
    color: #8d8d8d;
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  & .multiselect-icon {
    position: absolute;
    right: 1rem;
    top: 30%;
    color: ${(props) => (props.$darktheme ? 'white ' : 'black')};
  }
`

export const ContentBlock = styled.div`
  text-align: start;
  margin-bottom: 20px;
`

export const ContentBlockTitle = styled.div`
  font-family: gilroy-black;
  font-size: 20px;
  text-align: start;
  margin-bottom: 24px;
`

export const RadioInput = styled.input`
  position: relative;
  appearance: none;
  color: black;
  width: 1rem !important;
  height: 1rem !important;
  font-size: 10px;

  &: checked {
    &:before {
      content: '';
      left: 0.3rem;
      top: 0.35rem;
      position: absolute;
      width: 0.86rem;
      height: 0.86rem;
      border-radius: 50%;
      background: black;
    }
  }
`

export const RadioInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin: 0 0 1rem 0;
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`

export const PartnerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const PartnerLogo = styled.div<{ logo: string | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: ${(props) => (props.logo ? 'transparent' : '#7815e2')};
  color: white;
  font-size: 1.8rem;
  font-family: gilroy-semibold;
  overflow: hidden;
`

export const PartnerNameEmail = styled.div<{ darkTheme: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.2rem;
  font-size: 0.9rem;
  font-family: gilroy;
  color: ${(props) => (props.darkTheme ? 'white' : 'black')};
`

export const PartnerName = styled.div`
  font-size: 1.2rem;
  font-family: gilroy-bold;
`

export const Games = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`

export const ComingSoonBorder = styled.div`
  border-radius: 16px;
  background: linear-gradient(90deg, #9747ff, #e63fc7);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ComingSoonWhiteBackground = styled.div`
  background: white;
  border-radius: 16px;
  margin: 1px;
`

export const ComingSoon = styled.div`
  // background: url(coming-soon.png) no-repeat;
  // width: 110px;
  // height: 30px;
  // border: 1px solid black;
  padding: 0.5rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  background: linear-gradient(90deg, #9747ff, #e63fc7);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
`

export const PopularContainer = styled.div<{ $darktheme?: boolean }>`
  padding: 0.2rem 1rem;
  border: 1px solid ${(props) => (props.$darktheme ? 'white' : 'black')};
  border-radius: 1rem;
  color: ${(props) => (props.$darktheme ? 'white' : 'black')};
  font-size: 0.75rem;
  text-transform: uppercase;
`

export const SubscriptionTitle = styled.div<{ $darktheme?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  font-family: gilroy;

  & svg {
    width: 2rem;

    & path {
      fill: ${(props) => (props.$darktheme ? 'white' : 'black')};
    }
  }
`

export const SubscriptionContent = styled.div`
  font-size: 1rem;
  font-family: gilroy-bold;
  margin: 0 0 1rem 3rem;
`

export const SubscriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const TierContainer = styled.div<{ color?: SubscriptionColor }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  background: ${(props) =>
    props.color === 'violet'
      ? 'rgba(120, 21, 226, 0.08)'
      : props.color === 'pink'
        ? 'rgba(230, 57, 213, 0.08)'
        : 'rgba(0, 0, 0, 0.08)'};
  border: 1px solid
    ${(props) =>
      props.color === 'violet'
        ? '#7815E2'
        : props.color === 'pink'
          ? '#E639D5'
          : 'grey'};
  border-radius: 16px;
  padding: 1rem;
  transition: visibility 0.1s ease-out;

  & .price {
    font-family: gilroy-medium;
    color: #656565;
    margin-top: 0.7rem;
  }

  & button {
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    & button {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const PassStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`

export const InfoIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.2rem;

  &:hover {
    & .tier-card {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const Beta = styled.div`
  position: absolute;
  top: -0.4rem;
  left: 11rem;
  color: #e639d5;
  font-family: gilroy-medium;
  font-size: 1rem;
`

export const WarningWrapper = styled.div<{ $darktheme?: boolean }>`
  flex: 0 0 auto;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: 0.1rem;
  padding: 0.25rem;
  border-radius: 4px;
  background: rgba(
    134,
    32,
    243,
    ${(props) => (props.$darktheme ? '0.12' : '0.04')}
  );
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GameCardContainer = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
  width: 15rem;
  position: relative;
}
`

export const Genres = styled.div`
  display: flex;
  gap: 0.3rem;
  flex-wrap: wrap;
  border-radius: 1rem;
`

export const Genre = styled.div<{ $darktheme?: boolean }>`
  padding: 0 0.5rem;
  border: 1px solid ${(props) => (props.$darktheme ? 'white' : 'black')};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font: gilroy;
  font-size: 0.75rem;
  line-height: 1.5rem;
`

export const MultiselectContainer = styled.div<{ $darktheme?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: gilroy;
  padding: 0.9rem 1rem;
  font-size: 0.85rem;
  border-radius: 1rem;
  background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
  color: ${(props) => (props.$darktheme ? '#656565 ' : '#8D8D8D')};
  border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
  cursor: pointer;

  & .multiselect-icon {
    color: ${(props) => (props.$darktheme ? 'white ' : 'black')};
  }
`

export const MultiselectMenu = styled.div<{ $darktheme?: boolean }>`
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow-y: scroll;
  width: 100%;
  background: ${(props) => (props.$darktheme ? '#000' : '#fff')};
  border: 1px solid ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
  border-radius: 1rem;
  // padding: 0.5rem 1rem;
`

export const MultiselectItem = styled.div<{ $darktheme?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  color: ${(props) => (props.$darktheme ? '#fff' : '#000')};

  &:hover {
    background: ${(props) => (props.$darktheme ? '#111' : '#f5f5f5')};
  }
`

export const MultiselectCheckbox = styled.input<{ $darktheme?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  border: 1px solid #4a4949;
  background: transparent;
  color: ${(props) => (props.$darktheme ? '#000' : '#fff')};
  font-family: gilroy-bold;
  border-radius: 0.2rem;

  &:checked {
    background: ${(props) => (props.$darktheme ? '#fff' : '#000')};

    &:after {
      content: ' \u2714';
      color: ${(props) => (props.$darktheme ? '#000' : '#fff')};
    }
  }
`

export const PreviewImagesRow = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
  position: relative;
  top: 0rem;
  flex-wrap: wrap;
`

export const MockLogo = styled.div<{ $darktheme?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
  color: ${(props) => (props.$darktheme ? '#111' : '#DBDBDB')};
  background: ${(props) => (props.$darktheme ? '#222' : '#f5f5f5')};
  font-family: gilroy-bold;
  font-size: 1.5rem;
  border-radius: 1rem;
`

export const GameTitle = styled.div`
  font-family: gilroy-bold;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectContainer = styled.div<{ $darktheme?: boolean }>`
  position: relative;

  & .multiselect-icon {
    position: absolute;
    right: 1rem;
    top: 30%;
    color: ${(props) => (props.$darktheme ? 'white ' : 'black')};
  }
`

export const MenuIconContainer = styled.div`
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`

export const GameCardImage = styled.div<{
  gameId: number
  $darktheme?: boolean
}>`
  position: relative;
  z-index: 999;
  cursor: pointer;
  width: 15rem;
  height: 15rem;
  overflow: hidden;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${(props) => (props.$darktheme ? 'black' : 'white')};

    & img {
      opacity: 0.5;
    }

    & #new-entry-button-${(props) => props.gameId} {
      display: block;
    }
  }
`

export const AddImageButton = styled.div<{ $darktheme?: boolean }>`
display: flex;
  justify-content: center;
  align-items: center;
width: 136px;
height: 66px;
border-radius: 1rem;
border: 1px dashed ${(props) => (props.$darktheme ? '#2D2D2D' : '#DBDBDB')};
background: ${(props) => (props.$darktheme ? '#111' : '#F5F5F5')};
color: ${(props) => (props.$darktheme ? '#8D8D8D' : '#8D8D8D')};
cursor: pointer;

&:hover {
  color: ${(props) => (props.$darktheme ? 'white' : 'black')};
  }
}
`
