import { useNavigate, useParams } from 'react-router-dom'
import * as Styled from './styled'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import Button from '../components/buttons/Button'
import Loader from '../components/Loader'
import ContentBlockRow from '../components/ContentBlockRow'
import { games_content } from './content'
import { defaultGameData } from './defaultData'
import { IGameData, StatusType } from '../types'
import { transformGameData } from '../utils/data'
import { DataContext } from '../context'
import { useCookies } from 'react-cookie'
import {
  getGameData,
  postGameData,
  unpublishGame,
  updateGameData,
  updateGameReviewStatus,
} from '../utils/api/game'
import Switch from 'react-switch'
import PreviewImages from '../components/PreviewImages'
import { deleteReplacedImages } from '../utils/api'
import { uploadGameFile } from '../utils/save'
import { WarningWrapper } from '../components/styled'
import { WarningIcon } from '../icons'

function GamePage() {
  const params = useParams()
  const navigate = useNavigate()
  const { setFilter, darkTheme } = useContext(DataContext)
  const [gameData, setGameData] = useState<IGameData>(defaultGameData)
  const [values, setValues] = useState<IGameData>(defaultGameData)
  const [loading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const [loadingReview, setLoadingReview] = useState(false)
  const [status, setStatus] = useState<StatusType>(null)
  const [percentLoaded, setPercentLoaded] = useState(0)
  const [uploadCancelToken, setUploadCancelToken] = useState<any>(null)
  const [allFilesSize, setAllFilesSize] = useState(0)

  const getData = async () => {
    try {
      const gameData = await getGameData(params.id as string)
      const restructuredData = transformGameData(gameData?.attributes)
      setValues(restructuredData)
      setGameData(restructuredData)
      setStatus(restructuredData.status)
    } catch (error) {
      console.error(error)
      toast('Error occured during game data fetching...')
    }
  }

  const onCancelUploadClick = () => {
    uploadCancelToken?.cancel()
    setUploadCancelToken(null)
    setPercentLoaded(0)
  }

  const onUploadClick = async () => {
    setLoading(true)
    try {
      status !== 'published' && (await onSaveDraftClick(true))
      await uploadGameFile(
        params.id as string,
        values.source_url,
        setPercentLoaded,
        uploadCancelToken,
        setUploadCancelToken
      )
      await getData()
    } catch (error: any) {
      console.error(error)
      toast(
        error.code === 'ERR_CANCELED'
          ? 'Upload canceled'
          : 'Something went wrong...'
      )
    } finally {
      setLoading(false)
    }
  }

  const onSaveDraftClick = async (isFileUploaded: boolean) => {
    if (allFilesSize > 60 * 1024 * 1024) {
      toast('Total images size exceeds 60MB limit')
      return
    }
    try {
      setLoading(true)
      params.id &&
        (await deleteReplacedImages(
          gameData.preview_images,
          values.preview_images
        ))
      const response = params.id
        ? await updateGameData(values, params.id as string)
        : await postGameData(values)
      if (isFileUploaded) return
      if (response === 200) {
        if (params.id) {
          await getData()
          toast('You have successfully updated current game!')
        } else {
          toast('You have successfully created new game!')
          navigate('/games')
        }
      } else {
        toast('Something went wrong...')
      }
      setLoading(false)
    } catch (error: any) {
      console.error(error)
      if (error?.response?.data?.name === 'ValidationError') {
        toast('Fill in all required fields!')
      } else {
        toast('Something went wrong...')
      }
      setLoading(false)
    }
  }

  const onUpublishClick = async () => {
    try {
      const response = await unpublishGame(params.id as string)
      if (response === 200) {
        toast('Successfully unpublished')
        setStatus('draft')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onRequestReviewClick = async () => {
    if (JSON.stringify(gameData) !== JSON.stringify(values)) {
      toast('Save updates first')
      return
    }

    try {
      setLoadingReview(true)
      const responseStatus = await updateGameReviewStatus(params.id as string)
      if (responseStatus === 200) {
        toast('Game is in review now!')
        setStatus('in_review')
      } else {
        toast('Something went wrong...')
      }
    } catch (error) {
      console.error(error)
      toast('Something went wrong...')
    } finally {
      setLoadingReview(false)
    }
  }

  useEffect(() => {
    getData()
    setFilter(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])
  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>Upload Game Listing Info/Assets</Styled.Title>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            onClick={() => onSaveDraftClick(false)}
            color="black"
            disabled={
              JSON.stringify(gameData) === JSON.stringify(values) ||
              status === 'published'
            }
            width="10rem"
            onMouseEnter={() => setLoaderColor('black')}
            onMouseLeave={() => setLoaderColor('white')}
          >
            {loading ? (
              <Loader color={loaderColor} width="2.5rem" />
            ) : (
              'Save as Draft'
            )}
          </Button>
          {status === 'published' ? (
            <Button onClick={onUpublishClick}>Unpublish</Button>
          ) : (
            <Button
              onClick={onRequestReviewClick}
              disabled={status === 'in_review' || !params.id}
              onMouseEnter={() => setLoaderColor('white')}
              onMouseLeave={() => setLoaderColor('black')}
            >
              {loadingReview ? (
                <Loader color={loaderColor} width="8rem" />
              ) : status === 'in_review' ? (
                'In Review'
              ) : (
                'Request Review'
              )}
            </Button>
          )}
        </div>
      </Styled.TitleBlock>
      {games_content.map((block) => (
        <Styled.ContentBlock key={block.name}>
          <Styled.ContentBlockTitle $darktheme={darkTheme}>
            {block.name}
            {block.optional && (
              <span className="optional">{' (optional)'}</span>
            )}
            {block.switch && (
              <Styled.SwitchContainer
                $content="Create a game first to unlock section to add game files"
                $darktheme={darkTheme}
                $active={block.switch === 'files_upload' && !params.id}
              >
                <Switch
                  disabled={block.switch === 'files_upload' && !params.id}
                  checked={values[block.switch] || false}
                  onChange={() =>
                    block.switch &&
                    setValues({
                      ...values,
                      [block.switch as string]: !values[block.switch],
                    })
                  }
                  className="dark-mode-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={darkTheme ? '#000000' : '#ffffff'}
                  offColor={darkTheme ? '#000000' : '#ffffff'}
                  onHandleColor={darkTheme ? '#ffffff' : '#000000'}
                  offHandleColor="#656565"
                  handleDiameter={23}
                  width={56}
                  height={28}
                  activeBoxShadow="0"
                />
              </Styled.SwitchContainer>
            )}
          </Styled.ContentBlockTitle>
          {block.name === 'Images' && (
            <>
              <br />{' '}
              <PreviewImages
                values={values}
                setValues={setValues}
                allFilesSize={allFilesSize}
                setAllFilesSize={setAllFilesSize}
                tag="preview_images"
              />
            </>
          )}
          {block.rows.map((item) => {
            if (!block.switch || values[block.switch]) {
              return (
                <ContentBlockRow
                  key={item.tag}
                  tag={item.tag}
                  title={item.title}
                  value={values}
                  setValue={setValues}
                  content_type={item.content_type}
                  placeholder={item.placeholder}
                  description={item.description}
                  options={item.options}
                  required={item.required}
                  expandable={item.expandable}
                  content={item.content}
                  loading={loading}
                  percentLoaded={percentLoaded}
                  onUploadClick={onUploadClick}
                  onCancelUploadClick={onCancelUploadClick}
                  allFilesSize={allFilesSize}
                  setAllFilesSize={setAllFilesSize}
                />
              )
            }
            return null
          })}
        </Styled.ContentBlock>
      ))}
    </Styled.PageContainer>
  )
}

export default GamePage
