import { useEffect, useContext, useCallback, useState } from 'react'
import * as Styled from './styled'
import { toast } from 'react-toastify'
import ModsTableRow from '../components/moderators/ModsTableRow'
import { DataContext, PartnerContext } from '../context'
import Button from '../components/buttons/Button'
import InviteModal from '../components/modals/InviteModModal'
import { useCookies } from 'react-cookie'
import { Moderator } from '../types/partner'
import Api from '../services/api'
import Loader from '../components/Loader'
import { useNavigate } from 'react-router-dom'

function ModsPage() {
  const { darkTheme } = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [moderators, setModerators] = useState([])
  const [existingLink, setExistingLink] = useState('' as string)

  const { referrerId } = useContext(PartnerContext)

  const navigate = useNavigate()

  const getModerators = useCallback(async () => {
    try {
      setLoading(true)
      const moderators = await Api.get<Moderator[]>('partners/moderators')

      setModerators(moderators)
    } catch (error) {
      console.error(error)
      toast('Error occured during moderators data fetching...')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getModerators()
  }, [])

  if (referrerId) {
    navigate('/login')
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const showExistingLink = (link) => {
    setExistingLink(link)
    setIsModalOpen(true)
  }

  return (
    <Styled.PageContainer>
      <Styled.TitleBlock>
        <Styled.Title>Moderators</Styled.Title>
        <Button
          color={darkTheme ? 'white' : 'black'}
          onClick={handleModalOpen}
          id="new-entry-button"
        >
          Add new +
        </Button>
        <InviteModal
          open={isModalOpen}
          close={handleModalClose}
          getModerators={getModerators}
          existingLink={existingLink}
          setExistingLink={setExistingLink}
        />
      </Styled.TitleBlock>
      <Styled.FeedTable>
        <ModsTableRow isHeader />
        {loading ? (
          <Styled.LoaderContainer>
            <Loader type="grid" color="grey" />
          </Styled.LoaderContainer>
        ) : (
          moderators.map((row, i) => (
            <ModsTableRow
              row={row}
              index={i}
              getModerators={getModerators}
              showExistingLink={showExistingLink}
            />
          ))
        )}
      </Styled.FeedTable>
    </Styled.PageContainer>
  )
}

export default ModsPage
