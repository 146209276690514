import { transformGamesData } from '../utils/data'
import { PartnerContext } from '../context'
import { getGamesData } from '../utils/api/game'
import { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import * as Styled from './styled'
import GameCard from '../components/GameCard'
import { IGameCard } from '@/types'
import Button from '../components/buttons/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'

const GamesPageLoader = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Loader type="grid" color="grey" />
    </div>
  )
}

function GamesPage() {
  const { partner_cms_id } = useContext(PartnerContext)
  const [games, setGames] = useState<IGameCard[] | null>(null)
  const [loaded, setLoaded] = useState(false)
  const navigate = useNavigate()

  const getData = async () => {
    try {
      const gamesData = await getGamesData(partner_cms_id as string)
      const restructuredData = transformGamesData(gamesData)
      setGames(restructuredData)
    } catch (error) {
      console.error(error)
      toast('Error occured during games data fetching...')
    } finally {
      setLoaded(true)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.PageContainer>
      <Styled.GamesTitle>
        Games
        <Button onClick={() => navigate('/game')}>New Game +</Button>
      </Styled.GamesTitle>
      <Styled.GamesList>
        {!loaded ? (
          <GamesPageLoader />
        ) : !!games?.length ? (
          games?.map((game) => (
            <GameCard key={game.id} game={game} getData={getData} />
          ))
        ) : (
          <div>You have no games</div>
        )}
      </Styled.GamesList>
    </Styled.PageContainer>
  )
}

export default GamesPage
