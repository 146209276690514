import { useState } from 'react'
import * as Styled from './styled'
import Button from '../components/buttons/Button'
import { PassStatus } from '../types/partner'
import Api from '../services/api'
import { useCookies } from 'react-cookie'
import Loader from '../components/Loader'
import { toast } from 'react-toastify'
import { Beta, Status } from '../components/styled'
import { OkIcon, OkWhiteIcon } from '../icons'
import { subscription_content } from './content'
import Popular from '../components/Popular'

function SubscriptionPage() {
  const [cookies] = useCookies(['token'])
  const [isLoading, setLoading] = useState(false)
  const [loaderColor, setLoaderColor] = useState('white')
  const [subscription, setSubscription] = useState<PassStatus>(
    PassStatus.UPRISING
  )

  const onProceedClick = async () => {
    if (cookies.token) {
      try {
        setLoading(true)
        const res = await Api.post<{ url: string }>('payments', {
          passStatus: subscription,
          isPartner: true,
        })
        if (res.url) {
          window.location.replace(res.url)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      toast('Sign in first!')
    }
  }

  const isDark = localStorage.getItem('dark') === 'true'

  return (
    <Styled.SubscriptionBackground $darktheme={isDark}>
      <Styled.PageContainer height="100%">
        <Styled.LogoContainer>
          <Beta>Beta</Beta>
          <img
            src={
              isDark ? '/logo/metacade-white.png' : '/logo/metacade-black.png'
            }
            width="202"
            height="30"
            alt="metacade-logo"
          />
          {/* <Call href={bookCallUrl} target="_blank" rel="noreferrer">
            <CallIcon /> Book a Call
          </Call> */}
        </Styled.LogoContainer>
        <Styled.LoginContent marginTop="-1rem">
          <Styled.LoginTitle $darktheme={isDark}>
            Become a partner
          </Styled.LoginTitle>
          <Styled.LoginSubTitle>
            Purchasing a personal plan offers you exclusive access to various
            perks and benefits
          </Styled.LoginSubTitle>
          <Styled.SubscriptionCards>
            {subscription_content.map((card) => (
              <Styled.Card
                $darktheme={isDark}
                $active={subscription === card.type.status}
                onClick={() => setSubscription(card.type.status)}
              >
                <Styled.SubscriptionType color={card.type.color}>
                  {card.type.title}
                  {card.popular && <Popular isDark={isDark} />}
                </Styled.SubscriptionType>
                <Styled.Description>{card.description}</Styled.Description>
                <Styled.Price $darktheme={isDark}>
                  {'$' + card.price}
                  <span>/month</span>
                </Styled.Price>
                {card.previous && (
                  <Styled.Previous>
                    {'All of the '}
                    <Status color={card.previous.color}>
                      {card.previous.title}
                    </Status>
                    {' bundle +'}
                  </Styled.Previous>
                )}
                <Styled.Features>
                  <ul>
                    {card.features.map((item) => (
                      <li key={item}>
                        {isDark ? <OkWhiteIcon /> : <OkIcon />}
                        {item}
                      </li>
                    ))}
                  </ul>
                </Styled.Features>
              </Styled.Card>
            ))}
          </Styled.SubscriptionCards>
          <Button
            color="black"
            onClick={onProceedClick}
            // disabled={isLoading}
            disabled
            onMouseEnter={() => setLoaderColor('black')}
            onMouseLeave={() => setLoaderColor('white')}
          >
            {isLoading ? (
              <Loader color={loaderColor} />
            ) : (
              // : 'Proceed to Stripe'
              'Coming Soon'
            )}
          </Button>
        </Styled.LoginContent>
      </Styled.PageContainer>
    </Styled.SubscriptionBackground>
  )
}

export default SubscriptionPage
