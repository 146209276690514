import { DataContext } from '../../context'
import { Dispatch, SetStateAction, useContext } from 'react'
import * as Styled from './styled'
import { useNavigate } from 'react-router-dom'

function GameCardMenu({
  open,
  gameId,
  setDeleteModalOpen,
}: {
  open: boolean
  gameId: number
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>
}) {
  const navigate = useNavigate()
  const { darkTheme } = useContext(DataContext)

  return (
    <Styled.GameMenuContainer
      darkTheme={darkTheme}
      open={open}
      id={`new-entry-menu-${gameId}`}
    >
      <Styled.NewEntryMenuItem
        darkTheme={darkTheme}
        onClick={() => navigate(`/game/${gameId}`)}
      >
        Edit
      </Styled.NewEntryMenuItem>
      <Styled.NewEntryMenuItem
        darkTheme={darkTheme}
        onClick={() => setDeleteModalOpen(true)}
      >
        Delete
      </Styled.NewEntryMenuItem>
    </Styled.GameMenuContainer>
  )
}

export default GameCardMenu
