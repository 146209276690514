import {
  FeedTableType,
  IAnnouncementData,
  IContentData,
  IGameCard,
  IGameData,
  IJobData,
  IMerchData,
  IProfileData,
  IQuestData,
  IRewardData,
  ITournamentData,
} from '../types'
import { addPTags, formatDate } from './utils'

export const transformProfileData = (data: any, games: any[]): IProfileData => {
  return {
    name: data?.name || '',
    email: data?.email || '',
    description: data?.description || '',
    short_description: data?.short_description || '',
    logo: data?.logo?.data?.attributes?.url || '',
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    website_link: data?.website_link || '',
    video_thumbnail: data?.video_thumbnail?.data?.[0]?.attributes?.url || '',
    about_preview: data?.about_preview?.data?.attributes?.url || '',
    about_text: data?.about_text || '',
    x: data?.socials?.x || '',
    instagram: data?.socials?.instagram || '',
    youtube: data?.socials?.youtube || '',
    twitch: data?.socials?.twitch || '',
    telegram: data?.socials?.telegram || '',
    linkedin: data?.socials?.linkedin || '',
    linktree: data?.socials?.linktree || '',
    tiktok: data?.socials?.tiktok || '',
    currentGame: {
      id: data?.featured_game?.data?.id,
      title: data?.featured_game?.data?.attributes?.title || 'select',
    },
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
  }
}

export const transformQuestData = (
  data: any,
  games: any[],
  feed: any
): IQuestData => {
  return {
    title: data?.title || null,
    type: data?.type || null,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    short_description: data?.short_description || null,
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    url: data?.external_url || null,
    reward: data?.reward || null,
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    currentGame: {
      id: data?.game?.data?.id,
      title: data?.game?.data?.attributes?.title || 'select',
    },
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
    publish_date: feed?.publish_date || null,
    expiry_date: feed?.expiry_date || null,
    trailer_video: data?.trailer_video || '',
  }
}

export const transformRewardData = (data: any, games: any[]): IRewardData => {
  return {
    title: data?.title || null,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    url: data?.external_url || null,
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    currentGame: {
      id: data?.game?.data?.id,
      title: data?.game?.data?.attributes?.title || 'select',
    },
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
    trailer_video: data?.trailer_video || '',
  }
}

export const transformTournamentData = (
  data: any,
  games: any[]
): ITournamentData => {
  return {
    title: data?.title || null,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    url: data?.external_url || null,
    reward: data?.reward || null,
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    currentGame: {
      id: data?.game?.data?.id,
      title: data?.game?.data?.attributes?.title || 'select',
    },
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
    trailer_video: data?.trailer_video || '',
  }
}

export const transformMerchData = (data: any, games: any[]): IMerchData => {
  return {
    title: data?.title || null,
    type: data?.type || null,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    url: data?.external_url || null,
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
    currentGame: {
      id: data?.game?.data?.id,
      title: data?.game?.data?.attributes?.title || 'select',
    },
    trailer_video: data?.trailer_video || '',
  }
}

export const transformContentData = (
  data: any,
  games: any[],
  feed: any
): IContentData => {
  return {
    title: data?.title || null,
    type: data?.type || null,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    short_description: data?.short_description || null,
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    url: data?.external_url || null,
    reward: data?.reward || null,
    currentGame: {
      id: data?.game?.data?.id,
      title: data?.game?.data?.attributes?.title || 'select',
    },
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    publish_date: feed?.publish_date || null,
    expiry_date: feed?.expiry_date || null,
    trailer_video: data?.trailer_video || '',
  }
}

export const transformJobData = (data: any, feed: any): IJobData => {
  return {
    title: data?.title || null,
    company: data?.company || null,
    location: data?.location || null,
    employment_type: data?.employment_type,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    short_description: data?.short_description || null,
    salary: data?.salary || '',
    url: data?.external_url || null,
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    publish_date: feed?.publish_date || null,
    expiry_date: feed?.expiry_date || null,
    trailer_video: data?.trailer_video || '',
  }
}

export const transformAnnouncementData = (
  data: any,
  games: any[]
): IAnnouncementData => {
  return {
    title: data?.title || null,
    is_published: !!data?.publishedAt || false,
    description: addPTags(data?.description) || null,
    short_description: data?.short_description || null,
    preview_image: data?.preview_image?.data?.attributes?.url || '',
    url: data?.external_url || null,
    tags: data?.tags?.map((el: any) => '#' + el.Tag + ' ').join(' ') || '',
    currentGame: {
      id: data?.game?.data?.id || 0,
      title: data?.game?.data?.attributes?.title || 'select',
    },
    games:
      games?.map((el) => ({ id: el.id, title: el.attributes?.title })) || [],
    trailer_video: data?.trailer_video || '',
  }
}

export const transformFeedData = (data: any): FeedTableType[] => {
  const result: FeedTableType[] = []
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      const typeField = item?.attributes?.type

      const type =
        item?.attributes?.type.charAt(0).toUpperCase() +
        item?.attributes?.type.slice(1)

      const tags: string[] =
        item?.attributes?.[typeField]?.data?.attributes?.tags?.map(
          (el: any) => '#' + el.Tag
        ) || []

      const time = new Date(Date.parse(item?.attributes?.updatedAt))
        .toTimeString()
        .slice(0, 5)
      const date = formatDate(item?.attributes?.updatedAt)

      const entry: FeedTableType = {
        id: item?.id,
        typeId: item?.attributes?.[typeField]?.data?.id,
        index: index + 1,
        title: item?.attributes?.[typeField]?.data?.attributes?.title,
        type,
        tags,
        time,
        date,
        status: item?.attributes?.publishedAt
          ? 'published'
          : item?.attributes?.review_status,
      }

      result.push(entry)
    })
  }

  return result
}

export const transformGamesData = (data: any): IGameCard[] => {
  return data.map((game: any) => ({
    id: game?.id,
    title: game?.attributes?.title || null,
    genres: game?.attributes?.genres?.map((el: any) => el.Genre),
    logo: game?.attributes?.logo?.data?.attributes?.url || '',
    status: game?.attributes?.publishedAt
      ? 'published'
      : game?.attributes?.in_review
        ? 'in_review'
        : 'draft',
  }))
}

export const transformGameData = (data: any): IGameData => {
  return {
    title: data?.title || null,
    description: data?.description || null,
    short_description: data?.short_description || null,
    specifications: data?.specifications || null,
    filters: {
      category: data?.category?.map((el: any) => el.Category) || [],
      genres: data?.genres?.map((el: any) => el.Genre) || [],
      platform: data?.platform?.map((el: any) => el.GamePlatform) || [],
      age_rate: data?.age_rate || '',
      game_type: data?.GameType || '',
      network: data?.network?.map((el: any) => el.Network) || [],
    },
    logo: data?.logo?.data?.attributes?.url || '',
    preview_images:
      data?.preview_images?.data?.map((image: any) => ({
        url: image?.attributes?.url || '',
        id: image?.id,
      })) || [],
    trailer_video_url: data?.trailer_video?.url || '',
    trailer_video_thumbnail:
      data?.trailer_video?.thumbnail?.data?.attributes?.url || '',
    second_trailer_video_url: data?.second_trailer_video?.url || '',
    second_trailer_video_thumbnail:
      data?.second_trailer_video?.thumbnail?.data?.attributes?.url || '',
    languages_audio: data?.languages_audio || '',
    languages_text: data?.languages_text || '',
    release_date: data?.release_date || null,
    is_alpha: data?.is_alpha || false,
    is_development: data?.is_development || false,
    alpha_finish_date: data?.alpha_finish_date || null,
    alpha_extra_text: data?.alpha_extra_text || '',
    alpha_extra_image: data?.alpha_extra_image?.data?.attributes?.url || '',
    alpha_extra_questions_title: data?.alpha_extra_questions?.[0]?.title || '',
    alpha_extra_questions_type: data?.alpha_extra_questions?.[0]?.type || null,
    x: data?.twitter_url || '',
    discord: data?.discord_url || '',
    reddit: data?.reddit_url || '',
    linktree: data?.linktree_url || '',
    website: data?.website_url || '',
    google_store_url: data?.google_store_url || '',
    apple_store_url: data?.apple_store_url || '',
    source_url: data?.source_url || '',
    status: data?.publishedAt
      ? 'published'
      : data?.in_review
        ? 'in_review'
        : 'draft',
    socials:
      data?.twitter_url ||
      data?.reddit_url ||
      data?.discord_url ||
      data?.linktree_url ||
      data?.website_url,
    files_upload:
      data?.source_url || data?.google_store_url || data?.apple_store_url,
    is_file_uploaded: data?.is_file_uploaded || false,
  }
}
